import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-media',
  templateUrl: './enterprise-media.component.html',
  styleUrls: ['./enterprise-media.component.scss']
})
export class EnterpriseMediaComponent implements OnInit {
  lMediaLibraries: any = ['item','item','item','item','item','item','item','item','item','item',]
  bDisplayDropdown: boolean;
  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
 
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `
    $(document).ready(function(){
      var submitIcon = $('.searchbox-icon');
      var inputBox = $('.searchbox-input');
      var searchBox = $('.searchbox');
      var isOpen = false;
      submitIcon.click(function(){
          if(isOpen == false){
              searchBox.addClass('searchbox-open');
              inputBox.focus();
              isOpen = true;
          } else {
              searchBox.removeClass('searchbox-open');
              inputBox.focusout();
              isOpen = false;
          }
      });  
       submitIcon.mouseup(function(){
              return false;
          });
      searchBox.mouseup(function(){
              return false;
          });
      $(document).mouseup(function(){
              if(isOpen == true){
                  $('.searchbox-icon').css('display','block');
                  submitIcon.click();
              }
          });
    });`;
    document.body.appendChild(chatScript);
  }


}
