export class cVideoDetails
{
  private id: string;
  private thumb: string;
  private url: string;
  private title: string;
  private duration: string;
  private created: string;
  private automated: string;
  private color: string;
  private days_ago: string;
  private video_id: string;
  private key_terms: [];
  private music_file: string;
  private selected_voice: string;
  private status: string;
  private template_name: string; 
  private text_list: string;
  private vo_text: string;
  private about: string;
  private aspect_ratio: string;
  private font_color: string;
  private logo_img: string;
  private second_logo_img: string;
  private template_category: string;
  private user_clips: [];
  private user_images: [];
  private address: string;
  private vo_file: string;
  private use_pronto_clips: boolean
  private block_config : [];
  private comments: []
  private locales: []
  constructor
  (
    id: string, thumb: string, url: string, title: string, duration: string, created: string, automated: string, color: string,
    days_ago: string, video_id: string, key_terms: any, music_file: string, selected_voice: string, status: string, template_name: string,
    text_list: string, vo_text: string, about: string, aspect_ratio: string, font_color: string, logo_img: string, second_logo_img: string, template_category: string,
    user_clips: any, user_images: any, address: string, vo_file: string, use_pronto_clips: boolean, block_config: any, comments: any, locales: any,
  )
  {
    this.id = id;
    this.thumb = thumb;
    this.url = url;
    this.title = title;
    this.duration = duration;
    this.created = created;
    this.automated = automated;
    this.color = color;
    this.days_ago = days_ago;
    this.video_id = video_id;
    this.key_terms = key_terms;
    this.music_file = music_file;
    this.selected_voice = selected_voice;
    this.status = status;
    this.template_name = template_name;
    this.text_list = text_list;
    this.vo_text = vo_text;
    this.about = about;
    this.aspect_ratio = aspect_ratio;
    this.font_color = font_color;
    this.logo_img = logo_img;
    this.second_logo_img = second_logo_img;
    this.template_category = template_category;
    this.user_clips = user_clips;
    this.user_images = user_images;
    this.address = address;
    this.vo_file = vo_file;
    this.use_pronto_clips = use_pronto_clips;
    this.block_config = block_config;
    this.comments = comments,
    this.locales = locales
  }
}