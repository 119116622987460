import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-video-header',
  templateUrl: './create-video-header.component.html',
  styleUrls: ['./create-video-header.component.scss']
})
export class CreateVideoHeaderComponent implements OnInit {
  value : string = "Next"
  constructor(private oRouter : Router) { }

  ngOnInit(): void {
  }
  NavigateToNextTab(){
     if(this.oRouter.url === 'wizard/overview'){
       this.oRouter.navigateByUrl('/wizard/looknfeel')
       this.value = 'Next'
       console.log("value:", this.value);
       
     }
     else if (this.oRouter.url === '/wizard/looknfeel'){
      this.oRouter.navigateByUrl('/wizard/content')
      this.value = 'Next'
      console.log("value:", this.value);

     }
     else if (this.oRouter.url === '/wizard/content'){
       this.oRouter.navigateByUrl('/wizard/other-setting')
       this.value = 'Create Video'
       console.log("value:", this.value);

     }
     else if (this.oRouter.url === '/wizard/other-setting'){
      this.oRouter.navigateByUrl('/wizard/preview')
      this.value = 'Create Video'
      console.log("value:", this.value);

    }
  }

}
