export class cBlockConfigurations
{
  private sBlockID: string;
  private block_file_path: string;
  private url: string;
  private thumb: string;
  private thumb_url : string;
  private effects : any = [];
  private num_clips : number = 0;
  private clips : any = [];
  private block_args : any = [];
  private lTextAreaDetails : any = [];
  private nDuration : number = 0;
  private nTextAreaCount : number = 0;

  constructor
  (
    sBlockID: string, block_file_path : string, url: string, thumb: string, thumb_url: string, effects: any,
    num_clips : number, clips : any, block_args : any, lTextAreaDetails : any, nDuration : number, nTextAreaCount : number
  )
  {
    this.sBlockID = sBlockID;
    this.block_file_path = block_file_path;
    this.url = url;
    this.thumb = thumb;
    this.thumb_url = thumb_url;
    this.effects = effects;
    this.num_clips = num_clips;
    this.clips = clips;
    this.block_args = block_args;
    this.lTextAreaDetails = lTextAreaDetails;
    this.nDuration = nDuration;
    this.nTextAreaCount = nTextAreaCount;
  }
}