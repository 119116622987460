
<app-edit-video-header></app-edit-video-header>

    <section class="accountSec main-body bg-gray-7 h-100 width-72">
        
        <div class="container">


    <app-edit-video-breadcrumb></app-edit-video-breadcrumb>
           
            <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow" id="pills-tabContent">
                <div class="tab-pane fade show active">

                    <div class="content_area ps-5 ms-4">
                        <div class="row">

                            <div class="col-sm-12 col-md-12 col-lg-10">
                                <p class="text-md text-gray-3 mb-2 primaryFont">What business information you want display on-screens?</p>
                                <div class="d-flex align-items-start check_toggle_content">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input website" type="checkbox" id="flexSwitchCheckChecked" checked>
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Website</p>
                                        <div class="row website-div">
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                
                                                <div class="formItm">
                                                    <input id="website-address " type="text" value="https://www.I"
                                                        placeholder="Enter website address" name="textfeild" required>
                                                    <label for="website-address" class="text-sm">URL</label>
                                                </div>
                                             </div>   
                                        </div>
                                    </div>

                                </div>

                                <div class="d-flex align-items-start check_toggle_content">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input address" type="checkbox" id="flexSwitchCheckChecked" checked>
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Address</p>
                                        <div class="row address-div">
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="address1" type="text" value=""
                                                        placeholder="Address1" name="textfeild" required>
                                                    <label for="address1" class="text-sm">Address1</label>
                                                </div>
                                             </div>   
                                             <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="address2" type="text" value=""
                                                        placeholder="Address2" name="textfeild" required>
                                                    <label for="address2" class="text-sm">Address2</label>
                                                </div>
                                             </div>   
                                             <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="city" type="text" value=""
                                                        placeholder="city" name="textfeild" required>
                                                    <label for="city" class="text-sm">City</label>
                                                </div>
                                             </div> 
                                             <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="State" type="text" value=""
                                                        placeholder="State" name="textfeild" required>
                                                    <label for="State" class="text-sm">State</label>
                                                </div>
                                             </div> 
                                             <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="Zipcode" type="text" value=""
                                                        placeholder="Zipcode" name="textfeild" required>
                                                    <label for="Zipcode" class="text-sm">Zipcode</label>
                                                </div>
                                             </div> 
                                             <div class="col-sm-12 col-md-12 col-lg-5">
                                                <div class="formItm">
                                                    <input id="Country" type="text" value=""
                                                        placeholder="Country" name="textfeild" required>
                                                    <label for="Country" class="text-sm">Country</label>
                                                </div>
                                             </div> 
                                        </div>
                                    </div>

                                </div>

                                <div class="d-flex align-items-start check_toggle_content">
                                    <div class="check_toggle">
                                        <div class="form-switch toggle_switch">
                                            <input class="form-check-input phone" type="checkbox" id="flexSwitchCheckChecked">
                                        </div>
                                    </div>
                                    <div class="check_content w-100">
                                        <p class="text-md text-gray-1 pb-1 mb-0 primaryFont">Phone Number</p>
                                        <div class="row phone-div" style="display: none;">
                                            <div class="col-sm-12 col-md-12 col-lg-5">
                                                
                                                <div class="formItm">
                                                    <input id="phone-no" type="text" value=""
                                                        placeholder="Enter your phone" appPhoneMask name="textfeild" required>
                                                    <label for="phone-no" class="text-sm">Phone</label>
                                                </div>
                                             </div>   
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>



        </div>
    
    </section>
<app-edit-video-sidepanels></app-edit-video-sidepanels>
    <div class="overlay"></div>
    