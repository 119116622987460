<header class="header header-1 drop-shadow">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <div class="d-flex w-100">
                <div class="col d-flex align-items-center">
                    <div class="back_cta"> 
                        <a href="javascript:void(0)" (click)="NavigateToDashboard()" class="closeSideBar">
                            <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                        </a>
                    </div>
                    <h5 class="text-gray-1 ">{{sVideoTitle}}
                        <span class="d-block text-xs text-gray-3 secondaryFont weight-400">{{sVideoStatus==="published"?"Published":"Created"}} {{sVideoCreationDate | date}}</span>
                    </h5>
                    <span class="ms-2 text-xs bg-gray-3 text-white labelTag">{{sVideoDuration}}s</span>
                </div>
                <div class="col text-end">
                    <a  href="javascript:void(0)" class="text-gray-6 text-sm me-4 closeSideBar"  (click)="NavigateToDashboard()">Cancel</a>
                    <a  class="btn btn-md btn-secondary btn-round-6 closeSideBar" (click)="EditVideoHeaderComponent_SendUpdateVideoRequest()">Save Edits</a>
                </div>
            </div>
        </nav>
    </div>
</header>