
    <header class="header header-1 drop-shadow">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <div class="d-flex w-100">
                    <div class="col d-flex align-items-center">
                        <div class="back_cta h-auto pb-1">
                            <a href="./editVideo-timelineView.html">
                                <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                            </a>
                        </div>
                        <h5 class="text-gray-1 pt-2">Family Chiropractic Center of Richmod</h5>
                        <span class="ms-2 text-xs bg-gray-3 text-white labelTag">15s</span>
                    </div>
                </div>

            </nav>
        </div>
    </header>


    <section class="accountSec main-body bg-gray-7 h-100">
        <div class="container">

            <ul class="breadcrumb-tabs nav">
                <li class="nav-item" role="presentation">
                    <a href="editVideo-timelineView.html" class="nav-link">Edit Video</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a href="../dashboardVideo/myVideo.html" class="nav-link">Video Options</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a href="publishVideo-timelineView.html" class="nav-link active">Publish</a>
                </li>
            </ul>
            <div class="h-80vh br12 curve-bg tab-content bg-white page-shadow align-items-center justify-content-center d-flex"
                id="pills-tabContent">
           
                <div class="text-center">
                    <div class="row">
                        <div class="col">
                            <div class="loadInfo svgItem svgleft load1pos active">
                                <div class="loadText">
                                    <h5 class="text-primary-1 weight-700 pb-2">Did you know?</h5>
                                    <p class="text-gray-2 m-0">Videos are <b class="h3large text-primary-3">4x</b> more engaging than other types of content</p>
                                    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="35.5" cy="35.5" r="35.5" fill="#FF8369"/>
                                        <path d="M26 14L29 20.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M42.546 13.7905C42.6617 13.5397 42.9588 13.4303 43.2095 13.546C43.4603 13.6617 43.5697 13.9588 43.454 14.2095L42.546 13.7905ZM40.454 20.7095C40.3383 20.9603 40.0412 21.0697 39.7905 20.954C39.5397 20.8383 39.4303 20.5412 39.546 20.2905L40.454 20.7095ZM43.454 14.2095L40.454 20.7095L39.546 20.2905L42.546 13.7905L43.454 14.2095Z" fill="white"/>
                                        <path d="M33.6299 11L34.1286 18.1415" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24.9336 28.8066C27.3002 28.2606 29.3341 28.4435 31.0354 29.3555C32.7588 30.2623 33.8807 31.8437 34.4013 34.0997C34.881 36.1787 34.5704 37.9625 33.4696 39.4511C32.3859 40.9125 30.7296 41.912 28.5009 42.4496L28.9091 44.976L23.9327 46.1243L22.343 39.9918L24.3336 39.5325C26.0366 39.1396 27.282 38.6309 28.0697 38.0066C28.8796 37.3771 29.1569 36.5094 28.9017 35.4036C28.7231 34.6295 28.3727 34.0697 27.8504 33.7244C27.3281 33.379 26.6909 33.293 25.9389 33.4666C25.1427 33.6503 24.577 34.0254 24.2419 34.5919C23.9016 35.1363 23.8207 35.7956 23.9994 36.5697L18.658 37.8021C18.3025 36.4632 18.2983 35.183 18.6454 33.9614C19.0146 32.7348 19.7249 31.674 20.7764 30.7792C21.8448 29.8571 23.2306 29.1996 24.9336 28.8066ZM28.4775 54.1606C27.4823 54.3902 26.5976 54.2915 25.8235 53.8645C25.0664 53.4102 24.5884 52.7518 24.3894 51.8892C24.1852 51.0045 24.3239 50.1921 24.8054 49.4521C25.309 48.7069 26.0585 48.2195 27.0537 47.9899C28.0269 47.7653 28.892 47.8802 29.6491 48.3345C30.4283 48.7836 30.9199 49.4506 31.1241 50.3352C31.3231 51.1978 31.1708 52.0017 30.6672 52.7468C30.1806 53.4648 29.4507 53.936 28.4775 54.1606Z" fill="white"/>
                                        <path d="M46.8873 29.8422L43.7089 45.8527L38.8306 45.0943L40.6632 28.8745L46.8873 29.8422ZM40.0148 54.0993C39.0055 53.9424 38.2209 53.5218 37.6612 52.8375C37.1273 52.1343 36.9284 51.3453 37.0644 50.4706C37.2039 49.5734 37.6347 48.8709 38.3568 48.3629C39.1014 47.8585 39.9784 47.6847 40.9877 47.8416C41.9746 47.9951 42.7349 48.4234 43.2688 49.1266C43.8251 49.8333 44.0335 50.6353 43.894 51.5324C43.758 52.4071 43.3177 53.0967 42.5731 53.6012C41.8544 54.0867 41.0016 54.2527 40.0148 54.0993Z" fill="white"/>
                                        </svg>                                        
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="loadInfo svgItem svgright load2pos">
                                <div class="loadText">
                                    <h5 class="text-primary-1 weight-700 pb-2">Did you know?</h5>
                                    <h3 class="h3large text-primary-3 secondaryFont ">39 Billion</h3>
                                    <p class="text-gray-2 m-0">videos are viewed per month in the U.S.</p>
                                    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="35.5" cy="35.5" r="35.5" fill="#FF8369"/>
                                        <path d="M26 14L29 20.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M42.546 13.7905C42.6617 13.5397 42.9588 13.4303 43.2095 13.546C43.4603 13.6617 43.5697 13.9588 43.454 14.2095L42.546 13.7905ZM40.454 20.7095C40.3383 20.9603 40.0412 21.0697 39.7905 20.954C39.5397 20.8383 39.4303 20.5412 39.546 20.2905L40.454 20.7095ZM43.454 14.2095L40.454 20.7095L39.546 20.2905L42.546 13.7905L43.454 14.2095Z" fill="white"/>
                                        <path d="M33.6299 11L34.1286 18.1415" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24.9336 28.8066C27.3002 28.2606 29.3341 28.4435 31.0354 29.3555C32.7588 30.2623 33.8807 31.8437 34.4013 34.0997C34.881 36.1787 34.5704 37.9625 33.4696 39.4511C32.3859 40.9125 30.7296 41.912 28.5009 42.4496L28.9091 44.976L23.9327 46.1243L22.343 39.9918L24.3336 39.5325C26.0366 39.1396 27.282 38.6309 28.0697 38.0066C28.8796 37.3771 29.1569 36.5094 28.9017 35.4036C28.7231 34.6295 28.3727 34.0697 27.8504 33.7244C27.3281 33.379 26.6909 33.293 25.9389 33.4666C25.1427 33.6503 24.577 34.0254 24.2419 34.5919C23.9016 35.1363 23.8207 35.7956 23.9994 36.5697L18.658 37.8021C18.3025 36.4632 18.2983 35.183 18.6454 33.9614C19.0146 32.7348 19.7249 31.674 20.7764 30.7792C21.8448 29.8571 23.2306 29.1996 24.9336 28.8066ZM28.4775 54.1606C27.4823 54.3902 26.5976 54.2915 25.8235 53.8645C25.0664 53.4102 24.5884 52.7518 24.3894 51.8892C24.1852 51.0045 24.3239 50.1921 24.8054 49.4521C25.309 48.7069 26.0585 48.2195 27.0537 47.9899C28.0269 47.7653 28.892 47.8802 29.6491 48.3345C30.4283 48.7836 30.9199 49.4506 31.1241 50.3352C31.3231 51.1978 31.1708 52.0017 30.6672 52.7468C30.1806 53.4648 29.4507 53.936 28.4775 54.1606Z" fill="white"/>
                                        <path d="M46.8873 29.8422L43.7089 45.8527L38.8306 45.0943L40.6632 28.8745L46.8873 29.8422ZM40.0148 54.0993C39.0055 53.9424 38.2209 53.5218 37.6612 52.8375C37.1273 52.1343 36.9284 51.3453 37.0644 50.4706C37.2039 49.5734 37.6347 48.8709 38.3568 48.3629C39.1014 47.8585 39.9784 47.6847 40.9877 47.8416C41.9746 47.9951 42.7349 48.4234 43.2688 49.1266C43.8251 49.8333 44.0335 50.6353 43.894 51.5324C43.758 52.4071 43.3177 53.0967 42.5731 53.6012C41.8544 54.0867 41.0016 54.2527 40.0148 54.0993Z" fill="white"/>
                                        </svg>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg width="109" height="124" viewBox="0 0 109 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M67.1216 38.7933C71.1368 21.9564 55.2405 -7.09745e-05 55.2405 -7.09745e-05C55.2405 -7.09745e-05 49.5424 10.9363 45.9474 24.8733C42.3524 38.8103 44.442 52.1696 44.442 52.1696C44.442 52.1696 63.1063 55.6302 67.1216 38.7933Z"
                            fill="url(#paint0_linear_3473:68612)" />
                        <path
                            d="M69.8942 44.0086C78.1436 28.7743 68.4857 3.4787 68.4857 3.4787C68.4857 3.4787 60.1421 12.5803 53.0514 25.121C45.9606 37.6617 44.3984 51.8064 44.3984 51.8064C44.3984 51.8064 61.6447 59.2429 69.8942 44.0086Z"
                            fill="url(#paint1_linear_3473:68612)" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M19.6962 50.7485C19.1911 42.7087 25.7571 29.142 50.5057 39.1917V39.1196C67.9042 48.0517 79.7998 66.1076 79.7998 86.9276C79.7998 101.299 74.1319 114.353 64.8987 123.999C66.7031 118.498 67.6787 112.625 67.6787 106.525C67.6787 83.5308 53.8169 63.7577 33.9532 55.025C25.781 52.8586 13.3256 53.0037 0 62.5029C2.46284 59.0707 9.97928 51.9731 20.3424 51.0405C20.3412 51.0248 20.3401 51.009 20.339 50.9932C19.9052 50.9224 19.4698 50.8565 19.0328 50.7956L19.6962 50.7485Z"
                            fill="url(#paint2_linear_3473:68612)" />
                        <path
                            d="M81.9774 46.0173C93.895 33.413 91.1171 6.49564 91.1171 6.49564C91.1171 6.49564 80.6974 13.1502 70.5971 23.449C60.4969 33.7478 55.614 46.3651 55.614 46.3651C55.614 46.3651 70.0597 58.6215 81.9774 46.0173Z"
                            fill="url(#paint3_linear_3473:68612)" />
                        <path
                            d="M80.6136 55.2339C96.2709 47.6968 103.342 21.5684 103.342 21.5684C103.342 21.5684 91.2201 24.0807 78.0882 30.1104C64.9563 36.14 55.3599 46.6919 55.3599 46.6919C55.3599 46.6919 64.9563 62.771 80.6136 55.2339Z"
                            fill="url(#paint4_linear_3473:68612)" />
                        <path
                            d="M74.0634 65.5159C91.4559 65.2976 109 44.62 109 44.62C109 44.62 96.9512 41.7799 82.4883 41.6988C68.0254 41.6177 55.5077 46.8819 55.5077 46.8819C55.5077 46.8819 56.6709 65.7342 74.0634 65.5159Z"
                            fill="url(#paint5_linear_3473:68612)" />
                        <ellipse cx="29.2942" cy="45.2223" rx="2.0203" ry="2.00988" fill="white" />
                        <defs>
                            <linearGradient id="paint0_linear_3473:68612" x1="36.9848" y1="70.6649" x2="62.8841"
                                y2="8.18903" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#483D8F" />
                                <stop offset="1" stop-color="#483D8F" stop-opacity="0" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_3473:68612" x1="46.8539" y1="48.8461" x2="83.3972"
                                y2="-11.288" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FF9C61" />
                                <stop offset="1" stop-color="#FF9C61" stop-opacity="0" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_3473:68612" x1="83.3349" y1="56.2758" x2="-3.53032"
                                y2="49.6766" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FD5E21" />
                                <stop offset="0.764706" stop-color="#483D8F" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_3473:68612" x1="39.8815" y1="58.6999" x2="93.5843"
                                y2="17.3135" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#483D8F" />
                                <stop offset="1" stop-color="#483D8F" stop-opacity="0" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_3473:68612" x1="59.0932" y1="45.7242" x2="124.147"
                                y2="18.3513" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FF9C61" />
                                <stop offset="1" stop-color="#FF9C61" stop-opacity="0" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_3473:68612" x1="35.6615" y1="44.1818" x2="102.821"
                                y2="54.3333" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#483D8F" />
                                <stop offset="1" stop-color="#483D8F" stop-opacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="timelineViewProcessed my-4">
                        <span style="width: 30%;"></span>
                    </div>
                    <h2 class="text-gray-2">Your video is being processed. </h2>
                    <p class="text-gray-3 text-lg mt-1">We'll alert you once the process is complete.</p>
                </div>
                <div class="wtermarklogo">
                    <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
                </div>
            </div>



        </div>
    </section>
