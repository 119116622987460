import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-video-content',
  templateUrl: './edit-video-content.component.html',
  styleUrls: ['./edit-video-content.component.scss']
})
export class EditVideoContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `{
    var elSelectCustom = document.getElementsByClassName("js-selectCustom")[0];
    var elSelectCustomValue = elSelectCustom.children[0];
    var elSelectCustomOptions = elSelectCustom.children[1];
    var defaultLabel = elSelectCustomValue.getAttribute("data-value");

// Listen for each custom option click
Array.from(elSelectCustomOptions.children).forEach(function (elOption) {
  elOption.addEventListener("click", (e) => {
    // Update custom select text too
    elSelectCustomValue.textContent = e.target.textContent;
    // Close select
    elSelectCustom.classList.remove("isActive");
  });
});

// Toggle select on label click
elSelectCustomValue.addEventListener("click", (e) => {
  elSelectCustom.classList.toggle("isActive");
});

// close the custom select when clicking outside.
document.addEventListener("click", (e) => {
  const didClickedOutside = !elSelectCustom.contains(event.target);
  if (didClickedOutside) {
    elSelectCustom.classList.remove("isActive");
  }
});
// smooth Loaded  start
document.addEventListener("DOMContentLoaded", function(){
  
  var scrollSpy = new bootstrap.ScrollSpy(document.body, {
    target: '#main_nav',
  })

  // add smooth animated scroll via javascript
  document.querySelectorAll('.scrollto').forEach(function(element){
    element.addEventListener('click', function(e) {
      e.preventDefault();
      var section_name = element.getAttribute('data-bs-target');
      var offset_num = document.querySelector(section_name).offsetTop;
        window.scrollTo({ top:(offset_num - 0), behavior:'smooth' });
    });
  })

}); 
    // smooth Loaded  end

       var selector = '#main_nav li a';   
                
          $(selector).on('click', function(){
              $(selector).removeClass('active');
              $(this).addClass('active');

              $('.video-timeline').removeClass('active')
              $($(this).attr("data-bs-target")).addClass('active')
          });
}`;
    document.body.appendChild(chatScript);
  }

}
