import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-sidebar-videos',
  templateUrl: './reports-sidebar-videos.component.html',
  styleUrls: ['./reports-sidebar-videos.component.scss']
})
export class ReportsSidebarVideosComponent implements OnInit {
lTopVideos : any = ['item','item','item','item','item','item','item','item','item','item',]
  constructor() { }

  ngOnInit(): void {
  }

}
