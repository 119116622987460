
    <section class="loginSec">
        <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
        <div class="loginSecItm loginSecRight">

            <div class="loginSecItmContent-plane d-flex flex-column flex-grow-1">
                <div class="d-lg-none pb-5">
                    <a href="./login.html">
                        <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                    </a>
                </div>
                <a routerLink="/create-account/select-plan">
                    <img src="../assets/images/onBoarding/back-arrow.svg" alt="back-arrow" />
                </a>
                <h2 class="text-gray-1 mt-5">Complete Payment</h2>
                <p class="text-sm mt-3 ms-1 pb-0 mb-0">
                    Fill in your information to complete your payment via Stripe.
                </p>
                <div class="loginSecItmForm d-flex flex-column flex-grow-1" aria-labelledby="loginYourself">
                    <form class="needs-validation d-flex flex-column flex-grow-1" novalidate>
                        <!-- <h4 class="text-gray-1 mb-4">Business Information</h4> -->
                        <div class="flex-grow-1">
                            <div class="row mb-4">
                                <div class="col-md-5">
                                    <div class="formItm">
                                        <input class="text-gray-2 weight-600" id="inputNameonCard" type="text"
                                            value="Aaron" placeholder="Name on card" required>
                                        <label for="inputNameonCard" class="text-sm">Name on card</label>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="formItm">
                                        <input class="text-gray-2 weight-600" id="inputCardNumber" type="number"
                                            value="" placeholder="Card number" required>
                                        <label for="inputCardNumber" class="text-sm">Card number</label>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="formItm">
                                        <input class="text-gray-2 weight-600" id="inputExpiryOn" type="text" value=""
                                            placeholder="Expiry on" required>
                                        <label for="inputExpiryOn" class="text-sm">Expiry on</label>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="formItm">
                                        <input class="text-gray-2 weight-600" id="inputCVV" type="number" value=""
                                            placeholder="CVV" required>
                                        <label for="inputCVV" class="text-sm">CVV</label>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <label for="inputCVV" class="text-sm">Country or Region</label>
                                    <div class="dropdown dropdownSelect dropdownSelect">
                                        <button class="dropdown-toggle text-md text-gray-2 weight-600 borderPrimary-6"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="dropdownBtnText">United States</span>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end dropdownMenu w-100">
                                            <li><a class="dropdown-item text-md active" href="#;">United States</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="formItm">
                                        <input class="text-gray-2 weight-600" id="inputZipcode" type="number" value=""
                                            placeholder="Zipcode" required>
                                        <label for="inputZipcode" class="text-sm">Zip code</label>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-2">
                                    <img src="../assets/images/onBoarding/poweedByStripeLogo.png"
                                        alt="poweedByStripeLogo" />
                                </div>

                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-5">
                                <div class="row g-0">
                                    <div class="col">
                                        <div class="mb-3">
                                            <a routerLink="/create-account/paywall-failed" class="btn btn-secondary btn-round-6 px-4">Complete Payment</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </form>
                </div>
            </div>

        </div>
    </section>

    <nav id="sidebar">


        <div class="sidebar-header pb-1 px-4 mx-1">
            <h3 class="text-gray-2 weight-700">What industry are you in?</h3>
            <p class="text-gray-3 text-xs pt-2 pe-4">Knowing your industry will help us pick relatable graphics, images
                and content.</p>
            <div id="dismiss">

                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                            fill="#7A7A7A" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="11.8" height="11.8" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
        </div>

        <div class="row row mt-2 mx-0 px-3">
            <div class="col-md-12">
                <ul class="selct_business selct_business1">
                    <li>
                        <a href="#" class="active">
                            <figure>
                                <svg width="27" height="24" viewBox="0 0 27 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M25.3815 2.49618C23.2572 -0.186797 20.42 -0.370636 17.8541 0.387247C16.1181 0.900352 14.4151 2.15707 13.4763 2.16592C12.417 2.1759 10.7366 0.765139 8.7168 0.287497C6.303 -0.28544 3.47251 0.228762 1.57977 2.70896C-0.0017973 4.78353 -0.451411 7.71688 0.484148 10.8822H5.51596L8.08985 4.55756C8.18182 4.33038 8.41457 4.18742 8.66323 4.20072C8.91301 4.21513 9.12647 4.38358 9.19117 4.61964L12.1227 15.2131L13.7701 8.14947C13.8292 7.89569 14.0574 7.71393 14.3242 7.7084C14.5921 7.70396 14.8272 7.87463 14.8976 8.1262L15.7877 11.3023L17.258 6.4969C17.3329 6.25199 17.568 6.08686 17.8291 6.0924C18.0913 6.09794 18.3162 6.27414 18.3797 6.52238L20.0033 12.8992L20.781 11.218C20.8753 11.0152 21.0819 10.8844 21.3112 10.8844H23.5331C23.8545 10.8844 24.1145 11.1382 24.1145 11.4518C24.1145 11.7655 23.8545 12.0192 23.5331 12.0192H21.6859L20.3734 14.8574C20.2712 15.0779 20.0374 15.2109 19.791 15.1888C19.5446 15.1666 19.3391 14.9948 19.2789 14.761L17.7632 8.81089L16.3202 13.5286C16.2464 13.7691 16.0205 13.9342 15.7627 13.9342H15.7559C15.497 13.9309 15.2699 13.7613 15.2018 13.5164L14.3878 10.6107L12.7358 17.6899C12.6768 17.9437 12.4474 18.1266 12.1806 18.131H12.1693C11.907 18.131 11.6765 17.9592 11.6084 17.7121L8.52482 6.56229L6.4505 11.659C6.36308 11.8751 6.14848 12.017 5.91006 12.017H0.875922C2.53355 16.2126 6.6117 20.6756 13.6827 24C26.3341 18.2096 29.3121 7.45672 25.3816 2.49618H25.3815Z"
                                        fill="#A39FAA" />
                                </svg>
                            </figure>
                            <span class="text-center text-xs">Healthcare</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <figure>
                                <svg width="28" height="24" viewBox="0 0 28 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M27.5202 10.5463C27.2685 10.0613 26.5953 9.51996 26.5953 9.51996L23.8208 2.13717C23.2319 0.740975 21.5508 0 20.8223 0H7.20245C6.47389 0 4.76389 0.740975 4.20393 2.13717L1.4294 9.51996C1.4294 9.51996 0.756243 10.0613 0.504563 10.5463C0.252882 11.0313 0 11.4587 0 14.736C0 17.9291 0.644245 19.0678 1.40172 19.4391L2.18688 19.4966V22.4899C2.18688 23.4586 2.74684 24 3.67168 24H4.48453C5.40937 24 5.88625 23.4586 5.88625 22.4899L5.88504 19.4966H22.112V22.4899C22.112 23.4586 22.6166 24 23.5137 24H24.3266C25.2514 24 25.8114 23.4586 25.8114 22.4899V19.4966L26.5965 19.4391C27.3817 19.0398 27.9983 17.8995 27.9983 14.736C28.0247 11.4587 27.7454 11.0313 27.5202 10.5463H27.5202ZM5.40813 16.2755C4.42669 16.2755 3.61505 15.4488 3.61505 14.4519C3.61505 13.4537 4.42789 12.6282 5.40813 12.6282C6.38958 12.6282 7.20122 13.4549 7.20122 14.4519C7.20242 15.4488 6.41726 16.2755 5.40813 16.2755ZM22.6718 16.2755C21.6904 16.2755 20.8788 15.4488 20.8788 14.4519C20.8788 13.4537 21.6916 12.6282 22.6718 12.6282C23.6533 12.6282 24.4649 13.4549 24.4649 14.4519C24.4661 15.4488 23.6533 16.2755 22.6718 16.2755ZM22.6164 8.23742H5.40823C5.40823 8.23742 5.07227 8.26559 5.18425 7.92389C5.26854 7.63852 6.41737 4.67473 6.66905 3.87616C6.78104 3.59079 7.34221 2.96371 8.09845 2.99312H19.9528C20.709 2.93556 21.2702 3.59204 21.3822 3.87616C21.6339 4.6747 22.7839 7.63857 22.867 7.92389C22.9525 8.26557 22.6165 8.23742 22.6165 8.23742H22.6164Z"
                                        fill="#A39FAA" />
                                </svg>
                            </figure>
                            <span class="text-center text-xs">Auto</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <figure>
                                <svg width="24" height="27" viewBox="0 0 24 27" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M23.1117 6.45421L23.228 6.47859L20.3577 0.737909C20.2081 0.437568 19.9011 0.248047 19.5653 0.248047H5.37955C5.04375 0.248047 4.73677 0.43756 4.58714 0.737909L1.7168 6.47859L1.83317 6.45421H23.1117ZM17.793 12.6611C17.0261 13.2363 16.0919 13.5477 15.1332 13.5477C14.1746 13.5477 13.2403 13.2363 12.4734 12.6611C11.7065 13.2363 10.7722 13.5477 9.81357 13.5477C8.85493 13.5477 7.92066 13.2363 7.15375 12.6611C6.38684 13.2385 5.45367 13.55 4.49392 13.5477C3.88328 13.55 3.27815 13.4214 2.7207 13.1732V25.9602C2.7207 26.1951 2.8138 26.4212 2.98004 26.5874C3.14628 26.7537 3.37236 26.8468 3.6073 26.8468H8.92695V19.5167C8.94468 19.1421 9.26165 18.8517 9.63624 18.8673H15.3105C15.6851 18.8518 16.0021 19.1421 16.0198 19.5167V26.8468H21.3395C21.5744 26.8468 21.8005 26.7537 21.9667 26.5874C22.133 26.4212 22.2261 26.1951 22.2261 25.9602V13.1732C21.6686 13.4214 21.0635 13.55 20.4529 13.5477C19.4931 13.55 18.5599 13.2385 17.793 12.6611ZM1.83387 7.34082H23.1125L23.1125 7.34083C23.3474 7.34083 23.5735 7.43392 23.7398 7.60016C23.906 7.76639 23.9991 7.99248 23.9991 8.22743V9.11404C23.998 10.5893 23.0848 11.9092 21.7061 12.4301C20.3263 12.951 18.7681 12.5642 17.7928 11.4581C17.1201 12.2217 16.1504 12.6595 15.133 12.6595C14.1156 12.6595 13.1459 12.2217 12.4732 11.4581C11.8005 12.2217 10.8307 12.6595 9.81335 12.6595C8.79598 12.6595 7.82624 12.2217 7.15353 11.4581C6.17811 12.5642 4.62004 12.951 3.24025 12.4301C1.86159 11.9091 0.948374 10.5891 0.947266 9.11404V8.22743C0.947266 7.73757 1.34401 7.34082 1.83387 7.34082Z"
                                        fill="#A39FAA" />
                                </svg>

                            </figure>
                            <span class="text-center text-xs">Retail</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <figure>
                                <svg width="30" height="26" viewBox="0 0 30 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0.451146 9.57692L14.4301 0.173488L14.4297 0.173823C14.7747 -0.0579411 15.2252 -0.0579411 15.5703 0.173823L29.5493 9.57325C29.7927 9.73732 29.9542 9.99771 29.992 10.2894C30.0297 10.5797 29.9399 10.8727 29.7459 11.094L29.7472 11.0927L28.5936 12.4065C28.255 12.7906 27.6847 12.8648 27.2603 12.5797L15.5666 4.72688C15.2228 4.49642 14.7736 4.49642 14.4299 4.72688L2.74015 12.5834C2.31569 12.8685 1.74537 12.7943 1.40685 12.4102H1.40815L0.254545 11.0964C0.0605396 10.8764 -0.0293014 10.5834 0.00845767 10.2917C0.0462234 10.0014 0.207663 9.74098 0.451146 9.57692ZM15.5707 6.33398L25.3074 12.9211L25.3075 12.9212C25.5861 13.11 25.7528 13.4264 25.7541 13.7636V24.314C25.7541 24.5836 25.646 24.844 25.4546 25.0354C25.2632 25.2255 25.0041 25.3336 24.7332 25.3336H12.3572V17.1738C12.3572 17.0514 12.3077 16.9329 12.2218 16.8469C12.1346 16.7597 12.0161 16.7102 11.8937 16.7102H8.56045C8.30525 16.7102 8.09692 16.9185 8.09692 17.1737V25.3335H5.26756C4.99674 25.3348 4.73762 25.2281 4.54491 25.0367C4.35351 24.8465 4.24544 24.5875 4.24414 24.3166V13.7636C4.24544 13.425 4.41342 13.1099 4.69336 12.9211L14.4275 6.33398C14.7725 6.10091 15.2256 6.10091 15.5707 6.33398ZM22.0262 20.0056C22.0887 19.9431 22.1239 19.8585 22.1239 19.77L22.1266 16.6906C22.1266 16.6021 22.0914 16.5174 22.0289 16.455C21.9664 16.3925 21.8818 16.3573 21.7932 16.3573H18.1867C18.0031 16.3573 17.8533 16.507 17.8533 16.6906V19.77C17.8533 19.9549 18.0031 20.1033 18.1867 20.1033H21.7906C21.8791 20.1033 21.9637 20.0681 22.0262 20.0056Z"
                                        fill="#A39FAA" />
                                </svg>
                            </figure>
                            <span class="text-center text-xs">Real estate</span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <figure>
                                <svg width="33" height="23" viewBox="0 0 33 23" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18.6002 0.194501C18.8519 -0.0220606 19.2119 -0.057218 19.5002 0.0862204V0.0863495C19.7884 0.231213 19.9684 0.519487 19.9684 0.842926V21.0029C19.9684 21.3278 19.7884 21.6161 19.5002 21.7595C19.3919 21.7947 19.2484 21.8312 19.1402 21.8312C18.9236 21.8312 18.7436 21.7595 18.6002 21.6512L11.7969 16.0712V5.81122L18.6002 0.194501ZM26.1604 2.06747C26.6652 1.52747 27.5287 1.45576 28.0687 1.95918V1.95939C30.624 4.22631 32.1005 7.50271 32.0654 10.9241C32.0654 14.3441 30.6252 17.6191 28.0687 19.8874C27.8169 20.1391 27.4921 20.2474 27.1687 20.2474C26.8087 20.2474 26.4121 20.104 26.1604 19.8157C25.6569 19.2391 25.7286 18.4108 26.2686 17.9074C28.2486 16.1074 29.4004 13.5875 29.4004 10.9241C29.4004 8.29575 28.2486 5.73918 26.2686 3.97575C25.7286 3.47089 25.6569 2.60747 26.1604 2.06747ZM23.1016 5.84718C23.6416 5.30719 24.4699 5.30719 25.0099 5.81062V5.81082C26.4134 7.14246 27.2051 8.97896 27.2065 10.9955C27.2065 12.9389 26.4134 14.7389 25.0099 16.1072C24.7568 16.3589 24.3968 16.4672 24.0734 16.4672C23.7134 16.4672 23.3534 16.3589 23.1016 16.0706C22.5982 15.5306 22.5982 14.6657 23.1382 14.1623C24.0382 13.2989 24.5065 12.1823 24.5065 10.9589C24.5065 9.73547 24.0016 8.58376 23.1382 7.75547C22.5982 7.21547 22.5982 6.38718 23.1016 5.84718ZM0 9.3102V13.3864C0 14.643 0.961563 15.6966 2.1798 15.6966H3.04495L4.13421 22.3551C4.19806 22.7293 4.51858 23 4.87166 23H7.75635C7.98047 23 8.17329 22.8978 8.33355 22.728C8.46126 22.5581 8.52636 22.3206 8.49381 22.0831L7.43583 15.6967H10V7H2.17993C0.961691 7 0.00012823 8.01906 0.00012823 9.31014L0 9.3102Z"
                                        fill="#A39FAA" />
                                </svg>
                            </figure>
                            <span class="text-center text-xs">Advertising</span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <figure>
                                <svg width="28" height="24" viewBox="0 0 28 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.33339 0.00028744C4.34007 0.00028744 0.260416 1.85799 0.0104166 4.19424C0.14974 5.50178 1.61328 6.41089 2.56248 6.87842C4.24997 7.68762 6.6628 8.2205 9.33312 8.2205C10.087 8.2205 10.8201 8.1724 11.5206 8.09222C13.2576 6.51575 15.5726 5.5018 18.1353 5.37842C18.3879 5.00219 18.6118 4.59881 18.6561 4.21396C18.1509 0.779789 11.8514 -0.0109388 9.33312 0.000113673L9.33339 0.00028744ZM18.6667 6.316C13.5117 6.316 9.33339 10.2744 9.33339 15.158C9.33339 20.0416 13.5117 24 18.6667 24C23.8217 24 28 20.0416 28 15.158C28 10.2744 23.8217 6.316 18.6667 6.316ZM0.0104166 6.72059V8.01335C0.11328 8.94343 0.98048 9.89944 2.56248 10.6581C4.1549 11.4216 6.3958 11.9348 8.88546 11.9903C9.21488 11.0763 9.68752 10.2238 10.2709 9.45415C9.96226 9.46896 9.64975 9.47389 9.33336 9.47389C6.4805 9.47389 3.88937 8.92989 1.95838 8.00353C1.20448 7.64211 0.543015 7.20791 0.01045 6.72065L0.0104166 6.72059ZM0.0104166 10.51V11.8028C0.11328 12.7329 0.98048 13.6889 2.56248 14.4475C4.04164 15.1568 6.0768 15.6576 8.35412 15.7698C8.3411 15.5663 8.33329 15.364 8.33329 15.158C8.33329 14.503 8.401 13.8628 8.53121 13.2436C5.99865 13.1498 3.70556 12.6206 1.95823 11.783C1.20433 11.4216 0.542865 10.9973 0.0103 10.51L0.0104166 10.51ZM0.0104166 14.2994V15.5922C0.11328 16.5223 0.98048 17.4783 2.56248 18.2369C4.9049 19.3237 7.34113 19.5642 9.44779 19.579C9.02332 18.7858 8.70429 17.9322 8.52072 17.033C5.9921 16.938 3.70307 16.4088 1.95808 15.5725C1.20418 15.2111 0.542715 14.7867 0.0101499 14.2995L0.0104166 14.2994ZM0 18.0889V19.579C0 22.0202 4.17965 24 9.3333 24C10.7851 24 12.151 23.8359 13.375 23.5559C12.1367 22.854 11.0612 21.924 10.2291 20.8126C9.93356 20.8261 9.6367 20.8422 9.3333 20.8422C6.48045 20.8422 3.88932 20.2883 1.95833 19.3619C1.20183 18.9993 0.533865 18.5774 0 18.0889V18.0889Z"
                                        fill="#A39FAA" />
                                </svg>

                            </figure>
                            <span class="text-center text-xs">Finance</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <figure>
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12.4868 0.520996C11.9218 1.65226 11.6393 2.70224 11.0218 4.91844C10.4768 4.80718 10.2693 4.62718 9.62926 3.39719C9.62926 3.39719 9.70301 4.6497 10.8443 5.50845C8.95803 11.6147 4.31915 8.30346 8.15176 11.7184C8.07426 13.6559 6.32802 14.1696 8.17301 14.7071C5.77675 15.9509 7.97926 16.3034 7.95301 16.9321C7.92426 17.6671 6.83301 18.1296 6.88552 19.7408C6.93803 21.3521 13.0542 21.2433 15.1105 21.9758C19.3268 23.4758 20.3505 27.4632 19.513 31.4821C13.2154 32.9646 6.42184 30.5508 2.658 24.917C-3.6044 15.5406 1.81426 3.0456 12.4855 0.520804L12.4868 0.520996ZM16.0256 0.0897318C21.2106 0.0359814 26.2919 2.48973 29.3607 7.08237C33.408 13.1425 32.672 21.0136 28.0557 26.3224C33.9706 17.3249 17.8231 12.2149 16.0256 0.0910118L16.0256 0.0897318ZM13.3106 0.348481C14.1469 0.195981 14.9906 0.11098 15.8306 0.092231C15.9369 2.98222 16.9469 8.89735 22.6857 15.081C28.0668 20.8785 28.262 28.8609 20.5044 31.2161C26.7233 22.3972 23.7332 18.7124 17.3044 12.0286C14.3357 9.73857 12.4171 4.09223 13.3108 0.348551L13.3106 0.348481Z"
                                        fill="#A39FAA" />
                                </svg>

                            </figure>
                            <span class="text-center text-xs">Beauty</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <figure>
                                <svg width="29" height="29" viewBox="0 0 29 29" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.502 0H17.5034V7.0014H10.502V0ZM10.502 21.0029H17.5034V28.0043H10.502V21.0029ZM28.0034 21.0029H21.002V28.0043H28.0034V21.0029ZM0 21.0029H7.0014V28.0043H0V21.0029ZM14.8777 8.75098H13.127V13.127H2.625V19.2521H4.37413V14.876H13.127V19.2516H14.8777V14.876H23.6276V19.2521H25.3767L25.3766 13.127H14.8777V8.75098Z"
                                        fill="#A39FAA" />
                                </svg>

                            </figure>
                            <span class="text-center text-xs">Technology</span>
                        </a>
                    </li>

                </ul>
            </div>
            <div class="col-md-12">
                <h6 class="text-gray-2 weight-700 mt-3 pt-4 border-top">Didn’t find your industry?</h6>
                <p class="text-gray-3 text-xs pt-1 pe-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div class="row">
                    <div class="col-md-8">
                        <div class="formItm mb-4">

                            <input id="inputadd" type="text" value="" placeholder="Add your industry name" required>
                            <label for="inputadd" class="text-sm ">Add your industry name</label>
                        </div>
                    </div>
                    <div class="col-md-4 add-new">
                        <button class="col-md-3 weight-normal btn btn-secondary btn-round-6 small-pad text-xs px-3">Add
                            New</button>
                    </div>
                </div>
            </div>
        </div>

        <ul class="sideNav-btn px-3">

            <button class="weight-normal btn btn-primary btn-round-6 small-pad px-5 py-3">Save</button>
        </ul>

    </nav>
    <div class="overlay"></div>
    <script
        src="https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.concat.min.js"></script>

    <script type="text/javascript">
        $(document).ready(function () {
            $("#sidebar").mCustomScrollbar({
                theme: "minimal"
            });

            $('#dismiss, .overlay').on('click', function () {
                $('#sidebar').removeClass('active');
                $('.overlay').removeClass('active');
            });

            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').addClass('active');
                $('.overlay').addClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });
        });
    </script>