import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-sorry',
  templateUrl: './sorry.component.html',
  styleUrls: ['./sorry.component.scss']
})
export class SorryComponent implements OnInit {
  sErrorMessage: String;
  bDisplayErrorMsg: boolean = false;
  sUsername: string;
  sEmail: string;
  sSession_id: string;

  constructor(private oSessionService : cSessionService , private oWebSocketService : cWebSocketService ,public onboaringManager:OnboardingManagerService, private oSeesionService : cSessionService , private oRouter : Router) { }

  ngOnInit(): void 
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sUsername = this.oSeesionService.cSessionService_GetUsername();
  }
  SorryComponent_GetEmailUpdates()
  {



    this.oWebSocketService.WebSocket_RegisterEvent('keep_me_posted_result', this.SorryComponent_HandleGetEmailUpdatesResponse); //saving event request response 
    this.sEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sSession_id = this.oSessionService.SessionService_GetSessionId();
    let oKeepMePosted = 
    {
      email : this.sEmail,
      session_id: this.sSession_id
    }
    console.log("SorryComponent_GetEmailUpdates: Request Object  ==>", oKeepMePosted); 
    this.oWebSocketService.WebSocket_SendMessage('keep_me_posted_request', JSON.stringify(oKeepMePosted)); //sending request event
  }
  SorryComponent_HandleGetEmailUpdatesResponse  = (InComingMessage) =>
  {
    if (InComingMessage['errorMessage']) 
    {
      console.error('SorryComponent_HandleGetEmailUpdatesResponse: Email Updates Error ==> ', InComingMessage);
      this.sErrorMessage =JSON.parse(InComingMessage['errorMessage']);
      this.bDisplayErrorMsg = true;
      setTimeout(()=>{this.bDisplayErrorMsg = false},5000)
    }
    else 
    {
      console.log('SorryComponent_HandleGetEmailUpdatesResponse : Email Update Response ==> ', InComingMessage);
      this.oRouter.navigateByUrl("/dashboard/myvideos");
    }
  }

}
