import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-video-other-setting',
  templateUrl: './edit-video-other-setting.component.html',
  styleUrls: ['./edit-video-other-setting.component.scss']
})
export class EditVideoOtherSettingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `$(document).ready(function(){
      $('.address').change(function(){
         if(this.checked)
             $('.address-div').fadeIn('slow');
         else
             $('.address-div').fadeOut('slow');
         });
    
         $('.website').change(function(){
     if(this.checked)
         $('.website-div').fadeIn('slow');
     else
         $('.website-div').fadeOut('slow');
    
         });
    
         $('.phone').change(function(){
     if(this.checked)
         $('.phone-div').fadeIn('slow');
     else
         $('.phone-div').fadeOut('slow');
    
         });
    
     });`;
    document.body.appendChild(chatScript);
  }

}




