import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  zoomInRightOnEnterAnimation,
  zoomOutLeftOnLeaveAnimation,
  flashAnimation,
  
  zoomInLeftOnEnterAnimation,
  zoomOutRightOnLeaveAnimation,
} from 'angular-animations';
import { ConfigManagerService } from '../../Managers/config-manager.service';
import { OnboardingManagerService } from '../../Managers/onboarding-manager.service';
import { cSessionService } from '../../services/session.service';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
  animations: [
  zoomInRightOnEnterAnimation(),
  flashAnimation({ anchor: 'flash', duration: 5000 }),
    zoomOutLeftOnLeaveAnimation(),
    zoomInLeftOnEnterAnimation(),
    zoomOutRightOnLeaveAnimation(),
  ]
})
export class SplashscreenComponent implements OnInit, OnDestroy {

  constructor(public meta_info:ConfigManagerService,  public oSessionService:cSessionService,public onboardinManger:OnboardingManagerService,public oRouter:Router) { }

  animationState = false;
  animState =false;
  sessionId:any;
  email:any;
  id;

  animate() {
    // this.animationState = false;
    // setTimeout(() => {
    //   this.animationState = true;
    // }, 1);
    this.id = setInterval(_=>{this.animationState= !this.animationState},1000);
  }
 async ngOnInit() {
    this.animate(); 
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();  



/*
await this.meta_info.getMetaInfo().then(_=>{


  if(this.sessionId && this.email)
  {
      this.meta_info.getVoices(this.email,this.sessionId);
  
      this.onboardinManger.getUserProfile(this.email,this.sessionId,false).then(_=>{

       if(this.onboardinManger.loggedUser.getVideoCount() >0)
       {
        this.oRouter.navigateByUrl('/dashboard/myvideos');
  
      }else{
        this.oRouter.navigateByUrl('/dashboard/first-time-user');

      }}
      ).catch(err=>{

        this.oRouter.navigate(['login']);
      });
  }else
  {
this.oRouter.navigate(['login']);
  }

}).catch(err=>{});
 
*/


  }
  
  ngOnDestroy(){
    if (this.id) {
      clearInterval(this.id);
    }
 }
}
