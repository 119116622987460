import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid,
  ApexPlotOptions
} from "ng-apexcharts";
import { cSessionService } from './../../../services/session.service';

export type chartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
  plotOptions: ApexPlotOptions;
};


@Component({
  selector: 'app-reports-engagement',
  templateUrl: './reports-engagement.component.html',
  styleUrls: ['./reports-engagement.component.scss']
})
export class ReportsEngagementComponent implements OnInit {
 
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<chartOptions>;
  sUserName : string = "";
  constructor(private oSessionService : cSessionService) {
    this.chartOptions = {
      series: [
        {
          name: 'CTA Interactions',
          data: [30, 80, 50]
        },
       
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 200,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal:true,
          columnWidth: '40%',
          barHeight: '40%',
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '0',
      },
      colors: ['#483d8f','#483d8f','#483d8f'],
      legend: {
        show: false,
      },
      grid: {
        show: false,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Website',
          'Email',
          'Phone',
          
        ],
        labels: {
          style: {
            colors: '#000'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#000000'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      }
    };
  }
  ngOnInit(): void {
    this.sUserName = this.oSessionService.cSessionService_GetUsername();
  }

}
