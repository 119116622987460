    <section class="loginSec">
        <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
        <div class="loginSecItm loginSecRight">
        
            <div class="loginSecItmContent-plane">
                <div class="d-lg-none pb-5">
                    <a routerLink="/login">
                        <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120"/>
                    </a>
                </div>
                <a routerLink="/authentication">
                    <img src="../assets/images/onBoarding/back-arrow.svg" alt="back-arrow"/>
                </a>

                <figure class="timer"><img src="../assets/images/onBoarding/timer.svg" alt="back-arrow"/></figure>

                <div class="row">

                    <div class="col-md-10">
                <h2 class="text-gray-1 mt-5">Set up 2-Factor Authentication</h2>
                <p class="text-sm mt-3 ms-0 pb-0 mb-0">Set up 2-factor authentication to secure your account against unauthorized sign ins. Please download and install Google Authenticator on your smartphone, and scan the QR code displayed here.  </p>
            </div>

        </div>
                <div class="loginSecItmForm pt-4 mt-2" aria-labelledby="loginYourself">
                    <form>
                        <div class="verification-sec mb-5 ">
                            <figure class="mb-0">
                                <img src="../assets/images/onBoarding/bar_code.svg" alt="logo-mobile" width="120"/>
                            </figure>
                            <p class="text-lg text-gray-1 mt-4 pt-4 d-block">Manual Setup Code</p>
                            <h4 class="text-gray-1 text-xxl">GFQTAMLDHA3DMLLC</h4>
                         </div>

                        <div class="row">
                            <div class="col-md-5">
                                <div class="formItm">
                                    <input id="VerificationCode" type="text" value=""
                                        placeholder="Enter Your Verification Code" name="textfeild" required>
                                    <label for="VerificationCode" class="text-sm">Enter Your Verification Code</label>
                                </div>
                            </div>
                        </div>

                       
                      
                        <div class="row mt-4">
                            <div class="col-md-5">
                                <div class="row g-0">
                                    <div class="col">
                                        <div class="mb-3">
                                            <button class="btn btn-secondary btn-round-6 w-184">Submit</button>
                                            <a class="text-gray-6 text-sm ms-5" routerLink="/authentication-code">Skip</a>
                                        </div>
                                    </div>
                              
                                </div>
                            </div>
                        </div>

            
                  
                    </form>
                </div>
            </div>
      
        </div>
    </section>