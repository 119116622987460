<ul class="breadcrumb-tabs nav d-block">
   <span *ngIf="!bDisplayTimelineBreadcrumb">
    <li class="nav-item" role="presentation">
        <a routerLink="/edit-video/overview" [class.active]="showMenu==='/edit-video/overview'"class="nav-link active">Overview</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/edit-video/look-feel" [class.active]="showMenu==='/edit-video/look-feel'" class="nav-link">Look & Feel</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/edit-video/content" [class.active]="showMenu==='/edit-video/content'" class="nav-link ">Content</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/edit-video/other-setting" [class.active]="showMenu==='/edit-video/other-setting'" class="nav-link ">Other Settings</a>
    </li>
    <li class="nav-item" role="presentation">
        <a routerLink="/edit-video/preview-locale" [class.active]="showMenu==='/edit-video/preview-locale'" class="nav-link text-gray-10">Preview Locale Data</a>
    </li>
   </span>
   <span *ngIf="bDisplayTimelineBreadcrumb">
    <li class="nav-item" role="presentation" (click)="EditVideoBreadcrumbComponent_NavigateToEditVideo()">
        <a [class.active]="showMenu==='/edit-video/timeline-view'" class="nav-link active">Edit Video</a>
    </li>
    
    <!-- <li class="nav-item" role="presentation">
        <a href="javascript:void(0)" class="nav-link">Video Options</a>
    </li> -->
    <li class="nav-item" role="presentation">
        <a routerLink="/wizard/preview" [class.active]="showMenu==='/wizard/preview'"class="nav-link">Preview</a>
    </li>
   </span>

    <div *ngIf="!bDisplayTimelineBreadcrumb" class="form-check form-switch custom-switch">
        <label class="form-check-label text-gray-6 check-lab1 active" for="flexSwitchCheckChecked">Timeline</label>
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked (change)="ToggleEditVideoView($event)">
        <label class="form-check-label check-lab1" for="flexSwitchCheckChecked">Wizard</label>
      </div>
    
</ul>