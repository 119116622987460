<app-header></app-header>
<section class="dashboard main-body bg-gray-7 h-100">
    <div class="container">
        <div class=" br12 bg-white page-shadow curve-bg pb-0">
            <div class="py-4 px-4 pb-0 account-heading">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="weight-700 text-gray-2"> Connect with your <span class="text-primary-3">customers</span></h3>
                        <p class="mx-0 my-0 text-sm text-gray-3"> Analyze your views and impressions.</p>
                    </div>

                </div>
            </div>
            <div class="h-80vh pb-0 d-flex flex-column reports-sec">

                <div class="video-header row m-0">
                    <app-reports-breadcrumb class="col-lg-3 nav"></app-reports-breadcrumb>
                    <ul class="text-end d-block col-lg-9">
                        <li id="selectpreff">
                            <div class="formItm down_arrow">
                                <div class="dropdown dropdownBtn">
                                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <span class="ms-2 dropdownBtnText">Last 30 days</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                        <li class="active"><a class="dropdown-item text-md" href="#;">Last 30 days</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                    <!-- if no reports are there -->
                    <div class="d-none row m-0">
                        <app-empty-reports></app-empty-reports>
                       </div>
                <div class=" row m-0 flex-grow-1">
                    <app-reports-sidebar-videos class="col-lg-3"></app-reports-sidebar-videos>
                    <div class="col-lg-9 p-4 reportsAudienceScroll">
                        <div class="audienceGraph bg-gray-9 p-3">
                            <div class="video-header row m-0">
                                <ul class="nav breadcrumb-tabs col-lg-6 p-0" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                            aria-selected="true">Returning Viewers</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                            aria-selected="false">New
                                            Viewers</button>
                                    </li>
                                </ul>
                                <ul class="text-end d-block col-lg-6 p-0">
                                    <li id="selectpreff">
                                        <div class="formItm down_arrow m-0">
                                            <div class="dropdown dropdownBtn">
                                                <button class="dropdown-toggle text-md" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="ms-2 dropdownBtnText">Line Graph</span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                                    <li class="active">
                                                        <a class="dropdown-item text-md" href="#;">Line
                                                            Graph</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="lineGraph">
                                        <div style="height:300px;" class="d-flex flex-column">
                                            <apx-chart [series]="socialMediaChartOptions.series"
                                                [chart]="socialMediaChartOptions.chart"
                                                [xaxis]="socialMediaChartOptions.xaxis"
                                                [yaxis]="socialMediaChartOptions.yaxis"
                                                [grid]="socialMediaChartOptions.grid"
                                                [stroke]="socialMediaChartOptions.stroke"
                                                [tooltip]="socialMediaChartOptions.tooltip"
                                                [dataLabels]="socialMediaChartOptions.dataLabels"
                                                [legend]="socialMediaChartOptions.legend"
                                                [colors]="socialMediaChartOptions.colors"
                                                [markers]="socialMediaChartOptions.markers">
                                            </apx-chart>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="lineGraph">
                                        <div style="height:300px;" class="d-flex flex-column">
                                            <apx-chart [series]="socialMediaChartOptions.series"
                                                [chart]="socialMediaChartOptions.chart"
                                                [xaxis]="socialMediaChartOptions.xaxis"
                                                [yaxis]="socialMediaChartOptions.yaxis"
                                                [grid]="socialMediaChartOptions.grid"
                                                [stroke]="socialMediaChartOptions.stroke"
                                                [tooltip]="socialMediaChartOptions.tooltip"
                                                [dataLabels]="socialMediaChartOptions.dataLabels"
                                                [legend]="socialMediaChartOptions.legend"
                                                [colors]="socialMediaChartOptions.colors"
                                                [markers]="socialMediaChartOptions.markers">
                                            </apx-chart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                           
                            <div class="col-lg-6 dashboard-reach">
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-4">Gender</h6>
                                        <!-- <apx-chart [grid]="genderChartOptions.grid" [series]="genderChartOptions.series"
                                            [chart]="genderChartOptions.chart"
                                            [dataLabels]="genderChartOptions.dataLabels"
                                            [plotOptions]="genderChartOptions.plotOptions"
                                            [xaxis]="genderChartOptions.xaxis" [colors]="genderChartOptions.colors"
                                            [markers]="genderChartOptions.markers" [legend]="genderChartOptions.legend"
                                            [tooltip]="genderChartOptions.tooltip" [xaxis]="genderChartOptions.xaxis"
                                            [yaxis]="genderChartOptions.yaxis"></apx-chart> -->
                                            <div class="progress-bars">
                                                <ul>
                                                    <li>
                                                        <span>Female</span>
                                                        <p><span style="width: 30%;"></span>30%</p>
                                                    </li>
                                                    <li>
                                                        <span>Male</span>
                                                        <p><span style="width: 80%;"></span>80%</p>
                                                    </li>
                                                </ul>
                                            </div>
                                    </article>
                                </div>
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-1">Age</h6>
                                        <!-- <p class="text-gray-3 text-xs h-end">How Viewers came to
                                            find your Content. Website and apps that embed or link
                                            to videos from your channel.</p> -->
                                        <div class="lineGraph">
                                            <apx-chart [series]="ageChartOptions.series" [chart]="ageChartOptions.chart"
                                                [dataLabels]="ageChartOptions.dataLabels"
                                                [legend]="ageChartOptions.legends" [colors]="ageChartOptions.colors"
                                                [stroke]="ageChartOptions.stroke" [labels]="ageChartOptions.labels"
                                                [tooltip]="ageChartOptions.tooltip"
                                                [plotOptions]="ageChartOptions.plotOptions"></apx-chart>

                                            <table class="table vm font-14 m-b-0 text-center">
                                                <tr class="border-bottom">
                                                    <td class="border-0">13-17</td>
                                                    <td class="text-right font-medium border-0">10%</td>
                                                </tr>
                                                <tr class="border-bottom">
                                                    <td class="border-0">18-24</td>
                                                    <td class="text-right font-medium border-0">45%</td>
                                                </tr>
                                                <tr class="border-bottom">
                                                    <td class="border-0">25-34</td>
                                                    <td class="text-right font-medium border-0">20%</td>
                                                </tr>
                                                <tr class="border-bottom">
                                                    <td>35-44</td>
                                                    <td class="text-right font-medium">25%</td>
                                                </tr>
                                                <tr class="border-bottom">
                                                    <td>45-54</td>
                                                    <td class="text-right font-medium">10%</td>
                                                </tr>
                                                <tr class="border-bottom">
                                                    <td>55-64</td>
                                                    <td class="text-right font-medium">45%</td>
                                                </tr>
                                                <tr>
                                                    <td>65+</td>
                                                    <td class="text-right font-medium">20%</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div class="col-lg-6 dashboard-reach">
                                <div class="side-content">
                                    <article class="bg-gray-9 p-3">
                                        <h6 class="text-gray-2 pb-1">Location</h6>
                                        <!-- <p class="text-gray-3 text-xs h-end">Your Channel’s audience
                                            by geography. Data is based on IP Address </p> -->
                                        <!-- <apx-chart [grid]="locationsChartOptions.grid"
                                            [series]="locationsChartOptions.series"
                                            [chart]="locationsChartOptions.chart"
                                            [dataLabels]="locationsChartOptions.dataLabels"
                                            [plotOptions]="locationsChartOptions.plotOptions"
                                            [xaxis]="locationsChartOptions.xaxis"
                                            [colors]="locationsChartOptions.colors"
                                            [markers]="locationsChartOptions.markers"
                                            [legend]="locationsChartOptions.legend"
                                            [tooltip]="locationsChartOptions.tooltip"
                                            [xaxis]="locationsChartOptions.xaxis" [yaxis]="locationsChartOptions.yaxis">
                                        </apx-chart> -->
                                        <div class="progress-bars">
                                            <ul>
                                                <li>
                                                    <span>India</span>
                                                    <p><span style="width: 30%;"></span>30%</p>
                                                </li>
                                                <li>
                                                    <span>USA</span>
                                                    <p><span style="width: 80%;"></span>80%</p>
                                                </li>
                                                <li>
                                                    <span>Australia</span>
                                                    <p><span style="width: 20%;"></span>20%</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </article>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>