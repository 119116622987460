import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-media',
  templateUrl: './user-media.component.html',
  styleUrls: ['./user-media.component.scss']
})
export class UserMediaComponent implements OnInit {
  bDisplayUpgradedMediaBtn : boolean = true ;
  sSessionId : string;
  sUserEmail : string;
  lMediaLibraries : any = [];
  fAddLibraryForm: FormGroup;

  constructor(private oRouter : Router, private oFormBuilder: FormBuilder, private oWebSocketService: cWebSocketService, private oSessionService: cSessionService,) { }

  ngOnInit(): void {
    // this.InitializeJqueryCode();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oWebSocketService.WebSocket_RegisterEvent('user_media_libs_result', this.UserMediaComponent_HandleMediaLibraryListResponse);
    this.oWebSocketService.WebSocket_SendMessage('user_media_libs_request', JSON.stringify({email : this.sUserEmail,session_id : this.sSessionId}));
    if(environment.business_plan === 'business' || environment.business_plan === 'business_plus')
    {
      this.bDisplayUpgradedMediaBtn = false
    }
    this.fAddLibraryForm = this.oFormBuilder.group
    ({
      // email: this.sUserEmail,
      // session_id: this.sSessionId,
      lib_name: ['', Validators.required],
      // user_roles : [['creator'], Validators.required],
      description: ['', Validators.required]

    });
  }
  
  addMediaLib(){
    console.log("add media library start" );
    if(this.fAddLibraryForm.valid){
      const lib_name = this.fAddLibraryForm.value.lib_name;
      const description = this.fAddLibraryForm.value.description;
      // const keyterms =[];
      // const category = '';
      
    console.log("add media library", lib_name, description, this.sUserEmail, this.sSessionId );
      // this.mediaManger.addMediaLib(this.sUserEmail, this.sSessionId,lib_name, keyterms, description, category);
      this.closeMedia();
    }
  }


  UserMediaComponent_HandleMediaLibraryListResponse = (IncomingMessage) =>
  {
    console.log("UserMediaComponent_HandleMediaLibraryListResponse: IncomingMessage",JSON.parse(IncomingMessage));
    
    if(JSON.parse(IncomingMessage).errorMessage)
    {
      console.error("UserMediaComponent_HandleMediaLibraryListResponse : Error ==> ", JSON.parse(IncomingMessage).errorMessage);
    }
    else
    {
      let oFormatedIncommingMessage =  JSON.parse(IncomingMessage);
      this.lMediaLibraries = oFormatedIncommingMessage.media_libs;
    }  
  }
  UserMediaComponent_NavigateToMediaSection(sIncommingMedia : string)
  {
    this.oSessionService.cSessionService_SetMediaLibrary(sIncommingMedia);
    this.oRouter.navigate(['/business-profile/media-library']);
  }
  UserMediaComponent_nIncomingMediaLibraries(event)
  {
    console.log("Incoming Media lIbraries =====>>>>>",event)
    this.lMediaLibraries = [];
    this.lMediaLibraries = event[0];
  }
  NavigateToLibrary()
  {
    this.oRouter.navigateByUrl('/business-profile/media-library')
  }
 /*  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    $(document ).ready(function() {
      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay, .closeRightSideBar , .closeSidepanel").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
      
      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
    
      // data-custom-select
      $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
      // end data-custom-select
    
    // dropdownBtn
      $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
        var dropVal = $(this).text();
        $(this).parent("li").siblings().children().removeClass("active");
        $(this).addClass("active");
        $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
    });
    // end dropdownBtn
    });
    // end data-custom-select
    $("#checkAll").click(function () {
      $(".check").prop('checked', $(this).prop('checked'));
  });
    `;
    document.body.appendChild(chatScript);
  } */
 
  
  visibleMedia = false;
  placementMedia: NzDrawerPlacement = 'right';
  openMedia(): void {
    this.visibleMedia = true;
  }
  closeMedia(): void {
    this.visibleMedia = false;
  }


}
