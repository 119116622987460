import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid,
  ApexPlotOptions,
  ApexNonAxisChartSeries,
  ApexStroke
} from 'ng-apexcharts';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cWebSocketService } from './../../services/websocket.service';
export type salesoverviewChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
};


export type socialMediaChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
};
export interface ageChartOptions {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	legends: ApexLegend;
	labels: any;
	name: any;
	tooltip: ApexTooltip;
	colors: string[];
	plotOptions: ApexPlotOptions
}
export type genderChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
  plotOptions: ApexPlotOptions;
};
export type locationsChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-dashboard-sidepanels',
  templateUrl: './dashboard-sidepanels.component.html',
  styleUrls: ['./dashboard-sidepanels.component.scss']
})
export class DashboardSidepanelsComponent implements OnInit , OnDestroy{
  @ViewChild("chart") chart2: ChartComponent = Object.create(null);

  public socialMediaChartOptions: Partial<socialMediaChartOptions>;
	public ageChartOptions: Partial<ageChartOptions>;
  public genderChartOptions: Partial<genderChartOptions>;
  public locationsChartOptions: Partial<locationsChartOptions>;
  // sUserName : string = "";

  public salesoverviewChartOptions: Partial<salesoverviewChartOptions>;
  sAddContentSlide: string;
  sRightSidebar: string;
  sUserName : string;
  sUserEmail: string;
  sSessionId: string;
  lStatsTimeFrame : any = [{name : "Last 7 days", value : 7},{name : "Last 30 days", value : 30},{name : "Last 90 days", value : 90}];
  sSeletedTimeFrame : any = {name : "Last 30 days", value : 30};
  // bSendTotalStatsRequest : boolean = true;
  oTotalStats : any ;
  lTopVideos : any = [];
  lTotalViewed : any = [];
  lTotalCompleted : any = [];
  lTotalPaused : any = [];
  lDates : any = [];
  sVideoID : string;
  bDisplayEmptyStatsSection : boolean = false;
  constructor(private oDataService : cDataService ,private oSessionService : cSessionService, private oWebSocketService: cWebSocketService, ) {
    // audiance
    this.socialMediaChartOptions = {
      series: [
        {
          name: 'Views',
          data: [30, 60, 20, 280, 140, 115, 350, 370, 250, 105, 200,]
        },
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '3',
      },
      colors: ['#ff6a46'],
      legend: {
        show: false,
      },
      
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      yaxis: {
        
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },
      
    };
    this.ageChartOptions = {
			series: [10, 45, 20, 25, 10 , 45 , 20 ],
			chart: {
				fontFamily: 'Montserrat,sans-serif',
				type: 'donut',
				height: 165
			},
			plotOptions: {
				pie: {
					donut: {
						size: '60px',
					}
				}
			},
			tooltip: {
				fillSeriesColor: false,
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 0,
			},
			legends: {
				show: false,
			},
			labels: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-65', '65+'],
			colors: ['#f6502c', '#f53c14', '#cd2c09', '#b42608' ,'#9c2107' ,'#831c06', '#6b1705'],
		};
    this.genderChartOptions = {
      series: [
        {
          name: 'Gender',
          data: [30, 80]
        },
       
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 200,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal:true,
          columnWidth: '40%',
          barHeight: '40%',
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '0',
      },
      colors: ['#483d8f','#483d8f'],
      legend: {
        show: false,
      },
      grid: {
        show: false,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Female',
          'Male',
          
        ],
        labels: {
          style: {
            colors: '#000'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#000000'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      }
    };
    this.locationsChartOptions = {
      series: [
        {
          name: 'Viewers',
          data: [30, 80, 50]
        },
       
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 200,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal:true,
          columnWidth: '40%',
          barHeight: '40%',
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '0',
      },
      colors: ['#483d8f','#483d8f','#483d8f'],
      legend: {
        show: false,
      },
      grid: {
        show: false,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Pakistan',
          'USA',
          'India',
          
        ],
        labels: {
          style: {
            colors: '#000'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#000000'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      }
    };
  }
  ngOnInit(): void 
  {
    // this.InitializeJqueryCode(); 
    this.sUserName = this.oSessionService.cSessionService_GetUsername();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
  }
  ReportsReachComponent_UpdateDuration(oIncommingDuration : any)
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSeletedTimeFrame = oIncommingDuration;
    this.ReportsReachComponent_GetUserTotalVideoStats(oIncommingDuration.value);
  }
  ReportsReachComponent_CalculateChartData(lIncommingData : any)
  {
    let lDates : any =[];
    this.lTotalViewed=[];
    this.lTotalPaused=[];
    this.lTotalCompleted=[];
    for (let nChartDataIndex = 0; nChartDataIndex < lIncommingData.length; nChartDataIndex++) 
    {
      const data = lIncommingData[nChartDataIndex];
      if(data.eventName === "video_play")
      {
        this.lTotalViewed.push(data.eventCount);
      }
      else if(data.eventName === "video_pause")
      {
        this.lTotalPaused.push(data.eventCount);
      }  
      else 
      {
        this.lTotalCompleted.push(data.eventCount);
      }
      lDates.push(data.date);
    }
    let lRemoveDuplicates = new Set(lDates);
    this.lDates = [...lRemoveDuplicates];
    this.ReportsReachComponent_SetGraph();
  }
  ReportsReachComponent_SetGraph()
  {
    this.salesoverviewChartOptions = {
      series: [
        {
          name: 'Viewed',
          data: this.lTotalViewed
        },
        {
          name: 'Completed',
          data: this.lTotalCompleted
        },
        {
          name: 'Paused',
          data: this.lTotalPaused
        }
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '3',
      },
      colors: ['#FF0000', '#3b5998','#ca40cb'],
      legend: {
        show: false,
      },
      
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: this.lDates,
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      yaxis: {
        min:0,
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },
      
    };
  }
  ReportsReachComponent_GetUserTotalVideoStats(nIncommingDays : number)
  {
    let oTotalVideoStatsPayload = 
    {
      email :  this.sUserEmail,
      session_id : this.sSessionId,
      last_days : nIncommingDays
    }
 }
  ReportsReachComponent_HandleTotalVideoStatsResponse = (InComingMessage) => 
  {
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);

    console.log(oFormatedIncommingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("ReportsReachComponent_HandleTotalVideoStatsResponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("ReportsReachComponent_HandleTotalVideoStatsResponse : ==>", oFormatedIncommingMessage);
      this.oTotalStats = oFormatedIncommingMessage;
      if(this.oTotalStats.total_events_by_day.length == 0)
      {
        this.bDisplayEmptyStatsSection=true;
      }
      else
      {
        this.bDisplayEmptyStatsSection=false;
      }
      this.ReportsReachComponent_CalculateChartData(this.oTotalStats.total_events_by_day);
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("ga_user_video_stats_result", this.ReportsReachComponent_HandleTotalVideoStatsResponse);
  }

  ngOnDestroy()
  {
    this.oDataService.sAddClassContentSlide.next('')
  }
  DashboardSidepanelsComponent_DismissContentSlideClass()
  {
    this.oDataService.sAddClassContentSlide.next('')
  }

  visibleReach = false;
  placementReach: NzDrawerPlacement = 'right';
  openReach(): void {
   
    this.visibleReach = true;
  }
  closeReach(): void {
    this.visibleReach = false;
  }

  visibleStats = false;
  placementStats: NzDrawerPlacement = 'right';
  openStats(): void {
   
    this.visibleStats = true;
  }
  closeStats(): void {
    this.visibleStats = false;
  }
  
  visibleEngagement = false;
  placementEngagement: NzDrawerPlacement = 'right';
  openEngagement(): void {
   
    this.visibleEngagement = true;
  }
  closeEngagement(): void {
    this.visibleEngagement = false;
  }

  
}
