import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-authentication-code',
  templateUrl: './authentication-code.component.html',
  styleUrls: ['./authentication-code.component.scss']
})
export class AuthenticationCodeComponent implements OnInit {
  [x: string]: any;
  fAuthenticationCodeForm : FormGroup
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  constructor(private oFormBuilder: FormBuilder , private oWebSocketService : cWebSocketService , private oSessionService : cSessionService, private oRouter : Router) { }

  ngOnInit(): void {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.fAuthenticationCodeForm = this.oFormBuilder.group({
      email: this.oSessionService.cSessionService_GetUserEmail(), 
      code: ['']
    });
  }
  AuthenticationCodeComponent_Confirmation(){
    this.oWebSocketService.WebSocket_CreateWebSocket();
    if(this.fAuthenticationCodeForm.valid)
    {
     this.oWebSocketService.WebSocket_RegisterEvent('verify_confirm_code_result', this.AuthenticationCodeComponent_HandleConfirmationResponse);
     console.log("confirmation_submitRequest: Confirmation Request To WebSocket => ", this.fAuthenticationCodeForm.value);
     this.oWebSocketService.WebSocket_SendMessage("verify_confirm_code", JSON.stringify(this.fAuthenticationCodeForm.value));
    }
    else
    {
      this.fAuthenticationCodeForm.markAllAsTouched() 
    }
  }
  
  AuthenticationCodeComponent_HandleConfirmationResponse = (InComingMessage)=>{
      
    if (JSON.parse(InComingMessage)['errorMessage']) 
    {
       console.log("AuthenticationCodeComponent_HandleConfirmationResponse: Error  => ", InComingMessage);
       this.sErrorMessage = "Code not verified";
       this.bDisplayErrorMsg = true;
       setTimeout(()=>{this.bDisplayErrorMsg = false},5000)
    }
    else
    {
      const oResponse = JSON.parse(InComingMessage);
      console.log("AuthenticationCodeComponent_HandleConfirmationResponse : Response ==> ", oResponse);
      if(oResponse.code_verified)
      {
          this.oWebSocketService.WebSocket_RegisterEvent("pronto_signin_result", this.CreateAccountComponent_HandleLoginResponse);
          let oSignIn = 
          {
            email : this.oSessionService.cSessionService_GetUserEmail(),
            password : this.oSessionService.cSessionService_GetUserPassword()
          }
          this.oWebSocketService.WebSocket_SendMessage("pronto_signin_request", JSON.stringify(oSignIn));
      }
      else
        {
          this.sErrorMessage = "Code not verified";
          this.bDisplayErrorMsg = true;
          setTimeout(()=>{this.bDisplayErrorMsg = false },5000)
          this.oWebSocketService.WebSocket_Destroy();
        }
    }
  }

  CreateAccountComponent_HandleLoginResponse = (InComingMessage) => 
  {
    if (JSON.parse(InComingMessage)["errorMessage"]) 
    {
      console.error("CreateAccountComponent__HandleLoginResponse : Error ==> ", InComingMessage);
      this.sErrorMessage = "You have entered an invalid username or password";
       this.bDisplayErrorMsg = true;
       setTimeout(()=>{this.bDisplayErrorMsg = false},5000)

      // this.oRouter.navigateByUrl("/sorry");
    }
    else 
    {
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetSessionId(oResponse.session_id);
      console.log("LoginComponent_HandleLoginResponse : Response ==> ", oResponse);
      localStorage.removeItem('lsPassword');
      this.oRouter.navigateByUrl('/create-account/select-plan');

    }
  }
}
