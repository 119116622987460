import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingManagerService } from './../../Managers/onboarding-manager.service';
import { cSessionService } from './../../services/session.service';
import { cWebSocketService } from './../../services/websocket.service';

@Component({
  selector: 'app-verify-reset-password',
  templateUrl: './verify-reset-password.component.html',
  styleUrls: ['./verify-reset-password.component.scss']
})
export class VerifyResetPasswordComponent implements OnInit {
  sUserEmail: any;
  code: any;
  sUserSessionId: any;

  constructor(private oActivatedRoute: ActivatedRoute , private oWebSocketService: cWebSocketService , 
     private oSessionService: cSessionService, private oRouter : Router,public onboarding:OnboardingManagerService ) { 
    const resetPasswordResult = this.oActivatedRoute.snapshot.queryParams;
    console.log("Result::", resetPasswordResult);
    this.sUserEmail = resetPasswordResult.email;
    this.code = resetPasswordResult.code;

   
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.VerifyResetPasswordComponent_SendResetPasswordRequest()  
  }

  ngOnInit(): void {
      

  }
  VerifyResetPasswordComponent_SendResetPasswordRequest()
  {
     const oPayload = 
     {
       email: this.sUserEmail,
       code: this.code
     }
     console.log("VerifyResetPasswordComponent_SendResetPasswordRequest : Reset password Request To WebSocket => ",oPayload );
      this.oWebSocketService.WebSocket_RegisterEvent("verify_pw_code_result", this.VerifyResetPasswordComponent_HandleResetPasswordResponse);
      this.oWebSocketService.WebSocket_SendMessage("verify_pw_code", JSON.stringify(oPayload));
    
    
  }
  VerifyResetPasswordComponent_HandleResetPasswordResponse = (InComingMessage) =>
  {
    if(JSON.parse(InComingMessage)["errorMessage"])
    {
       console.error("VerifyResetPasswordComponent_HandleResetPasswordResponse : Error ==> ", InComingMessage);
       this.oRouter.navigateByUrl('/login')
    }
    else
    {
      const oResponse = JSON.parse(InComingMessage);
      console.log("VerifyResetPasswordComponent_HandleResetPasswordResponse : Response ==> ", oResponse);
      if(oResponse.code_verified)
      {        
        this.sUserSessionId = oResponse.session_id;
        this.oSessionService.cSessionService_SetUserEmail(this.sUserEmail);
        this.oSessionService.cSessionService_SetSessionId(this.sUserSessionId);
        this.oRouter.navigate(['account-setting/loginSecurity'])
      }
      else
      {
        this.oRouter.navigateByUrl('/login')
      }
    }
  };

}
