<section class="loginSec">
    <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
    <div class="loginSecItm loginSecRight">
        <div class="loginSecItmTop loginSecItmTop-right">
            <div class="text-end text-xs text-gray-3">
                Already a member? <a routerLink="/login"
                    class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 mr-2">Sign In</a>
                   
            </div>
        </div>
        <div class="loginSecItmContent">
            <div class="d-lg-none pb-3">
                <a href="/login">
                    <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
            </div>
            <h1 class="text-gray-1">Reset Password</h1>
            <h6 class="py-3 text-muted">Enter your new password</h6>
            <div class="loginSecItmForm" aria-labelledby="loginYourself">
                <form name="registration" [formGroup]="fLoginForm">


                    <div class="row">
                        <div class="col-md-6">
                            <div class="formItm formItmPasswordView">
                                <button type="button">
                                  <svg *ngIf="!bDisplayPassword" (click)="resetComponent_TogglePasswordVisibility()"
                                        class="showpass" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                            fill="#BABCBE" />
                                    </svg>

                                    <svg *ngIf="bDisplayPassword" (click)="resetComponent_TogglePasswordVisibility()"
                                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                            fill="#BABCBE" />
                                        <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536" stroke="#BABCBE" />
                                    </svg>

                                </button>
                                <input  id="inputPassword" [type]="bDisplayPassword?'text':'password'"  value=""
                                    placeholder="
                                    New Password" formControlName="newPassword"   required (input)="confirmPasswordChnage()" >
                                <label for="inputPassword" class="text-sm">New Password</label>
                                <span
                                    *ngIf="fLoginForm.controls['newPassword'].hasError('required') && fLoginForm.controls['newPassword'].touched"
                                    class="error">Please enter your password.</span>
                                    <!-- <span *ngIf="fLoginForm.controls['newPassword'].invalid && fLoginForm.controls['newPassword'].errors['regex1']">This is error message 1</span> -->

                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <div class="formItm formItmPasswordView">
                                <button type="button">
                                  <svg *ngIf="!bDisplayConfirmPassword" (click)="resetComponent_ToggleConfirmPasswordVisibility()"
                                        class="showpass" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                            fill="#BABCBE" />
                                    </svg>

                                    <svg *ngIf="bDisplayConfirmPassword" (click)="resetComponent_ToggleConfirmPasswordVisibility()"
                                        width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.09878 8.77804C0.967072 8.61999 0.967072 8.39041 1.09878 8.23235C4.69029 3.92255 11.3097 3.92255 14.9012 8.23235C15.0329 8.39041 15.0329 8.61999 14.9012 8.77804C11.3097 13.0879 4.69029 13.0879 1.09878 8.77804ZM0.330563 7.59217C4.32187 2.80261 11.6781 2.80261 15.6694 7.59217C16.1102 8.12107 16.1102 8.88933 15.6694 9.41823C11.6781 14.2078 4.32187 14.2078 0.330562 9.41823C-0.110188 8.88933 -0.110187 8.12107 0.330563 7.59217ZM9.52909 8.5052C9.52909 9.34969 8.8445 10.0343 8 10.0343C7.15551 10.0343 6.47092 9.34969 6.47092 8.5052C6.47092 7.6607 7.15551 6.97611 8 6.97611C8.8445 6.97611 9.52909 7.6607 9.52909 8.5052ZM10.5291 8.5052C10.5291 9.90197 9.39678 11.0343 8 11.0343C6.60323 11.0343 5.47092 9.90197 5.47092 8.5052C5.47092 7.10842 6.60323 5.97611 8 5.97611C9.39678 5.97611 10.5291 7.10842 10.5291 8.5052Z"
                                            fill="#BABCBE" />
                                        <line x1="13.3536" y1="2.35355" x2="2.35355" y2="13.3536" stroke="#BABCBE" />
                                    </svg>
                                </button>
                                <input id="inputPassword" [type]="bDisplayConfirmPassword?'text':'password'"  value=""
                                    placeholder="Confirm Password" formControlName="confirmPassword" required (input)="confirmPasswordChnage()">
                                <label for="inputPassword" class="text-sm">Confirm Password</label>
                               
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-8">
                        <h5><span> 
                            <svg *ngIf="fLoginForm.controls['newPassword'].hasError('length')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48"
                            style=" fill:#000000;"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"></path><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"></path></svg>
                             
                             <svg  *ngIf="!fLoginForm.controls['newPassword'].hasError('length')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                            viewBox="0 0 48 48" style=" fill:#000000;"><path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"></path></svg>
                        </span><small class="text-muted"> Passwords must be at least 8 characters long.</small></h5>

                        <h5><span>
                            <svg *ngIf="fLoginForm.controls['newPassword'].hasError('text')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48"
                            style=" fill:#000000;"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"></path><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"></path></svg>
                    
                             <svg  *ngIf="!fLoginForm.controls['newPassword'].hasError('text')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                            viewBox="0 0 48 48" style=" fill:#000000;"><path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"></path></svg>
                        </span>
                        
                        <small class="text-muted">Passwrods contain at least one each of uppercase and lowercase alphabets, one numeral</small></h5>

                        <h5><span>
                            <svg *ngIf="fLoginForm.controls['newPassword'].hasError('specialcharacter')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48"
                            style=" fill:#000000;"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"></path><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"></path></svg>
                            
                             <svg  *ngIf="!fLoginForm.controls['newPassword'].hasError('specialcharacter')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                            viewBox="0 0 48 48" style=" fill:#000000;"><path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"></path></svg>
                        </span>
                            <small class="text-muted ms-2">Inclusion of at least one special character, e.g. ! @ # ? ]</small></h5>

                         
                            </div>
                        
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6">
                                <div class="row g-0">
                                    <div class="col">
                                        <div class="mb-3">
                                            <button class="btn btn-secondary btn-block btn-round-6" (click)="resetPassword()"  [disabled]="!isPasswordMatched">Reset Password</button>
                                       <!-- <button class="btn btn-secondary btn-block btn-round-6" disabled *ngIf="!isPasswordMatched" >Reset Password</button>-->

                                    </div>
                                </div>                            
                            </div>
                        </div>
                        
                    </div>  
   

                 

                </form>
            </div>
        </div>
    </div>
</section>
<!-- error modal -->
<!-- <div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">{{sErrorMessage}}</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z" fill="#FF8369"></path>
                            <path d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z" fill="#FF8369"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="28" height="29" fill="white"></rect>
                            </clipPath>
                            </defs>
                            </svg>
                            
                            
                    </div>
                    <div class="col-11 p-0">
                       
                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sErrorMessage}}</p>
                    </div>
                </div>
              
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal" routerLink="/login">Go Back</a>
               
            </div>
        </div>
    </div>
</div> -->
<!-- error modal end -->