<section class="loginSec">
    <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
    <div class="loginSecItm loginSecRight">

        <div class="loginSecItmContent-plane">
            <div class="d-lg-none pb-5">
                <a href="./login.html">
                    <img src="../assets/images/logo-mobile.svg" alt="logo-mobile" width="120" />
                </a>
            </div>
            <a routerLink="/create-account/select-plan">
                <img src="../assets/images/onBoarding/back-arrow.svg" alt="back-arrow" />
            </a>
            <div class="row">

                <div class="col-md-10">
                    <div class="mt-5">
                        <div class="dot bg-secondary-26">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <h2 class="text-gray-1 mt-4">Payment Failed</h2>
                    <p class="text-sm mt-3 ms-0 pb-0 mb-0 d-none">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </p>
                </div>
            </div>
            <div class="loginSecItmForm pt-4" aria-labelledby="loginYourself">
                <div class="row mt-4">
                    <div class="col-md-5">
                        <div class="row g-0">
                            <div class="col">
                                <div class="mb-3">
                                    <a (click)="retry()"
                                        class="btn btn-secondary btn-round-6 w-184">Retry Payment</a>
                                    <a class="text-gray-6 text-sm ms-5" href="javascript:void(0)" data-bs-toggle="modal"
                                        data-bs-target="#subscription-popup">Cancel</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="modal fade" id="subscription-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h5>Are you sure you want to cancel your Payment?</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">
                    Your progress will be lost
                </p>
                <a href="#" id="sidebarCollapse" (click)="retry()" class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4"
                    data-sidebar="#selectplan" data-bs-dismiss="modal">No, Retry Payment</a>
                <a class="mt-3 text-gray-3 text-sm" data-bs-dismiss="modal" routerLink="/create-account/select-plan">Cancel Payment</a>
            </div>
        </div>
    </div>
</div>