import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
declare var $ : any;
@Component({
  selector: 'app-socials-channels',
  templateUrl: './socials-channels.component.html',
  styleUrls: ['./socials-channels.component.scss']
})
export class SocialsChannelsComponent implements OnInit {
  connected_social_platforms: any;
  oSocialChannel : string;
  oUserName : any;
  sDisplayDisconnectErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  sSessionId: string;
  sUserEmail: string;

  constructor( 
    private oSessionService: cSessionService,
    private oWebSocketService: cWebSocketService,
    private oDataService: cDataService,
    private oActivatedRoute: ActivatedRoute
    ) 
    {}

  ngOnInit()
  {
    const authResult = this.oActivatedRoute.snapshot.queryParams['auth_result'];
    console.log("SocialChannelsComponent : Auth result ==>",authResult);
    if(authResult != null && (authResult == 'google_auth_successful' || authResult == 'fb_auth_successful' || authResult == 'linkedin_auth_successful'))
    {
      const email = this.oActivatedRoute.snapshot.queryParamMap.get('email');
      const sessionId = this.oActivatedRoute.snapshot.queryParamMap.get('session_id');
      this.oSessionService.cSessionService_SetUserEmail(email)
      this.oSessionService.cSessionService_SetSessionId(sessionId);
    }
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.SocialChannelsComponent_GetProfileData();
    
  }
  SocialChannelsComponent_GetProfileData()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('profile_request_result', this.SocialChannelsComponent_HandleUserProfileResponse);
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    const oGetUserProfile = 
    {
      session_id: this.sSessionId,
      email: this.sUserEmail
    }
    console.log("SocialChannelsComponent_GetProfileData : Profile Request Object ==> ",oGetUserProfile);
    this.oWebSocketService.WebSocket_SendMessage('profile_request', JSON.stringify(oGetUserProfile));
  }

  SocialChannelsComponent_HandleUserProfileResponse = (InComingMessage) => 
  {
    console.log('SocialChannelsComponent_GetProfileData : Profile Response ==> ', InComingMessage);
    if (InComingMessage['errorMessage']) 
    {
      console.error('SocialChannelsComponent_HandleUserProfileResponse : Profile Response Error ==> ', InComingMessage);
      
    }
    else
    {
      let oResponse = InComingMessage;
      if(oResponse.name)
      {
       this.oUserName = oResponse.name
      }
      if (oResponse.connected_social_platforms) {
        this.connected_social_platforms = oResponse.connected_social_platforms;
        
      }
      console.log("connected_social_platforms ==>>>", this.connected_social_platforms);
    }
  }
  SocialChannelsComponent_SocialMediaChannelsLink(oData: string) 
  {
    let sURL: string;
    if(oData == 'google')
    {
      sURL = this.oDataService.googleJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId; 
    }
    else if(oData == 'facebook')
    {
      sURL = this.oDataService.faceBookJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    else if(oData == 'linkedin')
    {
      sURL = this.oDataService.linkedInJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId;
    }
    this.oSessionService.cSessionService_SetRedirectURL(21);
    location.assign(sURL);
  }
 
  SocialChannelsComponent_DisconnectSocialChannelOpenModal(value)
  {
    console.log("Selected Value ===>>>>",value)
    this.oSocialChannel = value;
    // $('#disconnect').modal('show');
    this.SocialChannelsComponent_DisconnectSocialChannel();
  }
  SocialChannelsComponent_DisconnectSocialChannel()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('disconnect_social_platform_result', this.SocialChannelsComponent_DisconnectAccountResponse);
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    const oGetUserProfile =
    {
      session_id: this.sSessionId,
      email: this.sUserEmail,
      platform : this.oSocialChannel,
    }
    console.log("SocialChannelComponent_DisconnectAccount: disconnect_social_platform Object ===>", oGetUserProfile);
    this.oWebSocketService.WebSocket_SendMessage('disconnect_social_platform', JSON.stringify(oGetUserProfile));
  }

  SocialChannelsComponent_UnsubscriptionModal()
  {
    console.log("working");
    // $('#subscription-popup').modal('show')
  }

  SocialChannelsComponent_DisconnectAccountResponse = (InComingMessage) => {
    console.log("SocialChannelsComponent_DisconnectAccountResponse: disconnect Response==> ", InComingMessage);
    if  (InComingMessage['errorMessage']) 
    {
      this.sDisplayDisconnectErrorMessage = InComingMessage;
      console.error('SocialChannelsComponent_DisconnectAccountResponse: Disconnect Response Error ==> ', InComingMessage);
      this.bDisplayErrorMessage = true;
            setTimeout(()=>{
              this.bDisplayErrorMessage = false
            },5000);
    }
    else 
    {
      let oResponse = InComingMessage;
      console.log("SocialChannelsComponent_DisconnectAccountResponse: Disconnect Profile Response==>", oResponse);
      this.SocialChannelsComponent_GetProfileData();
    }
  }

}
