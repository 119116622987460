<app-header></app-header>
<section class="dashboard main-body bg-gray-7 h-100">
    <div class="container">
        <div class="br12 bg-white page-shadow curve-bg h-80vh pb-0 d-flex flex-column">
            <div class="py-4 px-4 pb-0 account-heading">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="weight-700 text-gray-2">
                            Connect with your <span class="text-primary-3">customers</span>
                        </h3>
                        <p class="mx-0 my-0 text-sm text-gray-3">
                            Analyze your views and impressions.
                        </p>
                    </div>
                </div>
            </div>
            <div class="accountSecInner flex-grow-1">
                <div class="accountSecContent reports-sec">
                    <div class="row row-cols-md-2 g-0 justify-content-between">
                        <div class="loginSecItmForm pt-0" aria-labelledby="loginYourself">
                            <div class="video-header row m-0">
                                <app-reports-breadcrumb class="col-lg-3 nav"></app-reports-breadcrumb>
                                <ul class="text-end d-block col-lg-9">
                                    <li id="selectpreff">
                                        <div class="formItm down_arrow">
                                            <div class="dropdown dropdownBtn">
                                                <button class="dropdown-toggle text-md" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="ms-2 dropdownBtnText">{{sSeletedTimeFrame?.name}}</span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                                    <li [ngClass]="sSeletedTimeFrame.name==duration.name?'active':''" *ngFor="let duration of lStatsTimeFrame">
                                                        <a class="dropdown-item text-md" href="javascript:void(0);" (click)="ReportsReachComponent_UpdateDuration(duration)">{{duration.name}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- if no reports are there -->
                            <div class="row m-0" *ngIf="bDisplayEmptyStatsSection">
                                <app-empty-reports></app-empty-reports>
                            </div>
                            <div class="row m-0" *ngIf="!bDisplayEmptyStatsSection">
                                <!-- <app-reports-sidebar-videos class="col-lg-3"></app-reports-sidebar-videos> -->
                                <!-- Top videos section start-->
                                <div class="col-lg-3 p-4 border-right">
                                    <div class="d-flex">
                                        <div class="formItm search-icon">
                                            <input id="loginEmail" type="email" value="" placeholder="Email Address" (keyup)="ReportsReachComponent_SearchOnKeyUp($event.keyCode)" [(ngModel)]="sTitleToSearch" required>
                                            <label for="loginEmail" class="text-sm">Search videos</label>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="ReportsReachComponent_SearchVideo()"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                    fill="#A39FAA" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="sidebar-textblock">
                                        <div class="custom-switch ">
                                            <input class="form-check-input ms-0 location" type="checkbox" (change)="ReportsReachComponent_ToggleStatsCategory($event)"
                                                id="Videos-check" checked="">
                                            <label for="Videos-check" class="text-sm text-gray-3 ps-1">All
                                                Videos</label>
                                        </div>
                                    </div>
                                    <p class="text-gray-2 text-xl">Top Videos</p>
                                    <div class="video-listed">
                                        <div id="container-view">
                                            <ul class="list p-0 m-0">
                                                <li *ngFor="let video of lTopVideos; let i = index" (click)="bSendTotalStatsRequest?'':ReportsReachComponent_SetVideoForStats(video.video_id)"> 
                                                    <div class="video-outer" [class.active]="video.video_id == sVideoID">
                                                            <div id="TemplateThumbnail" class="video_wrapper video_wrapper_full js-videoWrapper">
                                                                <video  id="videoPreview" controls disablepictureinpicture controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" width="620" height="350" [src]="video?.url">
                                                                </video>
                                                                <figure class="video-baner">
                                                                    <img [src]="video?.thumb" alt="video">
                                                                </figure>
                                                                <button class="videoPoster js-videoPoster"></button>
                                                            </div>
                                                        <a href="javascript:void(0)"
                                                            class="text-sm p-3 m-0 text-gray-2 d-block float-none">{{video.title}}</a>
                                                        <span class="video-view">Views <b>{{video.views}}</b></span>
                                                    </div>
                                                    <hr>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- Top video section end -->
                                <div class="col-lg-9 p-4">
                                    <div class="row dashboardReachRow">
                                        <div class="col-lg-4 dashboard-reach">
                                            <div class="side-content">
                                                <article class="bg-gray-9 p-3">
                                                    <h6 class="text-gray-2 pb-1">Viewed</h6>
                                                    <!-- <p class="text-gray-3 text-xs h-end">
                                                        How many times your video was watched
                                                        across all platforms
                                                    </p> -->
                                                    <p class="text-gray-3 text-xs h-end">
                                                        How many times your video was played
                                                    </p>
                                                    <h5 class="align-self-end">
                                                        {{oTotalStats?.total_events.video_play}}
                                                        <!-- <span>
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                                                    fill="#00C599"></path>
                                                            </svg>
                                                        20%</span> -->
                                                    </h5>
                                                </article>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 dashboard-reach">
                                            <div class="side-content">
                                                <article class="bg-gray-9 p-3">
                                                    <h6 class="text-gray-2 pb-1">Completed</h6>
                                                    <!-- <p class="text-gray-3 text-xs h-end">
                                                        How many times your video was shown in a
                                                        users feed across all platforms
                                                    </p> -->
                                                    <p class="text-gray-3 text-xs h-end">
                                                        How many times your video was watched completely
                                                    </p>
                                                    <h5 class="align-self-end">
                                                        {{oTotalStats?.total_events.video_complete}}
                                                        <!-- <span>
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                                                    fill="#00C599"></path>
                                                            </svg>
                                                        13%</span> -->
                                                    </h5>
                                                </article>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 dashboard-reach">
                                            <div class="side-content">
                                                <article class="bg-gray-9 p-3">
                                                    <h6 class="text-gray-2 pb-1">
                                                        Paused
                                                    </h6>
                                                    <!-- <p class="text-gray-3 text-xs h-end">
                                                        How many times someone watched your
                                                        video after seeing an impression
                                                    </p> -->
                                                    <p class="text-gray-3 text-xs h-end">
                                                        How many times someone paused your video while playing
                                                    </p>
                                                    <h5 class="align-self-end">
                                                        {{oTotalStats?.total_events.video_pause}}
                                                        <!-- <span>
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M11.3574 6.01139C11.5044 6.16243 11.7428 6.16243 11.8898 6.01139C12.0368 5.86034 12.0368 5.61545 11.8898 5.46441L6.97594 0.415373C6.43694 -0.138457 5.56306 -0.138458 5.02406 0.415373L0.110249 5.46441C-0.0367498 5.61545 -0.0367498 5.86034 0.110249 6.01139C0.257248 6.16243 0.495582 6.16243 0.642581 6.01139L5.55639 0.962353C5.57868 0.93945 5.60223 0.918631 5.62681 0.899895L5.62681 11.6118C5.62681 11.8262 5.7947 12 6.00181 12C6.20892 12 6.37682 11.8262 6.37682 11.6118L6.37682 0.902684C6.40009 0.92069 6.42242 0.94058 6.44361 0.962353L11.3574 6.01139Z"
                                                                    fill="#00C599"></path>
                                                            </svg>
                                                        4%</span> -->
                                                    </h5>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0 video-header py-4 no-border">
                                        <div class="col-lg-10 ps-0">
                                            <p class="text-gray-2 text-lg m-0">Traffic Source</p>
                                            <p class="text-gray-3 text-xs m-0">
                                                How viewers came to find your content. Websites and apps
                                                that embed or link to videos from your channel.
                                            </p>
                                        </div>
                                        <div class="col-lg-2 pe-0">
                                            <div id="selectpreff" class="d-flex justify-content-end">
                                                <div class="formItm down_arrow m-0 d-flexjustify-content-end">
                                                    <div class="dropdown dropdownBtn">
                                                        <button class="dropdown-toggle text-md" type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span class="ms-2 dropdownBtnText">Line Graph</span>
                                                        </button>
                                                        <ul class="dropdown-menu dropdown-menu-end dropdownMenu">
                                                            <li class="active">
                                                                <a class="dropdown-item text-md" href="javascript:void(0);">Line Graph</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 px-0" *ngIf="oTotalStats">
                                            <div class="graph-div">
                                                <div style="height: 300px" class="d-flex flex-column">
                                                    <apx-chart [series]="socialMediaChartOptions.series"
                                                        [chart]="socialMediaChartOptions.chart"
                                                        [xaxis]="socialMediaChartOptions.xaxis"
                                                        [yaxis]="socialMediaChartOptions.yaxis"
                                                        [grid]="socialMediaChartOptions.grid"
                                                        [stroke]="socialMediaChartOptions.stroke"
                                                        [tooltip]="socialMediaChartOptions.tooltip"
                                                        [dataLabels]="socialMediaChartOptions.dataLabels"
                                                        [legend]="socialMediaChartOptions.legend"
                                                        [colors]="socialMediaChartOptions.colors"
                                                        [markers]="socialMediaChartOptions.markers">
                                                    </apx-chart>
                                                </div>
                                                <ul class="social-t p-0 my-3">
                                                    <li class="youtube-gh">Viewed</li>
                                                    <li class="facebook-gh">Completed</li>
                                                    <li class="instagram-gh">Paused</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>