import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-aside-section',
  templateUrl: './onboarding-aside-section.component.html',
  styleUrls: ['./onboarding-aside-section.component.scss']
})
export class OnboardingAsideSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
