import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss']
})
export class MediaLibraryComponent implements OnInit {
  lFilesToUploadList : any = [];
  nCurrentFileIndex : number = 0;
  sSessionId: string;
  sUserEmail: string;
  oCurrentVisualToUpload : any;
  bSelectMultipleVisuals : boolean = false;
  sSelectedMediaLibrary : string;
  sInputVisualPath : string;
  lInputVisualsToDelete : any = [];
  slides = [
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"},
    {img: "../assets/images/business_Profile/img-sel.png"}
  ];
  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "autoplay" : true,
    "autoplaySpeed" : 1500
  };  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }  

  bDisplayMediaLibraryTab: boolean = false;

  constructor(private oRouter : Router, private oWebSocketService : cWebSocketService, private oDataService : cDataService, private oSessionService : cSessionService) 
  {
    this.sSelectedMediaLibrary = this.oSessionService.cSessionService_GetMediaLibrary();
  }

  ngOnInit(): void {
    if(this.oRouter.url === '/business-profile/brands/media-library')
    {
       this.bDisplayMediaLibraryTab = true
    }
    this.InitializeJqueryCode();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
  }
  MediaLibrarySharedComponent_ToggleMultiSelection()
  {
    this.bSelectMultipleVisuals=true;
  }
  MediaLibraryComponent_UploadFiles(lIncommingVisualsToUpload : any)
  {
    this.oWebSocketService.WebSocket_UnRegisterEvent('signed_url_for_upload_result',this.MediaLibraryComponent_HandleBulkUploadResponse);
    this.oWebSocketService.WebSocket_RegisterEvent('signed_url_for_upload_result', this.MediaLibraryComponent_HandleBulkUploadResponse);
    console.log("MediaLibraryComponent_UploadFiles : Visuals to upload ==> ", lIncommingVisualsToUpload);
    this.lFilesToUploadList = lIncommingVisualsToUpload;
    this.nCurrentFileIndex = 0;
    // this.oDataService.oBulkUploadCount.next({current : this.nCurrentFileIndex , total : this.lFilesToUploadList.length});
    for(let nFileToUploadIndex = 0; nFileToUploadIndex < this.lFilesToUploadList.length; nFileToUploadIndex++)
    {
      let oFileToUpload = 
      {
        email: this.sUserEmail, session_id: this.sSessionId,
        filename: this.lFilesToUploadList[nFileToUploadIndex].name.replace(/ /g,"_"),
        content_type:this.lFilesToUploadList[nFileToUploadIndex].type,
        index: nFileToUploadIndex
      };
      this.oWebSocketService.WebSocket_SendMessage('signed_url_for_upload_request', JSON.stringify(oFileToUpload));
      //Generate Thumbnails for selected files
      var reader = new FileReader();
      reader.readAsDataURL(this.lFilesToUploadList[nFileToUploadIndex]);
      reader.onload =(event) => 
      {
        if(this.lFilesToUploadList[nFileToUploadIndex].type==="audio/mp3" || this.lFilesToUploadList[nFileToUploadIndex].type==="audio/mpeg")
        {
          this.lFilesToUploadList[nFileToUploadIndex].url =  "../assets/images/business_Profile/audio-line.png";
        }
        else
        {
          this.lFilesToUploadList[nFileToUploadIndex].url = (<FileReader>event.target).result;
        }
      }
    }
  }
  MediaLibraryComponent_HandleBulkUploadResponse = async (IncomingMessage) =>
  {
    console.log("MediaLibraryComponent_HandleBulkUploadResponse: IncomingMessage",JSON.parse(IncomingMessage));

    if(JSON.parse(IncomingMessage).errorMessage)
    {
      console.error("MediaLibraryComponent_HandleBulkUploadResponse : Error ==> ", JSON.parse(IncomingMessage).errorMessage);
      this.lFilesToUploadList[this.nCurrentFileIndex].status="failed";
      this.nCurrentFileIndex++; 
    }
    else
    {
      let oCurrentVisualToUpload = JSON.parse(IncomingMessage);
      try 
      {
        await this.oDataService.DataService_UploadFileOnSignedUrl(this.lFilesToUploadList[oCurrentVisualToUpload.index],oCurrentVisualToUpload.signed_url).then(
        (oUploadFileOnSignedUrlResponse) => 
        {
          console.log("MediaLibraryComponent_HandleBulkUploadResponse : Upload File On Signed URL Response ==> ", oUploadFileOnSignedUrlResponse);
        })
        .catch(
        (oUploadFileOnSignedUrlError) =>
        {
          console.error("MediaLibraryComponent_HandleBulkUploadResponse :  Upload File On Signed URL Error ==> ", oUploadFileOnSignedUrlError);
          this.lFilesToUploadList[oCurrentVisualToUpload.index].status="failed";
        });
        const oAddUserMediaRequest: any = new FormData();
        oAddUserMediaRequest.append('session_id', this.sSessionId);
        oAddUserMediaRequest.append('media_lib', this.sSelectedMediaLibrary);
        oAddUserMediaRequest.append('email', this.sUserEmail);
        oAddUserMediaRequest.append('filename', this.lFilesToUploadList[oCurrentVisualToUpload.index].name.replace(/ /g,"_"));
        await this.oDataService.DataService_PostAddUserMedia(oAddUserMediaRequest).then(
        (oAddUserMediaResponse : any) => 
        {
          if(oAddUserMediaResponse.errorMessage)
          {
            console.error("MediaLibraryComponent_HandleBulkUploadResponse : Add User Media Error ==> ", oAddUserMediaResponse.errorMessage);
            this.lFilesToUploadList[oCurrentVisualToUpload.index].status="failed";
            this.nCurrentFileIndex++; 
          }
          else
          {
            console.log("MediaLibraryComponent_HandleBulkUploadResponse : Add User Media Response ==> ", oAddUserMediaResponse);
            // console.log(this.lFilesToUploadList[oCurrentVisualToUpload.index].type.indexOf('image'));
            const nCheckForImage = this.lFilesToUploadList[oCurrentVisualToUpload.index].type.indexOf('image');
            //Skip visual duration part if visual is an image
            if(nCheckForImage == -1)
            {
              this.MediaLibraryComponent_FetchVisualDuration(oCurrentVisualToUpload.visual_id);
            }
            else
            {
              this.sInputVisualPath = oCurrentVisualToUpload.visual_id;
            }
            this.lFilesToUploadList[oCurrentVisualToUpload.index].status="complete";
            this.nCurrentFileIndex++;
          }
        })
        .catch(
        (oAddUserMediaError) =>
        {
          console.error("MediaLibraryComponent_HandleBulkUploadResponse : Add User Media Error ==> ", oAddUserMediaError);
          this.lFilesToUploadList[oCurrentVisualToUpload.index].status="failed";
        });
      }  
      catch (oCatchError) 
      {
        console.error("MediaLibraryComponent_HandleBulkUploadResponse :  ERROR ==> ", oCatchError);
      }
    }  
  };
  MediaLibraryComponent_FetchVisualDuration(sIncommingVisualID : string)
  {
    this.oWebSocketService.WebSocket_UnRegisterEvent('video_duration_result',this.MediaLibraryComponent_HandleFetchVisualDuration);
    this.oWebSocketService.WebSocket_RegisterEvent('video_duration_result', this.MediaLibraryComponent_HandleFetchVisualDuration);
    let oVisualDuration = 
    {
      email: this.sUserEmail, 
      session_id: this.sSessionId,
      visual_id : sIncommingVisualID
    };
    // console.log("MediaLibraryComponent_FetchVisualDuration : Visual ID ==> ", oVisualDuration);
    this.oWebSocketService.WebSocket_SendMessage('video_duration_request', JSON.stringify(oVisualDuration));
  }
  MediaLibraryComponent_HandleFetchVisualDuration = (IncomingMessage) =>
  {
    console.log("MediaLibraryComponent_HandleFetchVisualDuration: IncomingMessage",JSON.parse(IncomingMessage));
    
    if(JSON.parse(IncomingMessage).errorMessage)
    {
      console.error("MediaLibraryComponent_HandleFetchVisualDuration : Error ==> ", JSON.parse(IncomingMessage).errorMessage);
    }
    else
    {
      let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
       //fetch duration first then get details
      this.sInputVisualPath = oFormatedIncommingMessage.visual_id;
    }
  } 

  MediaLibraryComponent_CancelUpload()
  {
    this.lFilesToUploadList=[];
    this.nCurrentFileIndex=0;
  }
  MediaLibraryComponent_DeleteVisual()
  {
    let lVisualToDelete = [];
    lVisualToDelete = this.lFilesToUploadList.filter(visual => visual.status==="complete");
    this.lInputVisualsToDelete=lVisualToDelete;
    // console.log("MediaLibraryComponent_DeleteVisual : Deleted",lVisualToDelete);
    this.lFilesToUploadList=[];
    // console.log("this.lFilesToUploadList",this.lFilesToUploadList);
  }
  MediaLibraryComponent_ReturnFileSize(bytes, decimals = 2)
  {
    if (bytes === 0)
    {
      return "0 b";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))+sizes[i];
  }

  InitializeJqueryCode()
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `
    $(document ).ready(function() {
      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay, .closeRightSideBar").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
      
      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
    
      // data-custom-select
      $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
      // end data-custom-select
    
    // dropdownBtn
      $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
        var dropVal = $(this).text();
        $(this).parent("li").siblings().children().removeClass("active");
        $(this).addClass("active");
        $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
    });
    // end dropdownBtn
    
    });
      $(function($) {
        $.autofilter();
      });
      `;
    document.body.appendChild(chatScript);
  }
}
