import { Component, OnInit, ViewChild } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid
} from 'ng-apexcharts';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { environment } from '../../../../environments/environment';

export type socialMediaChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
};
@Component({
  selector: 'app-reports-reach',
  templateUrl: './reports-reach.component.html',
  styleUrls: ['./reports-reach.component.scss']
})
export class ReportsReachComponent implements OnInit {
  @ViewChild("chart") chart2: ChartComponent = Object.create(null);
  public socialMediaChartOptions: Partial<socialMediaChartOptions>;
  sUserName : string;
  sUserEmail: string;
  sSessionId: string;
  lStatsTimeFrame : any = [{name : "Last 7 days", value : 7},{name : "Last 30 days", value : 30},{name : "Last 90 days", value : 90}];
  sSeletedTimeFrame : any = {name : "Last 30 days", value : 30};
  bSendTotalStatsRequest : boolean = true;
  oTotalStats : any ;
  lTopVideos : any = [];
  lTotalViewed : any = [];
  lTotalCompleted : any = [];
  lTotalPaused : any = [];
  lDates : any = [];
  sVideoID : string;
  lTopVideosViews : any = [];
  sTitleToSearch : string = "";
  bDisplayEmptyStatsSection : boolean = false;
  bEnableFetchingTopVideos : boolean = false;

  constructor(private oSessionService : cSessionService, private oWebSocketService: cWebSocketService, private oDataService  : cDataService) {}

  ngOnInit(): void 
  {
    this.sUserName = this.oSessionService.cSessionService_GetUsername();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.ReportsReachComponent_GetUserTotalVideoStats(30);
    this.ReportsReachComponent_GetUserTopVideos(30);
  }
  ReportsReachComponent_SetVideoForStats(sIncommingVideoID : string)
  {
    this.bSendTotalStatsRequest=false;
    this.sVideoID = sIncommingVideoID;
    this.ReportsReachComponent_GetUserSingleVideoStats(this.sSeletedTimeFrame.value,this.sVideoID);
  }
  ReportsReachComponent_UpdateDuration(oIncommingDuration : any)
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSeletedTimeFrame = oIncommingDuration;
    if(this.bSendTotalStatsRequest)
    {
      this.ReportsReachComponent_GetUserTotalVideoStats(oIncommingDuration.value);
      this.ReportsReachComponent_GetUserTopVideos(oIncommingDuration.value);
    }
    else
    {
      this.ReportsReachComponent_GetUserSingleVideoStats(oIncommingDuration.value,this.sVideoID);
    }
  }
  ReportsReachComponent_ToggleStatsCategory(event : any)
  {
    let bChecked = event.target.checked;
    this.sTitleToSearch="";
    if(bChecked)
    {
      this.bSendTotalStatsRequest = true;
      this.sVideoID="";
      this.oWebSocketService.WebSocket_CreateWebSocket();
      this.ReportsReachComponent_GetUserTotalVideoStats(this.sSeletedTimeFrame.value);
      this.ReportsReachComponent_GetUserTopVideos(this.sSeletedTimeFrame.value);
    }
    else
    {
      this.bSendTotalStatsRequest = false;
      if(this.lTopVideos.length>0)
      {
        this.ReportsReachComponent_SetVideoForStats(this.lTopVideos[0].video_id);
      }
    }
  }
  ReportsReachComponent_CalculateChartData(lIncommingData : any)
  {
    let lDates : any =[];
    this.lTotalViewed=[];
    this.lTotalPaused=[];
    this.lTotalCompleted=[];
    for (let nChartDataIndex = 0; nChartDataIndex < lIncommingData.length; nChartDataIndex++) 
    {
      const data = lIncommingData[nChartDataIndex];
      if(data.eventName === "video_play")
      {
        this.lTotalViewed.push(data.eventCount);
      }
      else if(data.eventName === "video_pause")
      {
        this.lTotalPaused.push(data.eventCount);
      }  
      else 
      {
        this.lTotalCompleted.push(data.eventCount);
      }
      lDates.push(data.date);
    }
    let lRemoveDuplicates = new Set(lDates);
    this.lDates = [...lRemoveDuplicates];
    this.ReportsReachComponent_SetGraph();
  }
  ReportsReachComponent_SetGraph()
  {
    this.socialMediaChartOptions = {
      series: [
        {
          name: 'Viewed',
          data: this.lTotalViewed
        },
        {
          name: 'Completed',
          data: this.lTotalCompleted
        },
        {
          name: 'Paused',
          data: this.lTotalPaused
        }
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '3',
      },
      colors: ['#FF0000', '#3b5998','#ca40cb'],
      legend: {
        show: false,
      },
      
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: this.lDates,
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      yaxis: {
        min:0,
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },
      
    };
  }
  ReportsReachComponent_GetUserTotalVideoStats(nIncommingDays : number)
  {
    let oTotalVideoStatsPayload = 
    {
      email :  this.sUserEmail,
      session_id : this.sSessionId,
      last_days : nIncommingDays
    }
    this.oWebSocketService.WebSocket_RegisterEvent("ga_user_video_stats_result", this.ReportsReachComponent_HandleTotalVideoStatsResponse);
    this.oWebSocketService.WebSocket_SendMessage("ga_user_video_stats_request", JSON.stringify(oTotalVideoStatsPayload));
  }
  ReportsReachComponent_HandleTotalVideoStatsResponse = (InComingMessage) => 
  {
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);


    console.log(oFormatedIncommingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("ReportsReachComponent_HandleTotalVideoStatsResponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("ReportsReachComponent_HandleTotalVideoStatsResponse : ==>", oFormatedIncommingMessage);
      this.oTotalStats = oFormatedIncommingMessage;
      if(this.oTotalStats.total_events_by_day.length == 0)
      {
        this.bDisplayEmptyStatsSection=true;
      }
      else
      {
        this.bDisplayEmptyStatsSection=false;
      }
      this.ReportsReachComponent_CalculateChartData(this.oTotalStats.total_events_by_day);
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("ga_user_video_stats_result", this.ReportsReachComponent_HandleTotalVideoStatsResponse);
  }
  ReportsReachComponent_GetUserTopVideos(nIncommingDays : number)
  {
    let oTotalVideoStatsPayload = 
    {
      email :  this.sUserEmail,
      session_id : this.sSessionId,
      last_days : nIncommingDays
    }
    this.oWebSocketService.WebSocket_RegisterEvent("ga_user_top_ten_videos_result", this.ReportsReachComponent_HandleUserTopVideosResponse);
    this.oWebSocketService.WebSocket_SendMessage("ga_user_top_ten_videos_request", JSON.stringify(oTotalVideoStatsPayload));
  }
  ReportsReachComponent_HandleUserTopVideosResponse = (InComingMessage) => 
  {
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("ReportsReachComponent_HandleUserTopVideosResponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("ReportsReachComponent_HandleUserTopVideosResponse : ==>", oFormatedIncommingMessage);
      this.lTopVideosViews=[];
      this.lTopVideos=[];
      for (let nGetVideoDetailsIndex = 0; nGetVideoDetailsIndex < oFormatedIncommingMessage.data.length; nGetVideoDetailsIndex++) 
      {
        const sVideoURL = oFormatedIncommingMessage.data[nGetVideoDetailsIndex].pageLocation;
        let sVideoID = decodeURIComponent(sVideoURL.substr(sVideoURL.indexOf('video_id=')+9,sVideoURL.length-1));
        // console.log("Video Id : ==>",sVideoID);
        this.lTopVideosViews.push({video_id : sVideoID, views : oFormatedIncommingMessage.data[nGetVideoDetailsIndex].eventCount});
        this.ReportsReachComponent_GetVideoDetails(sVideoID);
      }
      console.log("Top videos views : ==>",this.lTopVideosViews);
      // this.lTopVideos = oFormatedIncommingMessage.data;
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("ga_user_top_ten_videos_result", this.ReportsReachComponent_HandleUserTopVideosResponse);
  }
  ReportsReachComponent_GetUserSingleVideoStats(nIncommingDays : number, sIncommingVideoID : string)
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    let oTotalVideoStatsPayload = 
    {
      email :  this.sUserEmail,
      session_id : this.sSessionId,
      last_days : nIncommingDays,
      video_id : environment.WS_HOST + '/public-videos?video_id=' + encodeURIComponent(sIncommingVideoID).replace('%40','@')
    }
    this.oWebSocketService.WebSocket_RegisterEvent("ga_user_single_video_stats_result", this.ReportsReachComponent_HandleSingleVideoStatsResponse);
    this.oWebSocketService.WebSocket_SendMessage("ga_user_single_video_stats_request", JSON.stringify(oTotalVideoStatsPayload));
  }
  ReportsReachComponent_HandleSingleVideoStatsResponse = (InComingMessage) => 
  {
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("ReportsReachComponent_HandleSingleVideoStatsResponse : ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("ReportsReachComponent_HandleSingleVideoStatsResponse : ==>", oFormatedIncommingMessage);
      this.oTotalStats = oFormatedIncommingMessage;
      const views = this.oTotalStats.total_events.video_play;
      for (let nVideoViewsIndex = 0; nVideoViewsIndex < this.lTopVideos.length; nVideoViewsIndex++) 
      {
        if(this.lTopVideos[nVideoViewsIndex].video_id === this.sVideoID)
        {
          this.lTopVideos[nVideoViewsIndex].views = views;
          break;
        }
      }
      this.ReportsReachComponent_CalculateChartData(this.oTotalStats.total_events_by_day);
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent("ga_user_single_video_stats_result", this.ReportsReachComponent_HandleSingleVideoStatsResponse);
  }
  ReportsReachComponent_GetVideoDetails(sIncommingVideoID : string)
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.oWebSocketService.WebSocket_RegisterEvent('video_data_result', this.ReportsReachComponent_HandleSingleVideoResponse);
    let obj = 
    {
      email: this.sUserEmail,
      session_id: this.sSessionId,
      video_id: sIncommingVideoID
    };
    this.oWebSocketService.WebSocket_SendMessage("video_data_request", JSON.stringify(obj));
  }
  ReportsReachComponent_HandleSingleVideoResponse = async (IncomingMessage) => 
  {
    if (JSON.parse(IncomingMessage)['errorMessage']) 
    {
      console.error("ReportsReachComponent_HandleSingleVideoResponse : ERROR ==>",JSON.parse(IncomingMessage)['errorMessage']);
    }
    else 
    {
      let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
      await this.ReportsReachComponent_GenerateTopVideosList(oFormatedIncommingMessage.video);
      console.log("ReportsReachComponent_HandleSingleVideoResponse : video Details => ", JSON.parse(IncomingMessage));
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent('video_data_result', this.ReportsReachComponent_HandleSingleVideoResponse);
    this.oWebSocketService.WebSocket_Destroy();
  }
  async ReportsReachComponent_GenerateTopVideosList(oIncommingVideoDetails : any)
  {
    const video = oIncommingVideoDetails; 
    let thumbnail : string = await this.oDataService.DataService_GetSignedURLForDownload(video.thumb);
    let url = await this.oDataService.DataService_GetSignedURLForDownload(video.fpath);
    let nVideoIndex : number;
    for (let nVideoViewsIndex = 0; nVideoViewsIndex < this.lTopVideosViews.length; nVideoViewsIndex++) 
    {
      if(this.lTopVideosViews[nVideoViewsIndex].video_id === video.fpath)
      {
        nVideoIndex = nVideoViewsIndex;
        break;
      }
    }
    const views = nVideoIndex!==undefined?this.lTopVideosViews[nVideoIndex].views:video.views;
    this.lTopVideos.push({thumb: thumbnail, url : url, video_id : video.fpath, views : views, title : video.title});
  }
  ReportsReachComponent_SearchOnKeyUp(keycode : any)
  {
    if(keycode == 13)
    {
      this.ReportsReachComponent_SearchVideo();
    }
  }
  ReportsReachComponent_SearchVideo()
  {
    if(this.sTitleToSearch !== "")
      {
        this.ReportsReachComponent_FetchUserVideos(this.sTitleToSearch);
        this.bEnableFetchingTopVideos=true;
      }
    else
      {
        if(this.bEnableFetchingTopVideos==true)
        {
          this.ReportsReachComponent_GetUserTopVideos(this.sSeletedTimeFrame.value);
          this.bEnableFetchingTopVideos=false;
        }
      }
  }
  ReportsReachComponent_FetchUserVideos(sIncommingTitle : string)
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.oWebSocketService.WebSocket_RegisterEvent('search_video_result', this.ReportsReachComponent_HandleVideoListResponse);
    let oVideosListPayload : any = 
    {
      email: this.oSessionService.cSessionService_GetUserEmail(),
      session_id: this.oSessionService.SessionService_GetSessionId(),
      publish_status : "published",
      num_skip: 0,
      num_items: 10,
      title : sIncommingTitle
    }
    this.oWebSocketService.WebSocket_SendMessage("search_video_request", JSON.stringify(oVideosListPayload));
  }
  ReportsReachComponent_HandleVideoListResponse = async (IncomingMessage) => 
  {
    let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
    if (JSON.parse(IncomingMessage)['errorMessage']) 
    {
      console.error("ReportsReachComponent_HandleVideoListResponse : Error ==>", JSON.parse(IncomingMessage)['errorMessage']);
    }
    else 
    {
      console.log("ReportsReachComponent_HandleVideoListResponse: response => ", JSON.parse(IncomingMessage));
      let lVideosList = oFormatedIncommingMessage.results;
      this.lTopVideos=[];
      if(lVideosList.length>0)
      { 
        for (let nVisualToPushIndex = 0; nVisualToPushIndex < lVideosList.length; nVisualToPushIndex++) 
        {
          const video = lVideosList[nVisualToPushIndex];
          let thumbnail : string = await this.oDataService.DataService_GetSignedURLForDownload(video.thumb);
          let url = await this.oDataService.DataService_GetSignedURLForDownload(video.fpath);
          if(video.thumb !=="")
          {
            this.lTopVideos.push({thumb: thumbnail, url : url, video_id : video.fpath, views : video.views, title : video.title});
          }
        }
      }
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent('user_video_list_result', this.ReportsReachComponent_HandleVideoListResponse);
  }
}
