import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { cDataService } from './../../services/data.service';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { cWebSocketService } from './../../services/websocket.service';
import { cVideoDetails } from '../models/video-details';

@Component({
  selector: 'app-locations-created-videos',
  templateUrl: './locations-created-videos.component.html',
  styleUrls: ['./locations-created-videos.component.scss']
})
export class LocationsCreatedVideosComponent implements OnInit, OnDestroy {

  @Input() sInputVideoStatus : string;
  @Output() bEmittedDisplayLoader = new EventEmitter<boolean>();
  lPublishedVideos : any = [];
  videoListForm : FormGroup;
  lVideoList : any = [];
  sSessionId: string;
  sUserEmail: string;
  bDisplayCountSection: boolean =true;
  sSelectedVideoId : string;
  bDisplayCreateVideoSection : boolean = false;
  lTemplateCategory : any = [];
  lTemplateNames : any = [];
  bAlert: boolean = false;
  oSetTime: any;
  bIsPublish: boolean = false;
  sSelectedSortingCategory  : string = "Last Modified";
  lSortBy : any = [{name  : 'Last Modified', value : 'last_modified'},{name  : 'Last Created', value : 'last_created'}];
  oSearchVideoSubscription : any;
  bPublishedEmptyScreenView: boolean = false;
  customGridLayout : string;
  addCustomClass: string;
  constructor( private oWebSocketService : cWebSocketService, private oSessionService : cSessionService, private oEditVideoService : cEditVideoService, private oTemplateConfigService : cTemplateConfigService, private oFormBuilder : FormBuilder,
               private oRouter : Router, private oDataService : cDataService) 
  {
    if(this.oRouter.url === '/dashboard/myvideos')
    {
      this.bDisplayCountSection = false;
      this.bPublishedEmptyScreenView = true;
      this.addCustomClass = 'custom-empty-state'
    }
    if(this.oRouter.url === '/account-setting/published-videos')
    {
      this.customGridLayout = 'custom-grid-list-view'
    }
    else 
    {
      this.customGridLayout = ''
    }
  }
    
  ngOnInit(): void 
  {
    this.InitializeJqueryCode()
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.videoListForm = this.oFormBuilder.group(
    {
      email: this.oSessionService.cSessionService_GetUserEmail(),
      session_id: this.oSessionService.SessionService_GetSessionId(),
      // publish_status : this.sInputVideoStatus,
      location_videos: true,
      num_skip: 0,
      num_items: 10
    });
    this.CreatedVideosComponent_SendTemplateReques();
    this.CreatedVideosComponent_FetchUserVideos();
    this.CreatedVideosComponent_SetTimeOut();
    //Search Videos based on title
    this.CreatedVideosComponent_SearchByTitle();
    this.oSessionService.cSessionService_SetVideoStatus(this.sInputVideoStatus);
  }
  CreatedVideosComponent_SearchByTitle()
  {
    console.log("Created Component NGONINIT")
    this.oSearchVideoSubscription = this.oDataService.sSearchVideoByTitle.subscribe(data=>
    {
      if(data.title!=="" && (data.type=="draft" || data.type=="published"))
      {
        this.CreatedVideosComponent_SearchByTiltle(data.title);
      }
      else if(data.title ==="" && (data.type=="draft" || data.type=="published"))
      {
        this.CreatedVideosComponent_FetchUserVideos();
      }
      console.log("CreatedVideosComponent : Search By title ==> ", data);
    });
  }
  ngOnDestroy(){ this.oSearchVideoSubscription.unsubscribe();}
  CreatedVideosComponent_SearchByTiltle(sIncommingTitle :string)
  {
    this.oWebSocketService.WebSocket_RegisterEvent('search_video_result', this.CreatedVideosComponent_HandleSearchVideoResponse);
    const oTemplatePayload = {email : this.sUserEmail, session_id : this.sSessionId, last_modified : true, publish_status : this.sInputVideoStatus, title : sIncommingTitle, num_items : 10, num_skip : 0};
    this.oWebSocketService.WebSocket_SendMessage('search_video_request', JSON.stringify(oTemplatePayload));
  }
  CreatedVideosComponent_HandleSearchVideoResponse = async (IncomingMessage) => 
  {
    console.log("CreatedVideosComponent_HandleSearchVideoResponse : response => ", JSON.parse(IncomingMessage));
    let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
    if (JSON.parse(IncomingMessage)['errorMessage']) 
    {
      this.bEmittedDisplayLoader.emit(false);
      this.bDisplayCreateVideoSection=true;
    }
    else 
    {
      let lVideosList = oFormatedIncommingMessage.results;
      this.lPublishedVideos=[];
      if(lVideosList.length>0)
      { 
        for (let nVisualToPushIndex = 0; nVisualToPushIndex < lVideosList.length; nVisualToPushIndex++) 
        {
          const video = lVideosList[nVisualToPushIndex];
          let oVideoStatus = video.publish_status;
          if(oVideoStatus==="published" || oVideoStatus==="scheduled")
          {
            this.bIsPublish=true;
          }
          if(video.thumb !=="")
          {
          await this.CreatedVideosComponent_PushIncommingVideo(video,nVisualToPushIndex);
          }
        }
      }
      setTimeout(() => {this.InitializeJqueryCode()}, 2000); 
      this.bEmittedDisplayLoader.emit(false);
      this.bDisplayCreateVideoSection=true;
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent('search_video_result', this.CreatedVideosComponent_HandleSearchVideoResponse);
  }  
  CreatedVideosComponent_SetTimeOut()
  {
   this.oSetTime = setTimeout(() => {this.oRouter.navigateByUrl('/timeout-error');}, 10000);
  }
  CreatedVideosComponent_SendTemplateReques()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('video_templates_result', this.CreatedVideosComponent_HandleVideoTemplatesResponse);
    const oTemplatePayload = {email : this.sUserEmail, session_id : this.sSessionId};
    this.oWebSocketService.WebSocket_SendMessage('video_templates_request', JSON.stringify(oTemplatePayload));
  }
  CreatedVideosComponent_HandleVideoTemplatesResponse = (IncomingMessage) => 
  {
    console.log('CreatedVideosComponent_HandleVideoTemplatesResponse : ', JSON.parse(IncomingMessage));
    clearTimeout(this.oSetTime);
    if (IncomingMessage.errorMessage) 
    {
      const sError = 'Error ' + IncomingMessage.errorMessage;
    }
    else 
    {
      this.oSessionService.cSessionService_SetTemplatesConfiguration(IncomingMessage);
      this.lTemplateCategory=this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategoriesNames();
      this.lTemplateNames=this.oTemplateConfigService.TemplateConfigService_FetchTemplateNames();
    }
  };
  CreatedVideosComponent_SortVideos(oIncommingSortingFilter : any)
  {
    this.sSelectedSortingCategory  = oIncommingSortingFilter.name;
  }

  CreatedVideosComponent_FetchUserVideos()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('user_video_list_result', this.CreatedVideosComponent_HandleVideoListResponse);
    console.log("CreatedVideosComponent_FetchUserVideos : Video List Request ==> ",this.videoListForm.value);
    this.oWebSocketService.WebSocket_SendMessage("user_video_list_request", JSON.stringify(this.videoListForm.value));
  }
  CreatedVideosComponent_HandleVideoListResponse = async (IncomingMessage) => 
  {
    console.log("CreatedVideosComponent_HandleVideoListResponse: response => ", JSON.parse(IncomingMessage));
    let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
    if (JSON.parse(IncomingMessage)['errorMessage']) 
    {
      this.bEmittedDisplayLoader.emit(false);
      this.bDisplayCreateVideoSection=true;
    }
    else 
    {
      let lVideosList = oFormatedIncommingMessage.video_list;
      this.lPublishedVideos=[];
      if(lVideosList.length>0)
      { 
        for (let nVisualToPushIndex = 0; nVisualToPushIndex < lVideosList.length; nVisualToPushIndex++) 
        {
          const video = lVideosList[nVisualToPushIndex];
          let oVideoStatus = video.publish_status;
          if(oVideoStatus==="published" || oVideoStatus==="scheduled")
          {
            this.bIsPublish=true;
          }
          if(video.thumb !=="")
          {
            await this.CreatedVideosComponent_PushIncommingVideo(video,nVisualToPushIndex);
          }
        }
      }
      this.bEmittedDisplayLoader.emit(false);
      this.bDisplayCreateVideoSection=true;
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent('user_video_list_result', this.CreatedVideosComponent_HandleVideoListResponse);
    console.log("CreatedVideosComponent_HandleVideoListResponse : Video List ==> ", this.lPublishedVideos);
  }
  async CreatedVideosComponent_PushIncommingVideo(video : any, nVisualToPushIndex : number)
  {
    
    let thumbnail :  any =  await this.oDataService.DataService_GetSignedURLForDownload(video.thumb);
    let template_category = this.lTemplateCategory.indexOf(video.template_category) !== -1 ? video.template_category : "Bold";
    let template_name = this.lTemplateNames.indexOf(video.template_name) !== -1 ? video.template_name : "Bold1";
    let url = await this.oDataService.DataService_GetSignedURLForDownload(video.fpath);
    let vo_text = video.vo_text?video.vo_text:'';
    let selected_voice = video.selected_voice;

    if(!video.vo_file)
    {
      selected_voice = selected_voice?selected_voice:'voice3';
    }
    let lRemoveDuplicates = new Set(video.key_terms);
    let lKeyterms = [...lRemoveDuplicates];
    let VideoDetails = new cVideoDetails
    (
      "video_"+nVisualToPushIndex,thumbnail,url,video.title, video.duration,video.created, video.automated,video.color,
      video.days_ago,video.fpath,lKeyterms,video.music_file,selected_voice,video.publish_status,
      template_name,video.text_list,vo_text,video.about,video.aspect_ratio, video.font_color,video.logo_img,video.second_logo_img,
      template_category,video.user_clips,video.user_images,video.ci_dict,video.vo_file, video.use_pronto_clips, video.block_config, video.comments, video.locales
    );
    this.lPublishedVideos.push(VideoDetails);
  }
  CreatedVideosComponent_PlayVideo(nIncommingVideoId)
  {
    this.sSelectedVideoId=nIncommingVideoId;
    this.lPublishedVideos.forEach(element => {
      var video = <HTMLVideoElement> document.getElementById(element.id);
      if(element.id == nIncommingVideoId)
      {
        video.play();
      }
      else 
      {
        video.pause();
      }
    });
  }
  
  CreatedVideosComponent_SetEditVideo(oIncommingVideo : any)
  { 
    this.bEmittedDisplayLoader.emit(true);
    this.oSessionService.cSessionService_SetOriginalVideoBlockConfig(oIncommingVideo.block_config);
    this.oSessionService.cSessionService_SetVideoStatus(oIncommingVideo.status);
    this.oEditVideoService.EditVideoService_GetEditVideo(oIncommingVideo.video_id);  
    this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
    this.oSessionService.cSessionService_SetOriginalVideoThumbUrl({thumb : oIncommingVideo.thumb,url :oIncommingVideo.url});
  }
  CreatedVideosComponent_NavigateToCreateVideo()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
    this.oRouter.navigate(['wizard/overview']);
  }
  CreatedVideosComponent_NavigateToOverView(oIncommingVideo : any)
  {
    this.oSessionService.cSessionService_SetVideoDetails(oIncommingVideo);
    this.oSessionService.cSessionService_SetVideoStatus(this.sInputVideoStatus);
    this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
    this.oRouter.navigate(['/dashboard/overview']);
  }
  CreatedVideosComponent_NavigateToShare(oIncommingVideo : any)
  {
    this.oSessionService.cSessionService_SetVideoDetails(oIncommingVideo);
    this.oSessionService.cSessionService_SetVideoStatus(this.sInputVideoStatus);
    this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
    this.oRouter.navigate(['/dashboard/socialmedia']);
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `{
      $(".customAccordionBody").slideUp();
      $(".customAccordion:first-child .customAccordionBody").slideDown();
      $(".customAccordionAction").on("click", function(){
        if($(this).parent(".customAccordion").hasClass("active")) {}
        else {
          $(".customAccordion").removeClass("active");
          $(this).parent(".customAccordion").addClass("active");
          $(".customAccordionBody").slideUp();
          $(this).siblings(".customAccordionBody").slideDown();
        }
      });
      
      jQuery('.button-gl').on('click',function(e) {
        jQuery('.button-gl').removeClass('active');
        jQuery(this).addClass('active');

    if (jQuery(this).hasClass('grid')) {
        jQuery('#container-view div.row').removeClass('list').addClass('grid');
    }
    else if(jQuery(this).hasClass('list')) {
        jQuery('#container-view div.row').removeClass('grid').addClass('list');
    }
});

jQuery(function(){
jQuery('.showSingle').click(function(){
    jQuery('.targetDiv').hide('.cnt');
    jQuery('#div'+$(this).attr('target')).slideToggle();

}); 
});
}`;
    document.body.appendChild(chatScript);
  }

}
