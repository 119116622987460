import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands-account',
  templateUrl: './brands-account.component.html',
  styleUrls: ['./brands-account.component.scss']
})
export class BrandsAccountComponent implements OnInit {
  lUserBrands : any = ['item1','item2','item3']
  constructor() { }

  ngOnInit(): void {
  }

}
