<nav>
    <div class="sidebar-header pb-5" style="padding: 3px 20px">
        <h3 class="text-gray-2 weight-400 text-lg pt-2">
            Add Voiceover
            <p class="mb-0 mt-1 text-xs text-gray-3">Select an audio file</p>
        </h3>
        <div class="closeRightSideBar pe-2" style="top: 0;" (click)="closeVoiceover()">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.409957 11.8C0.304968 11.8 0.204978 11.76 0.124987 11.685C-0.0299968 11.53 -0.0299968 11.275 0.124987 11.12L5.33943 5.9L0.114988 0.68C0.0399958 0.605 0 0.505 0 0.4C0 0.295 0.0399958 0.195 0.114988 0.12C0.194979 0.04 0.294969 0 0.399958 0C0.504947 0 0.604936 0.04 0.684927 0.115L5.90437 5.335L11.1188 0.115C11.1938 0.04 11.2938 0 11.3988 0C11.5038 0 11.6038 0.04 11.6838 0.115C11.8387 0.27 11.8387 0.525 11.6838 0.68L6.46931 5.9L11.6788 11.11C11.7538 11.185 11.7938 11.285 11.7938 11.39C11.7938 11.495 11.7538 11.595 11.6788 11.675C11.6038 11.755 11.5038 11.79 11.3938 11.79C11.2838 11.79 11.1888 11.75 11.1088 11.675L5.90437 6.465L0.689927 11.685C0.614935 11.76 0.514945 11.8 0.409957 11.8Z"
                        fill="#7A7A7A"></path>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="11.8" height="11.8" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="side-content flex-grow-1 pt-0" style="padding: 0px 14px !important">
       
        <button  (click)="ContentComponent_ToggleFileUploadType(true)" class="btnFileUpload btn-secondary btn-block" style="position: relative;">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.18253 5.2881C4.36857 5.49249 4.67022 5.49249 4.85626 5.2881L8.01029 1.82314L8.0122 9.30108C8.01227 9.5773 8.23654 9.80116 8.51312 9.80107C8.7897 9.80098 9.01385 9.57699 9.01378 9.30077L9.01186 1.82327L12.1658 5.2881C12.3518 5.49249 12.6535 5.49249 12.8395 5.2881C13.0255 5.08371 13.0255 4.75233 12.8395 4.54794L8.84788 0.162813C8.66183 -0.0415756 8.36019 -0.0415758 8.17415 0.162813L4.18253 4.54794C3.99648 4.75233 3.99648 5.08371 4.18253 5.2881ZM1.50096 7.2379C1.50096 6.96167 1.27675 6.73775 1.00018 6.73775C0.7236 6.73775 0.49939 6.96167 0.49939 7.2379V8.48817C0.49939 12.6315 3.86255 15.9904 8.0112 15.9904H9.01477C13.1634 15.9904 16.5266 12.6315 16.5266 8.48817V7.2379C16.5266 6.96167 16.3024 6.73775 16.0258 6.73775C15.7492 6.73775 15.525 6.96167 15.525 7.2379V8.48817C15.525 12.0791 12.6103 14.9901 9.01477 14.9901H8.0112C4.4157 14.9901 1.50096 12.0791 1.50096 8.48817V7.2379Z"
                    fill="white" />
            </svg>
            <span class="text-sm">Upload a File</span>
            <input #file class="upload-file" type="file" accept="audio/mp3,audio/mp4" name="myfile" (change)="ContentComponent_UploadedVoiceOverFile($event)">
        </button>
        <div class="text-center my-3 text-xs text-gray-3">
            or
        </div>
        <label class="text-lg text-gray-2 mb-3" href="javascript:void(0)" (click)="ContentComponent_TextToSpeech()">Enter text for Voiceover</label>
        <!-- <div>
            <label class="text-sm text-gray-3 mb-2">Enter your text</label>
        </div> -->
        <!-- Uploaded voice over file -->
        <div class="media_list" [ngClass]="nVoiceOverFileSize!==0?'d-block':'d-none'">
            <ul>
                <li class="mb-3" >
                    <figure class="media_figure">
                        <span class="media">
                            <img src="../assets/images/business_Profile/audio-line.png" alt="vo-file"></span>
                        <figcaption>
                            <div class="caption w-100">
                                <p class="text-xs sub_title">{{sUploadedVoiceOverFileName | slice:0:30}}</p>
                                <p class="text-xs sub_title"> {{ContentComponent_ReturnFileSize(nVoiceOverFileSize)}}</p>
                            </div>
                        </figcaption>
                    </figure>
                </li>
            </ul>
        </div>

        <div class="formItm" [ngClass]="bShowTextToSpeechSection?'d-block':'d-none'">
            <input id="voice_over_text" [(ngModel)]="va_text" type="text" placeholder="Enter your text" required=""  >
            <label class="text-sm" for="voice_over_text">Enter your text</label>
        </div>
        <div class="mb-3" [ngClass]="bShowTextToSpeechSection?'d-block':'d-none'">
            <label class="text-sm text-gray-3">Select type of voice</label>
            <div class="d-flex mt-3">
                <label class="customCheck customCheckRounder">
                    <input type="radio" class="check form-check-input" name="voiceType" [checked]="sSelectedVoiceGender==='male'?true:false" (click)="ContentComponent_SelectVoiceGender('male','create')" />
                    <span class="customCheckCheck"></span>
                    <span class="customCheckText text-gray-1 text-md">Male</span>
                </label>
                <label class="customCheck customCheckRounder ms-4">
                    <input type="radio" class="check form-check-input" name="voiceType" [checked]="sSelectedVoiceGender==='female'?true:false" (click)="ContentComponent_SelectVoiceGender('female','create')" />
                    <span class="customCheckCheck"></span>
                    <span class="customCheckText text-gray-1 text-md">Female</span>
                </label>
            </div>
        </div>
        <div [ngClass]="bShowTextToSpeechSection?'d-block':'d-none'">
            <div class="dropdown dropdownSelect">
                <label class="text-sm text-gray-3">Select voice</label>
                <button class="dropdown-toggle text-md" style="color: black" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{this.sVoiceName | titlecase}}
                </button>
                <ul class="dropdown-menu dropdownMenu">
                    <li *ngFor="let item of oVoiceOverList;let i=index;" [class.active]="i == nSelectedVoiceIndex" (click)="ContentComponent_SelectVoice(item,i)">
                        <a class="dropdown-item text-md d-flex justify-content-between">{{item.name | titlecase}}
                        <span class="play_staus">
                           <span class="play" (mouseover)="ContentComponent_PlayAudio(item.sample_voice)" (mouseout)="ContentComponent_StopAudio(item.sample_voice)" [ngClass]="i == nSelectedVoiceIndex ? 'd-none' : 'd-block'">
                            <svg width="8" height="8" viewBox="0 0 8 8"   
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.666992 7.08335C0.666992 7.60239 1.04444 7.81494 1.50594 7.55599L6.98752 4.47209C7.44904 4.21256 7.44904 3.78748 6.98752 3.52796L1.50594 0.443766C1.04441 0.184243 0.666992 0.396502 0.666992 0.916401V7.08335Z" fill="#2B2F32" />
                                </svg>
                           </span>
                        </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
       
    </div>
    <div class="ps-3" style="position:absolute; bottom:3%;">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSideBar" (click)="VideoTimelineView_SubmitfVoiceoverForm()">Replace</button>
    </div>
</nav>