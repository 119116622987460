import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private approvalStage = new BehaviorSubject('');
  currentApprovalStage = this.approvalStage.asObservable();
 
  constructor() {
 
  }
  listenChange(listenMessage: string) {
  this.approvalStage.next(listenMessage)
  }
 }