import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  lUsersData : any = ['item1','item1','item1','item1','item1','item1','item1','item1','item1','item1',]

  constructor(private oRouter : Router) { }

  ngOnInit(): void {
  }
  NavigateToBrandsAccount()
  {
    this.oRouter.navigateByUrl('business-profile/brands/account')
  }

}
