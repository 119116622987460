import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-customers-experience',
  templateUrl: './customers-experience.component.html',
  styleUrls: ['./customers-experience.component.scss']
})
export class CustomersExperienceComponent implements OnInit, OnDestroy {

  sSessionId: string;
  sUserEmail: string;
  mood_list: any=[];
  lSelectedMoodListValues : any = 
  [
    {name : "energized",checked : false},
    {name : "relaxed",checked : false},
    {name : "hopeful",checked : false},
    {name : "calm",checked : false},
    {name : "excited",checked : false},
    {name : "upbeat",checked : false}
  ];
  fCustomerExperienceForm : FormGroup;
  oSavedFormData : any;

  constructor(private oWebSocketService : cWebSocketService , private oFormBuilder : FormBuilder ,private oSessionService : cSessionService ) { }

  ngOnInit() {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oSavedFormData=this.oSessionService.cSessionService_GetCustomerExperienceForm();
    if(this.oSavedFormData)
    {
      this.CustomerExperienceComponent_SetCustomerExperienceForm();
    }
    else
    {
      this.CustomerExperienceComponent_GetProfileData();
      this.CustomerExperienceComponent_InitializeUserProfileForm();
    }
  }

  CustomerExperienceComponent_SetCustomerExperienceForm()
  {
    this.fCustomerExperienceForm = this.oFormBuilder.group(
      {
        session_id: [this.oSavedFormData.session_id],
        email: [this.oSavedFormData.email],
        greeting : [this.oSavedFormData.greeting],
        mood : [this.oSavedFormData.mood]
      }
      );
      this.mood_list= this.oSavedFormData.mood;
      this.mood_list.forEach(selectedmoods => 
      {
        this.lSelectedMoodListValues.forEach(allmoods => 
        {
          if(allmoods.name==selectedmoods)
          {
            allmoods.checked=true;
          }
        }); 
      });
  }

  CustomerExperienceComponent_InitializeUserProfileForm(){
    this.fCustomerExperienceForm = this.oFormBuilder.group({
      company_name: [''],
      session_id: this.sSessionId,
      url: [''],
      business_type: [''],
      industry: [''],
      address: this.oFormBuilder.group({
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        postal_code: ''
      }),
      phone: [''],
      email: this.sUserEmail,
      color: '',
      logo_file: '',
      greeting: [''],
      mood: [''],
      location:[''],
      name : [''],
      is_subscribed_to_email : [0],
      password :['']
    })
  }

  CustomerExperienceComponent_GetProfileData()
  {
      this.oWebSocketService.WebSocket_RegisterEvent('profile_request_result', this.CustomerExperienceComponent_HandleUserProfileResponse);
      const oGetUserProfile = 
      {
        session_id: this.sSessionId,
        email: this.sUserEmail
      }
      console.log("CustomerExperienceComponent_GetProfileData : Profile Request Object ==> ",oGetUserProfile);
      this.oWebSocketService.WebSocket_SendMessage('profile_request', JSON.stringify(oGetUserProfile));
    }

  CustomerExperienceComponent_HandleUserProfileResponse = (InComingMessage) => 
    {
      console.log('CustomerExperienceComponent_HandleUserProfileResponse : Profile Response ==> ', InComingMessage);
      if (InComingMessage['errorMessage']) 
      {
        console.error('CustomerExperienceComponent_HandleUserProfileResponse: Profile Response Error ==> ', InComingMessage);
        
      }
      else
      {
        let oResponse = InComingMessage;
        console.log("CustomerExperienceComponent_HandleUserProfileResponse: Get Profile Response==>" , oResponse);
        
        if(oResponse.greeting){
          this.fCustomerExperienceForm.patchValue({
            greeting : oResponse.greeting
          })
        }
      }
    }

  CustomerExperienceComponent_UpdateCustomerExperience()
  {
    
    this.oWebSocketService.WebSocket_RegisterEvent('profile_update_result', this.CustomerExperienceComponent_HandleUpdateProfileResponse);
    console.log("CustomerExperienceComponent_UpdateCustomerExperience : Line : 77 Profile Form ==> ", this.fCustomerExperienceForm.value);
    this.oWebSocketService.WebSocket_SendMessage('profile_update', JSON.stringify(this.fCustomerExperienceForm.value));
  }

  CustomerExperienceComponent_HandleUpdateProfileResponse = (InComingMessage) =>
  { 
    if (InComingMessage['errorMessage']) 
    {
      console.error('CustomerExperienceComponent_HandleUpdateProfileResponse: Profile Update Error ==> ', InComingMessage);
    }
    else 
    {
      console.log('CustomerExperienceComponent_HandleUpdateProfileResponse : Profile Update Response ==> ', InComingMessage);
    }
  };

  CustomerExperienceComponent_MoodListValue(value)
  { 
    for(let nMoodIndex=0; nMoodIndex<this.lSelectedMoodListValues.length;nMoodIndex++)
    {
      if(this.lSelectedMoodListValues[nMoodIndex].name===value)
      {
        this.lSelectedMoodListValues[nMoodIndex].checked = !this.lSelectedMoodListValues[nMoodIndex].checked;
      }  
    }
    this.mood_list=[];
    this.lSelectedMoodListValues.forEach(element => 
    {
      if(element.checked)
      {
        this.mood_list.push(element.name)
      }  
    });
    this.fCustomerExperienceForm.patchValue({ mood: this.mood_list});
  }
  ngOnDestroy()
  {
    this.oSessionService.cSessionService_SetCustomerExperienceForm(this.fCustomerExperienceForm.value);
    console.log("NgOnDestroy Form Called ==>>>>",this.fCustomerExperienceForm.value);
    this.CustomerExperienceComponent_UpdateCustomerExperience();
  }

}
