import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {
  bDisplayBrandsSection : boolean = false
  constructor() {
    if(environment.business_plan === 'enterprise_agency')
    {
      this.bDisplayBrandsSection = true
    }
   }

  ngOnInit(): void {
  }

}
