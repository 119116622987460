import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publish-video-timeline-view-fun-fect',
  templateUrl: './publish-video-timeline-view-fun-fect.component.html',
  styleUrls: ['./publish-video-timeline-view-fun-fect.component.scss']
})
export class PublishVideoTimelineViewFunFectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
