import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-business-sidebar',
  templateUrl: './business-sidebar.component.html',
  styleUrls: ['./business-sidebar.component.scss']
})
export class BusinessSidebarComponent implements OnInit {
  showMenu : string;
  bDisplayUserOption: boolean = false;
  bDisplayBrandsOption: boolean = false;
  bDisplayFrancheseesOption: boolean;
  bDisplayEnterpriseMediaOption: boolean = false;
  constructor(private oRouter : Router) {
    this.showMenu = this.oRouter.url
   }

  ngOnInit(): void {
  if(environment.business_plan === 'business_plus' || environment.business_plan === 'enterprise_agency' )
    {
      this.bDisplayUserOption = true
    }
    if(environment.business_plan === 'enterprise_agency' ){
      this.bDisplayBrandsOption = true
    }
    if(environment.business_plan === 'enterprise_franchesees')
    {
      this.bDisplayFrancheseesOption = true
    }
    if(environment.business_plan === 'enterprise_franchesees' || environment.business_plan === 'enterprise_agency')
    {
      this.bDisplayEnterpriseMediaOption = true
    }
    this.InitializeJqueryCode()
    
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `$(document).ready(function () {
      function toggleSidebar(side) {
        if (side !== "left" && side !== "right") {
          return false;
        }
        var left = $("#sidebar-left"),
          right = $("#sidebar-right"),
          content = $("#content"),
          openSidebarsCount = 0,
          contentClass = "";
        
        // toggle sidebar
        if (side === "left") {
          left.toggleClass("collapsed");
        } else if (side === "right") {
          right.toggleClass("collapsed");
        }
        
        // determine number of open sidebars
        if (!left.hasClass("collapsed")) {
          openSidebarsCount += 1;
        }
        
        if (!right.hasClass("collapsed")) {
          openSidebarsCount += 1;
        }
        
        // determine appropriate content class
        if (openSidebarsCount === 0) {
          contentClass = "col-md-12";
        } else if (openSidebarsCount === 1) {
          contentClass = "col-md-11 pleft";
                   
        } else {
          contentClass = "col-md-9";
        }
        
        // apply class to content
        content.removeClass("col-md-12 col-md-9 col-md-11 pleft")
             .addClass(contentClass);
      }
      
      $(".toggle-sidebar-left").click(function () {
        toggleSidebar("left");
        
        return false;
      });
      $(".toggle-sidebar-right").click(function () {
        toggleSidebar("right");
        
        return false;
      });
    });
     `;
    document.body.appendChild(chatScript);
  }
}
