
    <section class="loginSec">
        <app-onboarding-aside-section class="loginSecItm loginSecLeft"></app-onboarding-aside-section>
        <div class="loginSecItm loginSecRight">
        
            <div class="loginSecItmContent-plane">
                <div class="d-lg-none pb-5">
                    <a routerLink="/login">
                        <img src="../../../assets/images/logo-mobile.svg" alt="logo-mobile" width="120"/>
                    </a>
                </div>
                <a routerLink="/createAccount">
                    <img src="../assets/images/onBoarding/back-arrow.svg" alt="back-arrow"/>
                </a>
                <div class="row">

                    <div class="col-md-10">
                <h2 class="text-gray-1 mt-5">Enter Verification Code</h2>
                <p class="text-sm mt-3 ms-0 pb-0 mb-0">Open the Google Authenticator app on your smartphone and<br> enter the code displayed for this login here.  </p>
            </div></div>
                <div class="loginSecItmForm pt-4" aria-labelledby="loginYourself">
                    <form [formGroup]="fAuthenticationCodeForm">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="formItm">
                                    <input id="VerificationCode" type="number" value=""
                                        placeholder="Enter Your Verification Code" required name="textfeild" formControlName="code">
                                    <label for="VerificationCode" class="text-sm">Enter Your Verification Code</label>
                                    <span *ngIf="fAuthenticationCodeForm.controls['code'].hasError('required') && fAuthenticationCodeForm.controls['code'].touched" class="error">Please Enter Your Verification Code</span>
                                </div>
                            </div>
                        </div>  
                         <div *ngIf="bDisplayErrorMsg" class=" alert alert-danger d-flex align-items-center alert-dismissible fade show col-md-5"
                                  role="alert"> 
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M27.5683 21.8754L16.8998 3.08014C15.6241 0.839822 12.3899 0.839822 11.1141 3.08014L0.445544 21.8754C-0.830163 24.0854 0.788172 26.8545 3.33834 26.8545H24.6437C27.2254 26.8545 28.8121 24.1169 27.568 21.8754H27.5683ZM14.0387 7.56066C15.0337 7.56066 15.5318 8.46261 15.5318 10.2994C15.5318 11.2949 15.4079 12.6649 15.1273 14.4385C14.8478 16.2121 14.6607 17.3012 14.4736 17.6441C14.3497 17.8933 14.2245 18.0173 14.0387 18.0173C13.8212 18.0173 13.6657 17.9237 13.5721 17.7061C13.4166 17.4265 13.1991 16.4297 12.9185 14.7182C12.639 13.0066 12.5139 11.5126 12.4835 10.3298C12.4835 8.46383 13.012 7.56066 14.0387 7.56066V7.56066ZM15.1273 22.0627C14.8162 22.3739 14.4432 22.5295 14.0071 22.5295C13.5721 22.5295 13.1979 22.3739 12.8869 22.0627C12.5758 21.7516 12.4203 21.3784 12.4203 20.942C12.4203 20.5068 12.5758 20.1324 12.8869 19.8212C13.1979 19.51 13.5709 19.3544 14.0071 19.3544C14.4116 19.3544 14.7846 19.51 15.1273 19.8528C15.4699 20.1956 15.6254 20.5688 15.6254 20.9736C15.6242 21.3784 15.4383 21.7516 15.1273 22.0627Z"
                                        fill="#842029" />
                                </svg>
                                <div>
                                    {{sErrorMessage}}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert"
                                        aria-label="Close"><svg width="16" height="16" viewBox="0 0 16 16"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M0.546609 15.7333C0.406624 15.7333 0.273304 15.68 0.166649 15.58C-0.0399958 15.3733 -0.0399958 15.0333 0.166649 14.8267L7.11925 7.86667L0.153317 0.906667C0.0533277 0.806667 0 0.673333 0 0.533333C0 0.393333 0.0533277 0.26 0.153317 0.16C0.259972 0.0533333 0.393292 0 0.533277 0C0.673262 0 0.806581 0.0533333 0.913237 0.153333L7.8725 7.11333L14.8251 0.153333C14.9251 0.0533333 15.0584 0 15.1984 0C15.3384 0 15.4717 0.0533333 15.5784 0.153333C15.785 0.36 15.785 0.7 15.5784 0.906667L8.62575 7.86667L15.5717 14.8133C15.6717 14.9133 15.725 15.0467 15.725 15.1867C15.725 15.3267 15.6717 15.46 15.5717 15.5667C15.4717 15.6733 15.3384 15.72 15.1917 15.72C15.0451 15.72 14.9184 15.6667 14.8118 15.5667L7.8725 8.62L0.919903 15.58C0.819913 15.68 0.686594 15.7333 0.546609 15.7333Z"
                                                fill="#FF8369" />
                                        </svg>
                                    </button>
                                </div>
                         </div>
                        
                        <div class="row mt-4">
                            <div class="col-md-5">
                                <div class="row g-0">
                                    <div class="col">
                                        <div class="mb-3">
                                            <button class="btn btn-secondary btn-round-6 w-184"(click)="AuthenticationCodeComponent_Confirmation()">Submit</button>
                                            <a class="text-gray-6 text-sm ms-5" href="javascript:void(0)">Skip</a>
                                        </div>
                                    </div>
                              
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
