
    <div class="loginSecItmTop">
        <a routerLink="/login">
            <img src="../assets/images/Logo.svg" alt="logo">
        </a>
    </div>
    <div class="login-left-bottom">
        <div class="vector-text">
            <div class="loginSecLeftImg">
                <img src="../assets/images/onBoarding/login-Illistation.svg" alt="createAccount" class="tabImg1" />
            </div>
            <div class="login-left-text">
                <h5 class="text-white">Videos are the future of advertising</h5>
                <p class="text-white text-sm">72% of users prefer video over any other form of
                    marketing. Connect with your customers through
                    engaging, industry-driven content that works.</p>
            </div>
        </div>
    </div>
