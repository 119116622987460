import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands-published-video',
  templateUrl: './brands-published-video.component.html',
  styleUrls: ['./brands-published-video.component.scss']
})
export class BrandsPublishedVideoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    jQuery(function () {
      jQuery('.showSingle').click(function () {
          jQuery('.targetDiv').hide('.cnt');
          jQuery('#div' + $(this).attr('target')).slideToggle();

      });
  });

  $(document).ready(function () {
      var submitIcon = $('.searchbox-icon');
      var inputBox = $('.searchbox-input');
      var searchBox = $('.searchbox');
      var isOpen = false;
      submitIcon.click(function () {
          if (isOpen == false) {
              searchBox.addClass('searchbox-open');
              inputBox.focus();
              isOpen = true;
          } else {
              searchBox.removeClass('searchbox-open');
              inputBox.focusout();
              isOpen = false;
          }
      });
      submitIcon.mouseup(function () {
          return false;
      });
      searchBox.mouseup(function () {
          return false;
      });
      $(document).mouseup(function () {
          if (isOpen == true) {
              $('.searchbox-icon').css('display', 'block');
              submitIcon.click();
          }
      });
  });`;
    document.body.appendChild(chatScript);
  }


}
