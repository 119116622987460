import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cSessionService } from './../../services/session.service';

@Component({
  selector: 'app-empty-reports',
  templateUrl: './empty-reports.component.html',
  styleUrls: ['./empty-reports.component.scss']
})
export class EmptyReportsComponent implements OnInit {

  constructor(private oSessionService : cSessionService, private oRouter : Router,) { }

  ngOnInit(): void {
  }
  EmptyReportsComponent_NavigateToCreateVideo()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigate(['wizard/overview']);
  }
}
