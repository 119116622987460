import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { cEditVideoService } from './../../services/edit-video.service';
import { cSessionService } from './../../services/session.service';

@Component({
  selector: 'app-edit-video-breadcrumb',
  templateUrl: './edit-video-breadcrumb.component.html',
  styleUrls: ['./edit-video-breadcrumb.component.scss']
})
export class EditVideoBreadcrumbComponent implements OnInit {
  showMenu : string;
  bDisplayTimelineBreadcrumb: boolean = false;
  @Output() bEmittedEditVideoLoader = new EventEmitter<boolean>();
  constructor(private oRouter : Router, private oSessionService : cSessionService,private oEditVideoService : cEditVideoService,) 
  { 
    this.showMenu = this.oRouter.url
  }

  ngOnInit(): void {
    if(this.oRouter.url === '/edit-video/timeline-view' || this.oRouter.url === '/wizard/preview' )
    {
      this.bDisplayTimelineBreadcrumb = true;
    }
  }
  ToggleEditVideoView(event)
  {
    if(event.target.checked)
    {
      this.oRouter.navigateByUrl('/edit-video/overview')
    }
    else{
      this.oRouter.navigateByUrl('/edit-video/timeline-view')
    }
  }
  EditVideoBreadcrumbComponent_NavigateToEditVideo()
  {
    this.bEmittedEditVideoLoader.emit(true);
    let sVideo_ID = this.oSessionService.SessionService_GetVideoId();
    let oVideo = this.oSessionService.SessionService_GetGeneratedVideo();
    console.log("Saved Video : ==> ", oVideo);
    this.oSessionService.cSessionService_SetOriginalVideoBlockConfig([]);
    this.oSessionService.cSessionService_SetVideoStatus('draft');
    this.oEditVideoService.EditVideoService_GetEditVideo(sVideo_ID);  
    this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
    this.oSessionService.cSessionService_SetOriginalVideoThumbUrl(oVideo);
  }
}
