import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid
} from 'ng-apexcharts';
export type socialMediaChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
};
@Component({
  selector: 'app-edit-video-sidepanels',
  templateUrl: './edit-video-sidepanels.component.html',
  styleUrls: ['./edit-video-sidepanels.component.scss']
})
export class EditVideoSidepanelsComponent implements OnInit {
    bDisplayOverviewSidepanel: boolean = false;
    @ViewChild("chart") chart2: ChartComponent = Object.create(null);
  public socialMediaChartOptions: Partial<socialMediaChartOptions>;
  constructor(private oRouter : Router) {
    this.socialMediaChartOptions = {
      series: [
        {
          name: 'Youtube',
          data: [30, 60, 20, 280, 140, 115, 350, 370, 250, 105, 200,]
        },
        {
          name: 'Facebook',
          data: [80, 140, 80, 100, 190, 205, 250, 170, 50, 205, 180,]
        },
        {
          name: 'Instagram',
          data: [10, 50, 30, 260, 120, 105, 150, 190, 210, 115, 150,]
        }
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '3',
      },
      colors: ['#FF0000', '#3b5998','#ca40cb'],
      legend: {
        show: false,
      },
      
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      yaxis: {
        
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },
      
    };
  }


  ngOnInit(): void {
      this.InitializeJquery()
      if(this.oRouter.url == '/wizard/preview')
      {
         this.bDisplayOverviewSidepanel = true
      }
}
InitializeJquery() {
  let chatScript = document.createElement("script");
  chatScript.type = "text/javascript";
  chatScript.async = true;
  chatScript.text = `$(document ).ready(function() {
    $("[data-sidebar]").click(function() {
      var sideBarId = $(this).attr("data-sidebar");
      $(".rightSideBar").removeClass("active");
      $(".sidebarOverlay").addClass("active");
      $(sideBarId).addClass("active");
    });
    
    
    $(".sidebarOverlay, .closeRightSideBar").click(function() {
      $(".rightSideBar").removeClass("active");
      $(".sidebarOverlay").removeClass("active");
      $("body").removeClass("sidbar_contentslide");
    });
    
    $("[data-sidebar2]").click(function() {
      var sideBarId = $(this).attr("data-sidebar2");
      $(".rightSideBarTutorialsVideo").removeClass("active");
      $(".sidebarOverlay2").addClass("active");
      $(sideBarId).addClass("active");
    });
    
    
    $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
      $(".rightSideBarTutorialsVideo").removeClass("active");
      $(".sidebarOverlay2").removeClass("active");
      $("body").removeClass("sidbar_contentslide");
    });
  
    // data-custom-select
    $("[data-custom-select]").click(function() {
      $("body").addClass("sidbar_contentslide");
    });
    // end data-custom-select
  
  
    $(".customAccordionAction").on("click", function(){
      $(".customAccordion").removeClass("active");
      $(this).parent(".customAccordion").addClass("active");
    });
  
  // dropdownBtn
    $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
      var dropVal = $(this).text();
      $(this).parent("li").siblings().children().removeClass("active");
      $(this).addClass("active");
      $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
  });
  // end dropdownBtn
  });
  
  $('.enable-loc').change(function () {
    if (this.checked)
        $('.enable-loc-div').fadeIn('slow');
    else
        $('.enable-loc-div').fadeOut('slow');
});

$("#sidebar-edit").mCustomScrollbar({
    theme: "minimal"
});`;
  document.body.appendChild(chatScript);
}

}

