import { Component, OnInit } from '@angular/core';
import { cSessionService } from './../../services/session.service';

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss']
})
export class BusinessProfileComponent implements OnInit {

  session: cSessionService;

  constructor(private oSessionService: cSessionService) {
    this.session = oSessionService;
  }

  ngOnInit(): void {
  }

}
