import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-breadcrumb',
  templateUrl: './reports-breadcrumb.component.html',
  styleUrls: ['./reports-breadcrumb.component.scss']
})
export class ReportsBreadcrumbComponent implements OnInit {
  showMenu : string
  constructor(private oRouter : Router) {
    this.showMenu = this.oRouter.url
   }

  ngOnInit(): void {
  }

}
