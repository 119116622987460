<div class="wave-line">
<!-- <div class="line1">
    <h1 *ngIf="false" class="text-center"  [@fadeInUpBig]="animationState" >Loading..</h1>
    <h1 *ngIf="false" class="text-center" [@fadeInUp]="animationState" >Welcome Username</h1>
</div> -->

<img src="../../../assets/images/Logo2.svg" 
*ngIf="animationState"
[@zoomInRightOnEnter]
[@zoomOutLeftOnLeave] height="80px">

<img src="../../../assets/images/Logo2.svg" 
*ngIf="animationState"
[@zoomInLeftOnEnter]
[@zoomOutRightOnLeave] height="80px">


 
     
    <h2     [@flash]="animState" 
    (@flash.done)="animState = !animState">Loading</h2>
 

</div>

