import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-video-look-feel',
  templateUrl: './edit-video-look-feel.component.html',
  styleUrls: ['./edit-video-look-feel.component.scss']
})
export class EditVideoLookFeelComponent implements OnInit {
  bDisplayAddInputField : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField()
  {
    this.bDisplayAddInputField = false;
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput(){
    this.bDisplayAddInputField = true;
   }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
    document.body.appendChild(chatScript);
  }

}
