<!-- <app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
    <form>
        <div class="container">
            <ul class="breadcrumb my-3">
                <li><a href="#">Business Profile</a></li>
                <li>Media</li>
            </ul>
            <div class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden" id="row-main">
                <app-business-sidebar
                    class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
                    id="sidebar-left"></app-business-sidebar>
                <div class="col-md-9 ps-0" id="content"> -->

                    <div class="my-4 ps-5 pe-3 pb-3 account-heading">
                        <div class="row">
                            <div class="col-md-6">
                                <h3 class="weight-700 text-gray-2">Media Libraries</h3>
                                <p class="mx-0 my-0 text-sm">Keep your business information up to date for the
                                    best results</p>
                            </div>
                            <div class="col-md-6 text-end">
                                    <button type="button"
                                        class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-2 float-end mt-1 text-xs"
                                        (click)="openMedia()">
                                        Add Media Library
                                    </button>

                                    <a href="javascript:void(0)" class="float-end filter-btn d-inline-block my-1 ms-2 me-4"
                                        data-sidebar="#filterAgencyUser">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.16667 1.49996H5C5.46024 1.49996 5.83333 1.87306 5.83333 2.33329V2.7494V2.74998V2.75056V3.16663C5.83333 3.62686 5.46024 3.99996 5 3.99996H4.16667C3.70643 3.99996 3.33333 3.62686 3.33333 3.16663V2.75189L3.33334 2.74996L3.33333 2.74803V2.33329C3.33333 1.87306 3.70643 1.49996 4.16667 1.49996ZM6.66667 2.33329V2.33331H12.9167C13.1468 2.33331 13.3333 2.51986 13.3333 2.74998C13.3333 2.9801 13.1468 3.16665 12.9167 3.16665H6.66667C6.66666 4.08711 5.92047 4.83329 5 4.83329H4.16667C3.24619 4.83329 2.5 4.0871 2.5 3.16663H0.416672C0.186553 3.16663 5.26508e-06 2.98008 5.26508e-06 2.74996C4.47035e-06 2.51984 0.186553 2.33329 0.416671 2.33329H2.5C2.5 1.41282 3.24619 0.666626 4.16667 0.666626H5C5.92047 0.666626 6.66667 1.41282 6.66667 2.33329ZM10.8333 8.99996C10.8333 9.92043 10.0871 10.6666 9.16667 10.6666H8.33333C7.41287 10.6666 6.66668 9.92044 6.66667 8.99998H0.416667C0.186548 8.99998 0 8.81343 0 8.58331C0 8.35319 0.186548 8.16665 0.416667 8.16665H6.66667V8.16663C6.66667 7.24615 7.41286 6.49996 8.33333 6.49996H9.16667C10.0871 6.49996 10.8333 7.24615 10.8333 8.16663H12.9167C13.1468 8.16663 13.3333 8.35317 13.3333 8.58329C13.3333 8.81341 13.1468 8.99996 12.9167 8.99996H10.8333ZM7.5 8.58331V8.99996C7.5 9.4602 7.8731 9.83329 8.33333 9.83329H9.16667C9.6269 9.83329 10 9.4602 10 8.99996V8.58531C10 8.58463 10 8.58396 10 8.58329C10 8.58262 10 8.58195 10 8.58128V8.16663C10 7.70639 9.6269 7.33329 9.16667 7.33329H8.33333C7.8731 7.33329 7.5 7.70639 7.5 8.16663V8.58331Z"
                                                fill="#404243"></path>
                                        </svg>
                                    </a>

                                    <div class="float-end searchbox-slide d-inline-block m-0 p-0">
                                        <div class="searchbox">
                                            <input type="search" placeholder="Search" name="search"
                                                class="searchbox-input" onkeyup="buttonUp();" required="">
                                            <input type="submit" class="searchbox-submit" value="GO">
                                            <span class="searchbox-icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                        fill="#A39FAA"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="accountSecContent pt-0 pe-4 pb-2">
                        <div class="data-table  m-0 ">
                            <div class="table-header row m-0">
                                <p class="text-lg text-gray-2 pb-0 mb-2 ps-4">
                                    Total Media Libraries {{lMediaLibraries.length}}
                                </p>
                                <!-- <div class="col-sm-5 col-md-5 col-lg-5 p-0">
                                    <div class="formItm search-icon">
                                        <input id="loginEmail" type="email" value="" placeholder="Email Address"
                                            required>
                                        <label for="loginEmail" class="text-sm">Find media library</label>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M0 7.27242C0 3.25597 3.25597 0 7.27242 0C11.2889 0 14.5448 3.25597 14.5448 7.27242C14.5448 9.11134 13.8623 10.7908 12.7368 12.0714L15.8623 15.1991C16.046 15.3829 16.0459 15.6809 15.862 15.8646C15.6782 16.0484 15.3802 16.0483 15.1965 15.8644L12.0712 12.737C10.7906 13.8624 9.11123 14.5448 7.27242 14.5448C3.25597 14.5448 0 11.2889 0 7.27242ZM7.27242 0.941228C3.7758 0.941228 0.941228 3.7758 0.941228 7.27242C0.941228 10.769 3.7758 13.6036 7.27242 13.6036C10.769 13.6036 13.6036 10.769 13.6036 7.27242C13.6036 3.7758 10.769 0.941228 7.27242 0.941228Z"
                                                fill="#A39FAA" />
                                        </svg>
                                    </div>
                                </div> -->
                                <div class="col-sm-7 col-md-7 col-lg-7 px-0 pt-3 text-end">
                                    <!-- <a *ngIf="!bDisplayUpgradedMediaBtn" href="loginSecurity.html"
                                                    class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-5"
                                                    data-bs-toggle="modal" data-bs-target="#subscription-popup">
                                                    Add Media Library</a> -->
                                    <!-- <a href="javascript:void(0)"
                                        class="btn btn-secondary btn-round-6 d-inline-block small-pad ms-5"
                                        data-sidebar="#addAgencyLibrary">
                                        Add Media Library</a> -->
                                </div>
                            </div>
                            <table class="table tableCustom table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" class="tableCheck">
                                            <div class="custom-switch">

                                                <input type="checkbox" class="check form-check-input" id="checkAll">
                                            </div>
                                        </th>
                                        <th scope="col">Library Name</th>
                                        <th scope="col">Items</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="tableAction">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let media of lMediaLibraries">
                                        <td scope="row" class="tableCheck">
                                            <div class="custom-switch">
                                                <input type="checkbox" class="check form-check-input">
                                            </div>
                                        </td>
                                        <td><a routerLink="/business-profile/media-library"
                                                (click)="UserMediaComponent_NavigateToMediaSection(media.name)">{{media.name}}</a>
                                        </td>
                                        <td>{{media.total_items}}</td>
                                        <td>{{media.status}}</td>
                                        <td class="tableAction">
                                            <a href="javascript:void(0)" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown">
                                                <svg width="24" height="6" viewBox="0 0 24 6" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M1.5 3C1.5 3.82843 2.17157 4.5 3 4.5C3.82843 4.5 4.5 3.82843 4.5 3C4.5 2.17157 3.82843 1.5 3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM10.5 3C10.5 3.82843 11.1716 4.5 12 4.5C12.8284 4.5 13.5 3.82843 13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3ZM12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0ZM21 4.5C20.1716 4.5 19.5 3.82843 19.5 3C19.5 2.17157 20.1716 1.5 21 1.5C21.8284 1.5 22.5 2.17157 22.5 3C22.5 3.82843 21.8284 4.5 21 4.5ZM18 3C18 1.34315 19.3431 0 21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3Z"
                                                        fill="#7A7A7A" />
                                                </svg>
                                            </a>
                                            <ul class="dropdown-menu add_location page-shadow"
                                                aria-labelledby="dropdownMenuButton1">
                                                <li (click)="UserMediaComponent_NavigateToMediaSection(media.name)">Edit
                                                </li>
                                                <li>Deactivate</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                <!-- </div>
            </div>
        </div>
    </form>
</section> -->

<!-- Modal pop-up Start-->
<div class="modal fade" id="subscription-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5>Are you sure you want to Unsubscribe to email updates.</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">You will no longer be sent email updates.</p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal">Cancel</a>
                <a href="#" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm">Unsubscribe</a>
            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->
<!-- <app-business-profile-sidepanels (oNewMediaLib)="UserMediaComponent_nIncomingMediaLibraries($event)">
</app-business-profile-sidepanels> -->
<!-- Modal pop-up Start-->
<div class="modal fade" id="subscription-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5>You have reached your limit to create new Media Libraries.</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4">You can upgrade your Subscription plan to create more Media
                    Libraries</p>
                <a href="javascript:void(0)" id="sidebarCollapse"
                    class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-sidebar="#selectplan"
                    data-bs-dismiss="modal">Upgrade</a>
                <a class="mt-3 text-gray-3 text-sm" data-bs-dismiss="modal">Cancel</a>
            </div>
        </div>
    </div>
</div>
<!-- Modal pop-up End-->
<div class="overlay sidebarOverlay"></div>

<!-- add library -->
<nz-drawer
[nzClosable]="false"
[nzVisible]="visibleMedia"
[nzPlacement]="placementMedia"
(nzOnClose)="closeMedia()"
 [nzWidth]="440"
>
<ng-container *nzDrawerContent>
<nav>
    <div class="sidebar-header">
        <h3 class="text-gray-2 weight-700">Add Library</h3>
        <div class="closeRightSideBar" (click)="closeMedia()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                    fill="#F2F2F5" />
                <path
                    d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                    fill="#A7ABB6" />
            </svg>

        </div>
    </div>
        <div class="sidebar-content pt-0 flex-grow-1 overflow-auto">
            <form [formGroup]="fAddLibraryForm">
            <div class="formItm">
                <input id="lib_name" type="text" required placeholder="Library Name" formControlName="lib_name"
                     required>
                <label for="lib_name" class="text-sm">Library Name</label>
                 <span *ngIf="this.fAddLibraryForm?.controls['lib_name']?.hasError('required') && fAddLibraryForm?.controls['lib_name']?.touched" class="error">Please enter your Library Name.</span>
               
            </div>
    
            <!-- <div>
                <label class="btnSwitch mb-3">
                    <input type="checkbox" />
                    <span class="btnSwitchCheck"></span>
                    <span class="btnSwitchText text-sm text-gray-2">Active</span>
                </label>
            </div> -->
            <div class="formItm">
                <input id="description" type="text" placeholder="Library Name" formControlName="description"
                     required>
                <label for="description" class="text-sm">Description</label>
                <span *ngIf="this.fAddLibraryForm?.controls['description']?.hasError('required') && fAddLibraryForm?.controls['description']?.touched" class="error">Please enter your description.</span>
               
            </div>
    
            <!-- <div class="formItm down_arrow pt-0">
                <p class="text-md text-gray-3 mb-0">Asset Source</p>
                <div class="dropdown dropdownSelect">
                    <button class="dropdown-toggle text-md " type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Cloud Data Bucket - Amazon
                    </button>
                    <ul class="dropdown-menu dropdownMenu">
                        <li class="selected"><a class="dropdown-item text-md" href="#;">Cloud Data Bucket - Amazon</a>
                        </li>
                    </ul>
                </div>
                <p class="text-primary-4 text-sm mb-0 mt-2">Configure Access</p>
            </div>
    
            <div class="formItm down_arrow pt-0">
                <p class="text-md text-gray-3 mb-0">Access</p>
                <div class="dropdown dropdownSelect">
                    <button class="dropdown-toggle text-md " type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Limited
                    </button>
                    <ul class="dropdown-menu dropdownMenu">
                        <li class="selected"><a class="dropdown-item text-md" href="#;">Limited</a></li>
                    </ul>
                </div>
            </div> -->
    
    
        </form>
    </div>
    <div class="side-footer side-content d-flex align-items-center">
        <button class=" btn btn-primary btn-round-6 d-inline-block small-pad px-4 closeSidepanel"  [disabled]="!this.fAddLibraryForm.valid" (click)="addMediaLib()">Create Library</button>
        <!-- <button class="d-inline-block small-pad text-gray-2 font-weight-noral text-md ms-3">
            Deactivate User
        </button> -->
    </div>

</nav> 


</ng-container>
 </nz-drawer>   
<!-- Add library sidbar end -->
