<!-- Localise existing videos Header -->
<header class="header header-1 drop-shadow" >
    <div class="container">
        <nav class="navbar navbar-expand-lg" style="padding-right: 50px;">
            <div class="d-flex w-100">
                <div class="col d-flex align-items-center">
                    <div class="back_cta">
                        <a href="javascript:void(0)" (click)="back()">
                            <img src="../assets/images/back-arrow.svg" alt="back-arrow">
                        </a>
                    </div>
                    <h3 class="text-gray-1 mb-0">Localize existing video</h3>
                 </div>
                <div class="col text-end">
                    <a class="text-gray-6 text-sm me-4" href="javascript:void(0)" (click)="Cancel()">Cancel</a>
                    <a class="btn btn-md btn-secondary btn-round-6 " (click)="GenerateVideo()" href="javascript:void(0)">Generate</a>
                </div>
            </div>

        </nav>
    </div>
</header>
 
<section class="accountSec main-body bg-gray-7 h-100 w-100 width-72">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6" >
                <app-create-video-breadcrumb [localiseExistingVideos]="true"></app-create-video-breadcrumb>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</section>


