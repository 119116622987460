import { Component, ElementRef,OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-ftue-looknfeel',
  templateUrl: './ftue-looknfeel.component.html',
  styleUrls: ['./ftue-looknfeel.component.scss']
})
export class FtueLooknfeelComponent implements OnInit {
  showMenu : string;
  @ViewChild('previewTemplate') previewTemplate: ElementRef;
  bDisplayAddInputField : boolean = false;
  fLooknFeelForm : FormGroup;
  // lTemplateCategory : any = [{name : "Candid",value : "Candid",checked : false},{name : "Bold",value : "Bold",checked : false},{name : "Fresh",value : "Fresh",checked : false}]
  lTemplateCategory : any = [];
  sSessionID : string = "";
  sUserEmail : string = "";
  sVideoId;
  oSavedFormData : any;
  inputValue : string = "";
  lKey_terms : any=[];
  lKeyTermsTags : any=[];
  oPrimaryColor : any;
  oFontColor :any ;
  sDisplayResponseErrorMessage : string;
  bDisplayErrorMessage : boolean = false;
  lSelectedTemplateCategory  : any = [];
  sSelectedTemplateThumbnailURL : string;
  sSelectedTemplatePreviewVideoURL : string;
  sTemplateChange : string;
  oUploadedLogo : any;
  nUploadedLogoSize : number = 0;
  sUploadedLogoName : string = "No file choosen.";
  bCreateVideoMode : boolean = true;
  bDisplayUploadLogoSection : boolean = true;
  oLogoDetails : any;
  bSendRequestInBackground : boolean = true;

  @ViewChild('AddInputTagElement') AddInputTagElement:ElementRef;
  constructor
  (
    private oWebSocketService: cWebSocketService, 
    private oSessionService : cSessionService,
    private oTemplateConfigService : cTemplateConfigService,
    private oFormBuilder : FormBuilder,
    private oRoutes : Router,
    private oDataService : cDataService,
    private oEditVideoService : cEditVideoService,
    private oRouter : Router
  ) 
  {
    this.showMenu = this.oRouter.url
    let bGenerateVideo = this.oSessionService.cSessionService_GetGeneratedVideoCheck();
    if(bGenerateVideo)
    {
      this.bCreateVideoMode=false;
    }
    else
    {
      this.bCreateVideoMode=true;
    }
    //get template names first
    this.lTemplateCategory = this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategoriesNames();
    // this.lKeyTermsTags = this.oSessionService.SessionService_GetKeyTermsTags();
    console.log("KeyTerms Tag ======+++===>>",this.lKeyTermsTags);
  }

  ngOnInit() 
  {
    this.CreateVideoLooknFeelComponent_InitializeJqueryCode();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.oWebSocketService.WebSocket_RegisterEvent("user_video_result", this.CreateVideoLooknFeelComponent_HandleOverviewResponse);
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.oLogoDetails = this.oSessionService.cSessionService_GetUserLogo();
    // console.log("logo details : ==>",this.oLogoDetails)
    this.oSavedFormData = this.oSessionService.cSessionService_GetLooknFeelForm();
    if(this.oSavedFormData)
    {
      this.CreateVideoLooknFeelComponent_SetLooknFeelForm();
    }
    else
    {
      this.CreateVideoLooknFeelComponent_InitializeLooknFeelForm();
    }
    setTimeout(() => {this.CreateVideoLooknFeelComponent_CheckForResetTextOverlays()}, 2500);

  }
  CreateVideoLooknFeelComponent_UpdateVideo(bUpdateVideo : boolean)
  {
    console.log("Update video ==> ", bUpdateVideo);
    if(bUpdateVideo)
    {
      this.oSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
      const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
      console.log("Updated Video Request : ==> ", oEditVideoForm);
      this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oEditVideoForm));
    }
  }
  PreviewTemplate()
  {
    this.previewTemplate.nativeElement.play(); 
  }
  ngOnDestroy()
  {
    this.oSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
    //if user switch this screen via tabs then send request as it is required field
    if(this.bSendRequestInBackground)
    {
      this.CreateVideoLooknFeelComponent_SendLooknFeelFormRequest(true);
    }
    this.oWebSocketService.WebSocket_Destroy();
  }
  CreateVideoLooknFeelComponent_SetLooknFeelForm()
  {
    this.fLooknFeelForm = this.oFormBuilder.group(
    {
      session_id: this.oSavedFormData.session_id,
      email: this.oSavedFormData.email,
      video_id : this.oSavedFormData.video_id,
      template_category: [this.oSavedFormData.template_category, [Validators.required]],
      key_terms: [this.oSavedFormData.key_terms],
      template_name: [this.oSavedFormData.template_name],
      color: [this.oSavedFormData.color],
      font_color : [this.oSavedFormData.font_color],
      oUploadedLogo : [this.oSavedFormData.oUploadedLogo],
      nUploadedLogoSize : [this.oSavedFormData.nUploadedLogoSize],
      bDisplayUploadLogoSection  : [this.oSavedFormData.bDisplayUploadLogoSection]
    });
    this.lKey_terms = this.oSavedFormData.key_terms;
    this.oPrimaryColor = this.oSavedFormData.color;
    this.oFontColor = this.oSavedFormData.font_color;
    // this.sTemplateChange=this.oSavedFormData.template_category;
    this.oUploadedLogo = this.oSavedFormData.oUploadedLogo;
    this.nUploadedLogoSize = this.oSavedFormData.nUploadedLogoSize;
    this.bDisplayUploadLogoSection = this.oSavedFormData.bDisplayUploadLogoSection;

    if(this.oSavedFormData.oUploadedLogo)
    {
      this.sUploadedLogoName = this.oSavedFormData.oUploadedLogo.filename;
    }
    this.CreateVideoLooknFeelComponent_LoadSelectedTemplateCategory(this.oSavedFormData.template_category,this.oSavedFormData.template_name);
  }

  CreateVideoLooknFeelComponent_InitializeLooknFeelForm()
  {
    this.fLooknFeelForm = this.oFormBuilder.group(
    {
      session_id: this.sSessionID,
      email: this.sUserEmail,
      video_id : this.sVideoId,
      template_category : [this.lTemplateCategory[0]?this.lTemplateCategory[0]:'Sales/Events',[Validators.required]],
      key_terms : [this.lKeyTermsTags],
      template_name : [''],
      color : [''],
      font_color : [''],
      oUploadedLogo : [''],
      nUploadedLogoSize : [''],
      sUploadedLogoName : [this.sUploadedLogoName],
      bDisplayUploadLogoSection  : [this.bDisplayUploadLogoSection]
    });
    // this.sTemplateChange="Bold";
    let lRemoveDuplicates = new Set(this.lKeyTermsTags);
    this.lKey_terms = [...lRemoveDuplicates];
    this.CreateVideoLooknFeelComponent_SelectTemplateCategory();
  }

  CreateVideoLooknFeelComponent_LoadSelectedTemplate(oIncommingTemplate)
  {
    this.sSelectedTemplateThumbnailURL = oIncommingTemplate.sThumbnail;
    this.sSelectedTemplatePreviewVideoURL = oIncommingTemplate.sURL;
    this.fLooknFeelForm.patchValue({template_name : oIncommingTemplate.sName});
  }
  //Reset text overlays
  CreateVideoLooknFeelComponent_CheckForResetTextOverlays()
  {
    this.fLooknFeelForm.get('template_name').valueChanges.subscribe(template =>
    {
      console.log("Template changed : ==> ", template);
      let lsLooknFeelForm = this.oSessionService.cSessionService_GetLooknFeelForm();
      if(lsLooknFeelForm)
      {
        let lsContentForm = this.oSessionService.cSessionService_GetContentForm();
        if(lsContentForm)
        {
          this.oSessionService.cSessionService_SetResetTextOverlayCheck(true);
          const nSelectedDuraction  = this.oSessionService.cSessionService_GetOverviewForm().duration;
          this.oTemplateConfigService.TemplateConfigService_UpdateTemplateBlocks(lsContentForm.lBlockConfig,nSelectedDuraction,template);
          console.log("CreateVideoLooknFeelComponent_CheckForResetTextOverlays : Reset textOverlays ==> ",true);
        }
      }
    });
  }
  CreateVideoLooknFeelComponent_SelectTemplateCategory()
  {
    let sSelectedTemplateCategory = this.fLooknFeelForm.get('template_category').value; 
    let oTemplateCategories = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration()).template_categories;
    this.fLooknFeelForm.patchValue({template_name :oTemplateCategories[sSelectedTemplateCategory][0].template_name});
    // get thumbnails
    this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategories(this.fLooknFeelForm.get('template_category').value).then(result=>
    {
      this.lSelectedTemplateCategory=result;
      console.log("CreateVideoLooknFeelComponent_SelectTemplateCategory : SELECTED TEMPLATE ==> ",this.lSelectedTemplateCategory);
      // by default first template name will be selected
      this.CreateVideoLooknFeelComponent_LoadSelectedTemplate(this.lSelectedTemplateCategory[0]);
    });
    var element = document.getElementById("TemplateThumbnail");
    element.classList.remove("videoWrapperActive");
  }
  CreateVideoLooknFeelComponent_LoadSelectedTemplateCategory(sIncommingtemplateCategory : string, sIncommingTemplateName : string)
  {
    this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategories(sIncommingtemplateCategory).then(result=>
      {
        this.lSelectedTemplateCategory=result;
        console.log("CreateVideoLooknFeelComponent_SelectTemplateCategory : SELECTED TEMPLATE ==> ",this.lSelectedTemplateCategory);
        let nSelectedTemplateNameIndex : number = this.lSelectedTemplateCategory.findIndex(template=>template.sName === sIncommingTemplateName);
        if(nSelectedTemplateNameIndex==-1)nSelectedTemplateNameIndex=0;
        // console.log("Selected template name index : ==> ", nSelectedTemplateNameIndex);
        this.sSelectedTemplateThumbnailURL = this.lSelectedTemplateCategory[nSelectedTemplateNameIndex].sThumbnail;
        this.sSelectedTemplatePreviewVideoURL = this.lSelectedTemplateCategory[nSelectedTemplateNameIndex].sURL;
      });
      var element = document.getElementById("TemplateThumbnail");
      element.classList.remove("videoWrapperActive");
  }
  CreateVideoLooknFeelComponent_SendLooknFeelFormRequest(bIncommingSendRequestInBackground  : boolean)
  {
    // this.CreateVideoLooknFeelComponent_CheckForResetTextOverlays();
    this.bSendRequestInBackground = bIncommingSendRequestInBackground;

      let oLooknFeelPayload = 
      {
        session_id: this.sSessionID,
        email: this.sUserEmail,
        video_id : this.sVideoId,
        template_category : this.fLooknFeelForm.get('template_category').value,
        key_terms : this.fLooknFeelForm.get('key_terms').value,
        template_name : this.fLooknFeelForm.get('template_name').value,
        color : this.fLooknFeelForm.get('color').value,
        font_color : this.fLooknFeelForm.get('font_color').value,
        logo_img : this.CreateVideoLooknFeelComponent_ReturnLogo(),
      }
      // if(this.fLooknFeelForm.get('key_terms').value == null )
      // {
      //   oLooknFeelPayload.key_terms= [];
      // }
      console.log("CreateVideoLooknFeelComponent_SendLooknFeelFormRequest : LooknFeel Request => ",oLooknFeelPayload);
      this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oLooknFeelPayload));
  }
  CreateVideoLooknFeelComponent_HandleOverviewResponse = (InComingMessage) =>
  {
    if (JSON.parse(InComingMessage)["errorMessage"])
    {
      this.sDisplayResponseErrorMessage = InComingMessage;
      console.log("CreateVideoOverviewComponent_HandleLooknFeelComponentResponse : Request Error ==>",InComingMessage)
      this.bDisplayErrorMessage = true;
      setTimeout(()=>{this.bDisplayErrorMessage = false},5000);
    }
    else 
    { 
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
      console.log("CreateVideoOverviewComponent_HandleLooknFeelComponentResponse : Request result response ==> ",oResponse);
      if(this.bCreateVideoMode)
      {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(false);
        this.oRoutes.navigateByUrl('/ftue-content');
      }
      else
      {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oRoutes.navigateByUrl('/ftue-overview');
      }
    }
  }
  CreateVideoLooknFeelComponent_FocusoutFromInput()
  {
    if(this.inputValue!=="")
    {
      this.lKey_terms.push(this.inputValue);
      this.fLooknFeelForm.patchValue({ key_terms : this.lKey_terms});
    }
    setTimeout(() => {this.bDisplayAddInputField = false;}, 100);
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField()
  {
      console.log("Key Term ===>>>",this.inputValue);
      this.bDisplayAddInputField = false;
      this.lKey_terms.push(this.inputValue);
      this.fLooknFeelForm.patchValue({ key_terms : this.lKey_terms});
      this.inputValue = "";
      console.log("key ",this.lKey_terms);  
  }
  CreateVideoLooknFeelComponent_EnterKeyTermsTag(keycode)
  {
    if(keycode == 13 && this.inputValue != "" || keycode == 188 && this.inputValue != "")
    {
      this.CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInputField();
    }
  }
  CreateVideoLooknFeelComponent_RemoveTagListElements(index)
  {
    this.lKey_terms.splice(index, 1)
  }
  CreateVideoLooknFeelComponent_ToggleTagButtonAndAddInput()
  {
    this.inputValue = "";
    setTimeout(() => this.AddInputTagElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  CreateVideoLooknFeelComponent_GetInputValue(event)
  {
    this.inputValue = event.target.value;
    console.log("Input Value ==>",this.inputValue);
  }
  async CreateVideoLooknFeelComponent_UploadedLogoFile(event) 
  {
    let file = event.target.files[0];
    this.nUploadedLogoSize = file.size;
    console.log("CreateVideoLooknFeelComponent_UploadFile : File To Upload ==> ", file);
    var oFormData: any = new FormData();
    oFormData.append('session_id',this.sSessionID);
    oFormData.append('email',this.sUserEmail);
    oFormData.append('file',file);
    oFormData.append("logo", true);

    try 
      {
        await this.oDataService.DataService_PostUploadedVisuals(oFormData).subscribe((result : any) =>
        {
        console.log("CreateVideoLooknFeelComponent_UploadedLogoFile : Response ==>", result);
        this.oUploadedLogo = result;
        this.sUploadedLogoName = result.filename;
        this.fLooknFeelForm.patchValue({oUploadedLogo : this.oUploadedLogo, nUploadedLogoSize : this.nUploadedLogoSize});
        },
        (error)=>
        {
          console.error("CreateVideoLooknFeelComponent_UploadedLogoFile : Error ==>", error);
          this.oUploadedLogo=null;
          this.sDisplayResponseErrorMessage = error.error.errorMessage;
          this.bDisplayErrorMessage = true;
          setTimeout(()=>{this.bDisplayErrorMessage = false;},5000);
        });
      } 
      catch (error) 
      {
        console.error("CreateVideoLooknFeelComponent_UploadedLogoFile : ERROR ==>", error);
      }
  }
  CreateVideoLooknFeelComponent_GetPrimaryColor(event)
  {
    const primaryColor = event.target.value;
    console.log("Primary Color ==>", primaryColor);
    this.fLooknFeelForm.patchValue({ color : primaryColor})
    this.oPrimaryColor = primaryColor;
  }
  CreateVideoLooknFeelComponent_GetFontColor(event)
  {
    const fontColor = event.target.value;
    console.log("Font Color ==>", fontColor);
    this.fLooknFeelForm.patchValue({font_color : fontColor})
    this.oFontColor = fontColor;
  }
  CreateVideoLooknFeelComponent_ReturnFileSize(bytes, decimals = 2)
  {
    if (bytes === 0)
    {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  CreateVideoLooknFeelComponent_ToggleView(event)
  {
    if(event.target.checked)
    {
      this.oRoutes.navigateByUrl('/wizard/overview');
    }
    else 
    {
      this.oRoutes.navigateByUrl('edit-video/timeline-view');
    }
  } 
  CreateVideoLooknFeelComponent_ToggleLogoSection(event : any)
  {
    let value = event.target.value;
    if(value==="upload-logo")
    {
      this.bDisplayUploadLogoSection=true;
    }
    else
    {
      this.bDisplayUploadLogoSection=false;
    }
    this.fLooknFeelForm.patchValue({bDisplayUploadLogoSection : this.bDisplayUploadLogoSection});
  }
  CreateVideoLooknFeelComponent_ReturnLogo()
  {
    let logo_path;
    if(this.bDisplayUploadLogoSection)
    {
      logo_path = this.oUploadedLogo?this.fLooknFeelForm.get('oUploadedLogo').value.fpath:'';
    }
    else
    {
      logo_path = this.oLogoDetails?this.oLogoDetails.fpath:'';
    }
    return logo_path;
  }
  FTUELooknFeelComponent_NavigateToLogin()
  {
    localStorage.clear();
    this.oRouter.navigateByUrl('/ftue-login');
  }
  CreateVideoLooknFeelComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    $(document).ready(function() {
      $(window).keydown(function(event){
        if(event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });
    });
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
    document.body.appendChild(chatScript);
  }

}
