import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cSessionService } from './../../services/session.service';
import { cWebSocketService } from './../../services/websocket.service';
@Component({
  selector: 'app-business-profile-sidepanels',
  templateUrl: './business-profile-sidepanels.component.html',
  styleUrls: ['./business-profile-sidepanels.component.scss']
})
export class BusinessProfileSidepanelsComponent implements OnInit {
  sSessionId: string;
  sUserEmail: string;
  fAddLibraryForm: FormGroup;
  lMediaLibraries: any = [];
  @Output() oNewMediaLib = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  constructor(private oWebSocketService: cWebSocketService, private oSessionService: cSessionService, private oFormBuilder: FormBuilder) { }

  ngOnInit(): void 
  {
    // setTimeout(() => {this.BusinessProfileSidepanelsComponent_InitializeJqueryCode()}, 5000);
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.fAddLibraryForm = this.oFormBuilder.group
    ({
      email: this.sUserEmail,
      session_id: this.sSessionId,
      lib_name: ['', Validators.required],
      user_roles : [['creator'], Validators.required],
      description: ['', Validators.required]
    });

  //initialize jquery
  // this.oMediaService.bDisplaySidePanel.subscribe(data=>
  // {
  //   console.log(`Display Side Panel : ==> ${data}`);
  //   if(data==1)
  //   {
  //     let screipt = document.getElementById('media_sidepanel');
  //     console.log(screipt);
  //     if(screipt)
  //     {
  //       screipt.remove();
  //       setTimeout(() => {this.BusinessProfileSidepanelsComponent_InitializeJqueryCode()}, 2000);
  //     }
  //   }
  // });
  }
  BusinessProfileSidePanelsComponent_SendCreateLibraryRequest()
  {
  this.closeMedia();
    
    if(this.fAddLibraryForm.valid)
    {
      this.oWebSocketService.WebSocket_RegisterEvent("add_user_media_lib_result", this.BusinessProfileSidePanelsComponent_HandleAddMediaLibraryResponse);
      console.log("BusinessProfileSidePanelsComponent_HandleCreateLibraryRequest : Add Media Library Request to WebSocket ==> ", this.fAddLibraryForm.value);
      this.oWebSocketService.WebSocket_SendMessage("add_user_media_lib_request", JSON.stringify(this.fAddLibraryForm.value));
    }
  }
  BusinessProfileSidePanelsComponent_HandleAddMediaLibraryResponse = (InComingMessage) => 
  {
    console.log("BusinessProfileSidePanelsComponent_HandleAddMediaLibraryResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    console.log("Result ==>",oFormatedIncommingMessage);
    // this.oNewMediaLib.emit(oFormatedIncommingMessage);
    this.oWebSocketService.WebSocket_RegisterEvent('user_media_libs_result', this.BusinessProfileSidePanelsComponent_HandleMediaLibraryListResponse);
    this.oWebSocketService.WebSocket_SendMessage('user_media_libs_request', JSON.stringify({email : this.sUserEmail,session_id : this.sSessionId}));
  }
  BusinessProfileSidePanelsComponent_HandleMediaLibraryListResponse = (IncomingMessage) =>
  {
    console.log("BusinessProfileSidePanelsComponent_HandleMediaLibraryListResponse: IncomingMessage",JSON.parse(IncomingMessage));
    
    if(JSON.parse(IncomingMessage).errorMessage)
    {
      console.error("BusinessProfileSidePanelsComponent_HandleMediaLibraryListResponse : Error ==> ", JSON.parse(IncomingMessage).errorMessage);
    }
    else
    {
      let oFormatedIncommingMessage =  JSON.parse(IncomingMessage);
     this.lMediaLibraries.push(oFormatedIncommingMessage.media_libs);
     this.oNewMediaLib.emit(this.lMediaLibraries);
    }  
  }

 /*  BusinessProfileSidepanelsComponent_InitializeJqueryCode() 
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.id = "media_sidepanel";
    chatScript.text = `
    $(document).ready(function(){
      var submitIcon = $('.searchbox-icon');
      var inputBox = $('.searchbox-input');
      var searchBox = $('.searchbox');
      var isOpen = false;
      submitIcon.click(function(){
          if(isOpen == false){
              searchBox.addClass('searchbox-open');
              inputBox.focus();
              isOpen = true;
          } else {
              searchBox.removeClass('searchbox-open');
              inputBox.focusout();
              isOpen = false;
          }
      });  
       submitIcon.mouseup(function(){
              return false;
          });
      searchBox.mouseup(function(){
              return false;
          });
      $(document).mouseup(function(){
              if(isOpen == true){
                  $('.searchbox-icon').css('display','block');
                  submitIcon.click();
              }
          });
    });
    $("#checkAll").click(function () {
      $(".check").prop('checked', $(this).prop('checked'));
  });
  
  $("[data-sidebar]").click(function() {
    var sideBarId = $(this).attr("data-sidebar");
    $(".rightSideBar").removeClass("active");
    $(".sidebarOverlay").addClass("active");
    $(sideBarId).addClass("active");
  });
  
  
  $(".sidebarOverlay, .closeRightSideBar .closeSidepanel").click(function() {
    $(".rightSideBar").removeClass("active");
    $(".sidebarOverlay").removeClass("active");
    $("body").removeClass("sidbar_contentslide");
  });
  
  $("[data-sidebar2]").click(function() {
    var sideBarId = $(this).attr("data-sidebar2");
    $(".rightSideBarTutorialsVideo").removeClass("active");
    $(".sidebarOverlay2").addClass("active");
    $(sideBarId).addClass("active");
  });
  
  
  $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
    $(".rightSideBarTutorialsVideo").removeClass("active");
    $(".sidebarOverlay2").removeClass("active");
    $("body").removeClass("sidbar_contentslide");
  });

  // data-custom-select
  $("[data-custom-select]").click(function() {
    $("body").addClass("sidbar_contentslide");
  });
  // end data-custom-select


  $(".customAccordionAction").on("click", function(){
    $(".customAccordion").removeClass("active");
    $(this).parent(".customAccordion").addClass("active");
  });

// dropdownBtn
  $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
    var dropVal = $(this).text();
    $(this).parent("li").siblings().children().removeClass("active");
    $(this).addClass("active");
    $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
});
// end dropdownBtn`;
    document.body.appendChild(chatScript);
    console.log("JQUERY INITIALIZED");

  } */

 
  closeMedia(): void {

this.onClose.emit();
    //this.visibleMedia = false;
  }
}
