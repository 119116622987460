import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss']
})
export class CommentSectionComponent implements OnInit {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  bDisplayProntoShare: boolean = false;
  bDisplayProntoDownload: boolean = true;
  sVideoTitle : string = "";
  sVideoDuration : string = "";
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  oGeneratedVideo : any;
  oPublicUrl: string;
  fCommentsForm: FormGroup;

  constructor(private oSessionService : cSessionService,private oFormBuilder: FormBuilder, private oWebSocketService: cWebSocketService,  private oRouter : Router, private oTemplateConfigService : cTemplateConfigService ) { }

  ngOnInit(): void 
  {
    this.CommentSectionComponent_InitializeCommentForm()
    this.sVideoTitle = this.oSessionService.SessionService_GetVideoTitle();
    this.sVideoDuration = this.oSessionService.SessionService_GetVideoDuration();
    this.oGeneratedVideo = this.oSessionService.SessionService_GetGeneratedVideo();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.oPublicUrl = this.oSessionService.cSessionService_GetPublicURL();
  }
  CommentSectionComponent_InitializeCommentForm()
  {
    this.fCommentsForm = this.oFormBuilder.group
    ({
      video_id: this.oSessionService.SessionService_GetVideoId(),
      comment: ''
    });
  }
  CommentSectionComponent_AddCommentRequest()
  {
    this.oWebSocketService.WebSocket_RegisterEvent("add_video_comment_result", this.CommentSectionComponent_HandleAddCommentRequestResponse);
    console.log("CommentSectionComponent_AddCommentRequest : Add Media Library Request to WebSocket ==> ", this.fCommentsForm.value);
    this.oWebSocketService.WebSocket_SendMessage("add_video_comment_request", JSON.stringify(this.fCommentsForm.value));
  }
  CommentSectionComponent_HandleAddCommentRequestResponse = (InComingMessage) => 
  {
  console.log("CommentSectionComponent_HandleAddCommentRequestResponse : ==> ", InComingMessage);
  let oFormatedIncommingMessage = JSON.parse(InComingMessage);
  console.log("Result ==>",oFormatedIncommingMessage);
  }
  TimelineViewComponent_CopyToClipBoard() 
  {
    navigator.clipboard.writeText(this.oPublicUrl);
    console.log(navigator.clipboard.writeText(this.oPublicUrl))
  }

  TimelineViewComponent_PlayVideo()
  {
    this.videoPreview.nativeElement.play();
  }
  TimelineViewComponent_ResetVideo()
  {
    this.videoPreview.nativeElement.load();
    var element = document.getElementById("TemplateThumbnail");
    element.classList.remove("videoWrapperActive");
  }
}
