import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands-business-details',
  templateUrl: './brands-business-details.component.html',
  styleUrls: ['./brands-business-details.component.scss']
})
export class BrandsBusinessDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });`;
    document.body.appendChild(chatScript);
  }

}
