import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }


  public loading :boolean = false;

 public showLoader()
  {
setTimeout(() => {
  this.loading = true;
}, 0);
  }

  public hideLoader()
  {
setTimeout(() => {
  this.loading = false;
}, 0);
  }
}
