import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'secondsToTime'})
export class SecondsToTime implements PipeTransform {

  toTime(seconds = 0) {

    var date = new Date(null);
    date.setSeconds(seconds);

    if(seconds < 36000){
      return date.toISOString().substring(14, 19)
    } else {
      return date.toISOString().substring(11, 19)
    }
 }

  transform(value: any): string {
    let numb = Number(value);
    return this.toTime(numb);
  }
}