import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as io from 'socket.io-client';
import {environment} from '../../environments/environment';


@Injectable({
              providedIn: 'root',
            })

export class cWebSocketService
{
  fpWebSocket_HandleConnectionOpenCallback: any;
  fpWebSocket_HandleConnectionCloseCallback: any;
  fpWebSocket_HandleSocketErrorCallback: any;
  bWebSocketCreated: boolean = false;
  oSocket: any;
  Loginform: FormGroup;
  oFormGroupKeepAliveMsg: FormGroup;
  sServerSocketURL: any;
  nSendKeepAliveMsgTimeInSeconds: number = 0;
  bWebSocketConnected: boolean = false;
  bKeepAliveTimerIsActive: boolean = false;
  hSendKeepAliveMessageOnWebSocketTimer: any = 0;
  resultForLogin: any;
  isResponse: boolean = false;
  hideGoogle: boolean = false;
  hidePassword: boolean = false;
  emailValue: any;
  emailLink: string;
  emailRejex: string;


  constructor(private oFormBuilder: FormBuilder)
  {
    this.sServerSocketURL = environment.API_HOST;
    const emilRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$)/;
  }

  WebSocket_Create(sServerWebSocketURL, WebSocket_HandleConnectionOpenCallback, WebSocket_HandleConnectionCloseCallback, WebSocket_HandleSocketErrorCallback)
  {
    //const Options = {rememberUpgrade: true, transports: ['http'], secure: true, rejectUnauthorized: false};
    //const Options = {reconnect: true, path: ''};


   // Options.path = environment.WS_PATH;

    //this.oSocket = io(sServerWebSocketURL);
    //this.oSocket = io.connect(sServerWebSocketURL);
    //this.oSocket = io.connect(environment.WS_HOST);
   // this.oSocket = io.connect(environment.WS_HOST, Options);

   // this.fpWebSocket_HandleConnectionOpenCallback = WebSocket_HandleConnectionOpenCallback;
   // this.fpWebSocket_HandleConnectionCloseCallback = WebSocket_HandleConnectionCloseCallback;
   // this.fpWebSocket_HandleSocketErrorCallback = WebSocket_HandleSocketErrorCallback;

   // this.WebSocket_HandleConnectionOpen();

    //this.bWebSocketCreated = true;
    // console.log('WebSocket_Create: WebSocket Connection InProgress at ', environment.WS_HOST);
  }

  WebSocket_SendMessage(sOutGoingMessageType, OutGoingMessage)
  {
    console.log("WebSocket_SendMessage: Send Message {%s} => ", sOutGoingMessageType, OutGoingMessage);
   // this.oSocket.emit(sOutGoingMessageType, OutGoingMessage);

  }

  WebSocket_HandleConnectionOpen()
  {
    //
    // Called when connection is opened
    // console.log('WebSocket_HandleConnectionOpen: WebSocket Connected Successfully');
    //this.fpWebSocket_HandleConnectionOpenCallback();
  }

  WebSocket_HandleConnectionClose()
  {
    //
    // Called when connection is closed (for whatever reason)
    console.log('WebSocket_HandleConnectionClose: Socket Closed');
   // this.fpWebSocket_HandleConnectionCloseCallback();
  }

  WebSocket_RegisterEvent(sInComingMessageType, WebSocket_HandleInComingMessageCallback)
  {

    // this.WebSocket_Create(this.sServerSocketURL, this.WebSocket_HandleConnectionOpenOnWebSocket, this.WebSocket_HandleConnectionCloseOnWebSocket, this.WebSocket_HandleSocketErrorOnWebSocket);
   // this.oSocket.on(sInComingMessageType, WebSocket_HandleInComingMessageCallback);
  }

  WebSocket_UnRegisterEvent(sInComingMessageType, WebSocket_HandleInComingMessageCallback)
  {
    //
   // this.oSocket.off(sInComingMessageType, WebSocket_HandleInComingMessageCallback);
  }

  WebSocket_HandleSocketError(SocketError)
  {
    //
    // Called if WebSocket API signals some kind of error
    console.log('WebSocket_HandleSocketError: Socket Error: ' + SocketError);
  //  this.fpWebSocket_HandleSocketErrorCallback(SocketError);
  }

  WebSocket_Destroy()
  {
    //
    // console.log('WebSocket_Destroy: Socket Teardown InProgress');

   // if(this.bWebSocketCreated === true)
  //  {
   //   this.bWebSocketCreated = false;
   //   this.oSocket.disconnect();
   // }

    // console.log('WebSocket_Destroy: WebSocket Destroyed Successfully');
  }

  WebSocket_CreateWebSocket()
  {
    // console.log('WebSocket_CreateWebSocket: Call WebSocket_Create');
   // this.WebSocket_Create(this.sServerSocketURL, this.WebSocket_HandleConnectionOpenOnWebSocket, this.WebSocket_HandleConnectionCloseOnWebSocket, this.WebSocket_HandleSocketErrorOnWebSocket);
  }

  WebSocket_HandleConnectionOpenOnWebSocket = () =>
  {
    console.log('WebSocket_HandleConnectionOpenOnWebSocket: WebSocket Connected Successfully');
   // this.bWebSocketConnected = true;
   // this.bKeepAliveTimerIsActive = true;

   // this.WebSocket_SendKeepAliveMessageOnWebSocket();
  };
  WebSocket_HandleConnectionCloseOnWebSocket = () =>
  {
    console.log('WebSocket_HandleConnectionCloseOnWebSocket: Socket Closed');
    //this.bWebSocketConnected = false;
   // if(this.bKeepAliveTimerIsActive === true)
   // {
    //  clearTimeout(this.hSendKeepAliveMessageOnWebSocketTimer);
    //  this.hSendKeepAliveMessageOnWebSocketTimer = null;
    //  this.bKeepAliveTimerIsActive = false;
   // }
   // this.bWebSocketCreated = false;
   // this.WebSocket_CreateWebSocket();
  };
 /* WebSocket_HandleSocketErrorOnWebSocket = (SocketError) =>
  {
   console.log('WebSocket_HandleSocketErrorOnWebSocket: Socket Error: ' + SocketError);
    this.bWebSocketConnected = false;
    if(this.bKeepAliveTimerIsActive === true)
    {
      clearTimeout(this.hSendKeepAliveMessageOnWebSocketTimer);
      this.hSendKeepAliveMessageOnWebSocketTimer = null;
      this.bKeepAliveTimerIsActive = false;
    }
    this.bWebSocketCreated = false;
    this.WebSocket_CreateWebSocket();
  };
  WebSocket_SendKeepAliveMessageOnWebSocket = () =>
  {
    this.oFormGroupKeepAliveMsg = this.oFormBuilder.group({
                                                            message: ('keepalive'), session_id: (''), is_guest_session: false
                                                          });

    this.nSendKeepAliveMsgTimeInSeconds = 30;
    const csKeepAliveMsg = JSON.stringify(this.oFormGroupKeepAliveMsg.value);

    if(this.bKeepAliveTimerIsActive === true)
    {
      this.hSendKeepAliveMessageOnWebSocketTimer = setTimeout(this.WebSocket_SendKeepAliveMessageOnWebSocket, (this.nSendKeepAliveMsgTimeInSeconds * 1000));
    }
  };
*/
}
