import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid,
  ApexPlotOptions,
  ApexNonAxisChartSeries,
  ApexStroke
} from 'ng-apexcharts';
import { cSessionService } from './../../../services/session.service';

export type socialMediaChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
};
export interface ageChartOptions {
	series: ApexNonAxisChartSeries;
	chart: ApexChart;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	legends: ApexLegend;
	labels: any;
	name: any;
	tooltip: ApexTooltip;
	colors: string[];
	plotOptions: ApexPlotOptions
}
export type genderChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
  plotOptions: ApexPlotOptions;
};
export type locationsChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-reports-audience',
  templateUrl: './reports-audience.component.html',
  styleUrls: ['./reports-audience.component.scss']
})
export class ReportsAudienceComponent implements OnInit {
  @ViewChild("chart") chart2: ChartComponent = Object.create(null);
  public socialMediaChartOptions: Partial<socialMediaChartOptions>;
	public ageChartOptions: Partial<ageChartOptions>;
  public genderChartOptions: Partial<genderChartOptions>;
  public locationsChartOptions: Partial<locationsChartOptions>;
  sUserName : string = "";


  constructor(private oSessionService : cSessionService) {
    this.socialMediaChartOptions = {
      series: [
        {
          name: 'Views',
          data: [30, 60, 20, 280, 140, 115, 350, 370, 250, 105, 200,]
        },
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '3',
      },
      colors: ['#ff6a46'],
      legend: {
        show: false,
      },
      
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      yaxis: {
        
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },
      
    };
    this.ageChartOptions = {
			series: [10, 45, 20, 25, 10 , 45 , 20 ],
			chart: {
				fontFamily: 'Montserrat,sans-serif',
				type: 'donut',
				height: 240
			},
			plotOptions: {
				pie: {
					donut: {
						size: '70px',
					}
				}
			},
			tooltip: {
				fillSeriesColor: false,
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 0,
			},
			legends: {
				show: false,
			},
			labels: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-65', '65+'],
			colors: ['#f6502c', '#f53c14', '#cd2c09', '#b42608' ,'#9c2107' ,'#831c06', '#6b1705'],
		};
    this.genderChartOptions = {
      series: [
        {
          name: 'Gender',
          data: [30, 80]
        },
       
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 200,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal:true,
          columnWidth: '40%',
          barHeight: '40%',
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '0',
      },
      colors: ['#483d8f','#483d8f'],
      legend: {
        show: false,
      },
      grid: {
        show: false,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Female',
          'Male',
          
        ],
        labels: {
          style: {
            colors: '#000'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#000000'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      }
    };
    this.locationsChartOptions = {
      series: [
        {
          name: 'Viewers',
          data: [30, 80, 50]
        },
       
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 200,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal:true,
          columnWidth: '40%',
          barHeight: '40%',
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '0',
      },
      colors: ['#483d8f','#483d8f','#483d8f'],
      legend: {
        show: false,
      },
      grid: {
        show: false,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Pakistan',
          'USA',
          'India',
          
        ],
        labels: {
          style: {
            colors: '#000'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#000000'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      }
    };
  }

  ngOnInit(): void {
    this.sUserName = this.oSessionService.cSessionService_GetUsername();
  }

}
