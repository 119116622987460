import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-locale',
  templateUrl: './preview-locale.component.html',
  styleUrls: ['./preview-locale.component.scss']
})
export class PreviewLocaleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
