import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-ftue-login',
  templateUrl: './ftue-login.component.html',
  styleUrls: ['./ftue-login.component.scss']
})
export class FtueLoginComponent implements OnInit {
  fGuestLoginForm : FormGroup;
  sUserEmail: any;
  sSessionId: any;
  lTemplateCategory : any = [];
  lTemplateNames : any = [];
  constructor(private oRouter : Router,private oFromBuilder : FormBuilder,private oWebSocketService : cWebSocketService,private oSessionService : cSessionService, private oTemplateConfigService : cTemplateConfigService) { }

  ngOnInit() {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.FtueComponent_FtueLoginFormInitialize();
    this.oSessionService.SessionService_SetVideoTimeout();
  }
  FtueComponent_FtueLoginFormInitialize()
  {
    const sEmailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$)/;
    this.fGuestLoginForm = this.oFromBuilder.group({
     email : [null, [Validators.required, Validators.pattern(sEmailRejex)]],
    })
  }
  FtueComponent_SendFtueloginRequest()
  {
    this.oWebSocketService.WebSocket_RegisterEvent("create_guest_result", this.FtueComponent_GenerateFtueLoginResponse);
    this.oWebSocketService.WebSocket_SendMessage("create_guest_request", JSON.stringify(this.fGuestLoginForm.value));
  }
  FtueComponent_GenerateFtueLoginResponse = (InComingMessage) => 
  {
    console.log("FtueComponent_GenerateFtueLoginResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.log("FtueComponent_GenerateFtueLoginResponse : Error ==> ", oFormatedIncommingMessage["errorMessage"]);
    }
    else 
    {
      console.log("FtueComponent_GenerateFtueLoginResponse : Response ==> ", oFormatedIncommingMessage);
      const oResponse = JSON.parse(InComingMessage);
      this.oSessionService.cSessionService_SetUserEmail(this.fGuestLoginForm.get('email').value)
      this.oSessionService.cSessionService_SetSessionId(oResponse.guest_session_id);
      this.sUserEmail=this.fGuestLoginForm.get('email').value;
      this.sSessionId=oResponse.guest_session_id;
      this.CreatedVideosComponent_SendTemplateReques();
      this.oSessionService.cSessionService_ResetHighlightedTabs();
      this.oRouter.navigateByUrl("ftue-overview");
    }
  }
  CreatedVideosComponent_SendTemplateReques()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('video_templates_result', this.CreatedVideosComponent_HandleVideoTemplatesResponse);
    const oTemplatePayload = {email : this.sUserEmail, session_id : this.sSessionId};
    this.oWebSocketService.WebSocket_SendMessage('video_templates_request', JSON.stringify(oTemplatePayload));
  }
  CreatedVideosComponent_HandleVideoTemplatesResponse = (IncomingMessage) => 
  {
    console.log('CreatedVideosComponent_HandleVideoTemplatesResponse : ', JSON.parse(IncomingMessage));
    if (IncomingMessage.errorMessage) 
    {
      const sError = 'Error ' + IncomingMessage.errorMessage;
    }
    else 
    {
      this.oSessionService.cSessionService_SetTemplatesConfiguration(IncomingMessage);
      this.lTemplateCategory=this.oTemplateConfigService.TemplateConfigService_FetchTemplateCategoriesNames();
      this.lTemplateNames=this.oTemplateConfigService.TemplateConfigService_FetchTemplateNames();
    }
  };

}
