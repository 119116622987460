import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { ErrorService } from './../../../services/error.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';

@Component({
  selector: 'app-manage-comments',
  templateUrl: './manage-comments.component.html',
  styleUrls: ['./manage-comments.component.scss']
})
export class ManageCommentsComponent implements OnInit {
  sAddContentSlide: string;
  oVideoDetails: any;
  sComments: any = [];
  fCommentsForm: FormGroup;
  videoComment: ''

  constructor(private oDataService : cDataService, private oSessionService: cSessionService, private oFormBuilder: FormBuilder, private oWebSocketService: cWebSocketService,public videoManager:VideoManagerService,public errorService:ErrorService) { }

  ngOnInit(): void {
    this.oDataService.sAddClassContentSlide.subscribe(res =>{
      this.sAddContentSlide = res;
  });
   this.oVideoDetails =  this.videoManager.selectedVideo;
 
  this.CommentSectionComponent_InitializeCommentForm()
  console.log("The Video Details are===>>>", this.oVideoDetails);
  this.sComments = this.oVideoDetails.comments;
  }
  CommentSectionComponent_InitializeCommentForm()
  {
    this.fCommentsForm = this.oFormBuilder.group
    ({
      video_id: this.oVideoDetails.fpath,
      comment: ''
    });
  }
  ManageCommentsComponent_AddCommentRequest()
  { 
    if(this.fCommentsForm.valid && this.fCommentsForm.value.comment != ' ')
    {
      this.videoManager.addComment(this.oVideoDetails.fpath,this.fCommentsForm.value.comment ).then(res=>{

       this.CommentSectionComponent_InitializeCommentForm();
      }).catch(err=>{});
   }
  }
  ManageCommentsComponent_HandleAddCommentRequestResponse = (InComingMessage) => 
  {
  let oFormatedIncommingMessage = JSON.parse(InComingMessage);
  if (oFormatedIncommingMessage["errorMessage"]) 
    {
      console.error("ManageCommentsComponent_HandleAddCommentRequestResponse : Error => ", oFormatedIncommingMessage["errorMessage"]);
    }
  else
  {
    console.log("ManageCommentsComponent_HandleAddCommentRequestResponse ==>",oFormatedIncommingMessage);
    this.sComments.unshift(this.fCommentsForm.value.comment)
    this.CommentSectionComponent_InitializeCommentForm();
  }
  }
  delete(oIncommingIndex)
  {
    console.log("the comment to delete is ===>>>", oIncommingIndex);
    this.videoComment = oIncommingIndex;
    this.videoManager.deleteComment(this.oVideoDetails.fpath, this.videoComment). then(res=>{
      console.log("res");
      
    }).catch(err=>{
      console.log("catch", err);
      
      this.errorService.handleError(err.errorMessage,err.errorType,true);
    });
  }
}
