import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cDataService } from './data.service';
import { cSessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})

export class cTemplateConfigService {
  bSendGenerateVideoRequest = new BehaviorSubject(0);
  
  oTemplateConfigurations : any;
  lTemplateCategory : any = [];
  lBlocksconfiguration : any = [];
  sURL : string;
  sThumbnail : string;
  constructor(private oSessionService : cSessionService, private oDataService : cDataService){}

  TemplateConfigService_FetchTemplateCategoriesNames()
  {
    this.oTemplateConfigurations = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration());
    // console.log("TemplateConfigService_FetchTemplateCategoriesNames : ==> ",this.oTemplateConfigurations);
    let oTemplate = this.oTemplateConfigurations.template_categories;
    let lTemplateCategories=[];
    for (const key in oTemplate) 
    {
      if (Object.prototype.hasOwnProperty.call(oTemplate, key)) 
      {
        lTemplateCategories.push(key);
      }
    }
    console.log("TemplateConfigService_FetchTemplateCategoriesNames : TEMPLATE CATEGORIES ==> ", lTemplateCategories);
    return lTemplateCategories;
  }

  TemplateConfigService_FetchTemplateNames()
  {
    this.oTemplateConfigurations = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration());
    // console.log("TemplateConfigService_FetchTemplateNames : ==> ",this.oTemplateConfigurations);
    let oTemplate = this.oTemplateConfigurations.template_config;
    let lTemplateNames=[];
    for (const key in oTemplate) 
    {
      if (Object.prototype.hasOwnProperty.call(oTemplate, key)) 
      {
        lTemplateNames.push(key);
      }
    }
    console.log("TemplateConfigService_FetchTemplateCategoriesNames : TEMPLATE NAMES ==> ", lTemplateNames);
    return lTemplateNames;
  }

  TemplateConfigService_FetchTemplateCategories(sIncommingtemplateCategory : string)
  {
    this.oTemplateConfigurations = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration());
    return new Promise(async resolve =>
      {
      const oTemplates = this.oTemplateConfigurations.template_categories;
      console.log("TemplateConfigService_FetchTemplateCategories : Categories ==> ",oTemplates);
      for (const sTemplateCategory in oTemplates) 
      {
        if(sIncommingtemplateCategory==sTemplateCategory)
        {
          if (Object.prototype.hasOwnProperty.call(oTemplates, sTemplateCategory)) 
          {
            const element = oTemplates[sTemplateCategory];
            console.log("TemplateConfigService_FetchTemplateCategories : Selected Category ==> ",element);
            this.lTemplateCategory=[];

            for (let nTemplateNameIndex = 0; nTemplateNameIndex < element.length; nTemplateNameIndex++) 
            {
              const template = element[nTemplateNameIndex];
              //get signed url of video
              this.sURL = await this.oDataService.DataService_GetSignedURLForDownload(template.preview_path);
              //get signed url of thumbnail
              this.sThumbnail = await this.oDataService.DataService_GetSignedURLForDownload(template.thumb);
              var oTemplate = new cTemplateCategories(template.template_name,this.sThumbnail,this.sURL);
              this.lTemplateCategory.push(oTemplate);
              //return the list after all elements are pushed into the list
              if(nTemplateNameIndex==element.length-1)
              {
                console.log("Return List")
                resolve(this.lTemplateCategory);
              }
            }
          }
        }
      }
    });
  }

  TemplateConfigService_FetchTemplateBlocks(nSelectedDuration : number, sSelectedTemplateName : string)
  {
    this.oTemplateConfigurations = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration());
    console.log("TemplateConfigService_FetchTemplateBlocks : ==> ",this.oTemplateConfigurations);
    // const nSelectedDuraction  = this.oSessionService.cSessionService_GetOverviewForm().duration;
    console.log("TemplateConfigService_FetchTemplateBlocks : Selected Duration ==>",nSelectedDuration)
    // const sSelectedTemplateCategory = this.oSessionService.cSessionService_GetLooknFeelForm().template_category;
    // console.log("TemplateConfigService_FetchTemplateBlocks : Selected Template Category ==>",sSelectedTemplateCategory)
    // const sSelectedTemplateName = this.oSessionService.cSessionService_GetLooknFeelForm().template_name;
    console.log("TemplateConfigService_FetchTemplateBlocks : Selected Template Name ==>",sSelectedTemplateName);

    let lSelectedBlockList = this.oTemplateConfigurations["template_config"][sSelectedTemplateName][nSelectedDuration]["blocks"];
    console.log("TemplateConfigService_FetchTemplateBlocks : Selected Blocks ==>",lSelectedBlockList);
    this.lBlocksconfiguration=[];
    var nBlockDuraion = 0;
    lSelectedBlockList.forEach((element,index) => 
    {
      let oBlockConfig = this.oTemplateConfigurations.block_config;
      let sBlockName = element.block_name;
      for (let block in oBlockConfig) 
      {
        if (Object.prototype.hasOwnProperty.call(oBlockConfig, block)) 
        {
          if(sBlockName==block)
          {
            let oSelectedElement = oBlockConfig[block];
            let nNumberOfTextAreas  = oSelectedElement.num_texts??0;
            // nNumberOfTextAreas==undefined?0:nNumberOfTextAreas;
            // console.log("Selected Block Name : ==>",sBlockName)
            // console.log("Selected Block : ==>",oSelectedElement)
            console.log("Selected Block num text : ==>",oSelectedElement.num_texts)
            let ltextTitles =[];
            for (let nTextAreaToPush = 0; nTextAreaToPush < nNumberOfTextAreas; nTextAreaToPush++) 
            {
              if(nTextAreaToPush==0)
              {
                ltextTitles.push({placeholder: "Add Title", value : ""});                  
              }
              else
              {
                if(nTextAreaToPush==nNumberOfTextAreas-1)
                {
                  if(nNumberOfTextAreas==2)
                  {
                    ltextTitles.push({placeholder: "Add Subtitle " + nTextAreaToPush, value : ""});
                  }
                  else
                  {
                    ltextTitles.push({placeholder: "Add Description", value : ""});
                  }
                }
                else
                {
                  ltextTitles.push({placeholder: "Add Subtitle " + nTextAreaToPush, value : ""});
                }
              }
            }
            nBlockDuraion = index==0?0:nBlockDuraion+oSelectedElement.duration;
            let oBlock = new cBlockConfiguration("block_"+index,nBlockDuraion,nNumberOfTextAreas,ltextTitles);
            this.lBlocksconfiguration.push(oBlock);
          }
        }
      } 
    });
    return this.lBlocksconfiguration;
  }

  // this.lBlockConfigurations=this.oTemplateConfigService.TemplateConfigService_FetchTemplateBlocks();
  TemplateConfigService_UpdateTemplateBlocks(lIncommingBlocks : any,duration,template)
  {
    let lBlocksConfigurations = this.TemplateConfigService_FetchTemplateBlocks(duration,template);
    //if user have already entered some text then update the text area accordingly
    for (let nBlockTextToUpdateIndex = 0; nBlockTextToUpdateIndex < lBlocksConfigurations.length; nBlockTextToUpdateIndex++) 
    {
      const currentBlock = lIncommingBlocks[nBlockTextToUpdateIndex];
      if(currentBlock)
      {
        for (let nTextListIndex = 0; nTextListIndex < lBlocksConfigurations[nBlockTextToUpdateIndex].lTextAreaDetails.length; nTextListIndex++) 
        {
          if(currentBlock.lTextAreaDetails[nTextListIndex] && currentBlock.lTextAreaDetails[nTextListIndex].value !=="")
          {
            lBlocksConfigurations[nBlockTextToUpdateIndex].lTextAreaDetails[nTextListIndex].value = currentBlock.lTextAreaDetails[nTextListIndex].value;
          }
        }
      }
    }

    let contentForm = this.oSessionService.cSessionService_GetContentForm();
    contentForm.lBlockConfig = lBlocksConfigurations;
    this.oSessionService.cSessionService_SetContentForm(contentForm);
    console.log("Updated template configurations : ==> ", lBlocksConfigurations);
  }
}


export class cTemplateCategories {
  private sName : string;
  private sThumbnail : string;
  private sURL : string;
  constructor(name: string,thumb: string,url: string)
  {
    this.sName=name;
    this.sThumbnail=thumb;
    this.sURL=url;
  }
}

export class cBlockConfiguration {
  private sBlockID : string;
  private nDuration : number;
  private nTextAreaCount : number;
  private lTextAreaDetails : any = [];

  constructor(block_id,duration: number,num_texts: number,text_titles: any)
  {
    this.sBlockID=block_id;
    this.nDuration=duration;
    this.nTextAreaCount=num_texts;
    this.lTextAreaDetails=text_titles;
  }
}