import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-video-overview',
  templateUrl: './edit-video-overview.component.html',
  styleUrls: ['./edit-video-overview.component.scss']
})
export class EditVideoOverviewComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
 
}
