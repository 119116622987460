import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
lUsersData : any = ['item1','item1','item1','item1','item1','item1','item1','item1','item1','item1',]
  constructor(private oRouter : Router) { }

  ngOnInit(): void {
    
  }
  NavigateToUserAccount()
  {
    this.oRouter.navigateByUrl('/business-profile/business-plus/account')
  }
  

}
