import { Component, OnInit } from '@angular/core';

import { cSessionService } from './../../../services/session.service';

@Component({
  selector: 'app-publishedd-video',
  templateUrl: './publishedd-video.component.html',
  styleUrls: ['./publishedd-video.component.scss']
})
export class PublisheddVideoComponent implements OnInit {
  bDashboardLoader  : boolean = true;
  oSetTimeOut: any;

  constructor(private oSessionService: cSessionService) { }

  ngOnInit(): void {
    
    this.oSessionService.setAccountSettingHeaderName("Published Videos");  
  }
  DashboardMyvideoComponent_HideLoader(bEmittedLoadingController : boolean)
  {
    this.bDashboardLoader=bEmittedLoadingController;
    clearTimeout(this.oSetTimeOut);
  }


}

