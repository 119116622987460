import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cWebSocketService } from './../../services/websocket.service';
declare var $ : any
@Component({
  selector: 'app-social-channels',
  templateUrl: './social-channels.component.html',
  styleUrls: ['./social-channels.component.scss']
})
export class SocialChannelsComponent implements OnInit {
  sFacebookSigninLink: string
  sGoogleSigninLink: string;
  sLinkedInSigninLink: string
  sSessionId: string;
  sUserEmail: string;
  DisconnectSocialForm : FormGroup;
  connected_social_platforms: any;
  oSocialChannel : string;
  constructor(
    private oWebSocketService: cWebSocketService, 
    private oSessionService: cSessionService, 
    private oDataService: cDataService , 
    private oFormBuilder : FormBuilder
    ) 
    { }

  ngOnInit() 
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.SocialChannelsComponent_GetProfileData();
    this.SocialChannelsComponent_SocialMediaChannelsLink();
    this.DisconnectSocialForm = this.oFormBuilder.group({platform : ['']});
  }

  SocialChannelsComponent_GetProfileData() 
  {
    this.oWebSocketService.WebSocket_RegisterEvent('profile_request_result', this.SocialChannelsComponent_HandleUserProfileResponse);
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    const oGetUserProfile =
    {
      session_id: this.sSessionId,
      email: this.sUserEmail
    }
    console.log("SocialChannelsComponent_GetProfileData : Profile Request Object ==> ", oGetUserProfile);
    this.oWebSocketService.WebSocket_SendMessage('profile_request', JSON.stringify(oGetUserProfile));
  }

  SocialChannelsComponent_HandleUserProfileResponse = (InComingMessage) => 
  {
    console.log('SocialChannelsComponent_HandleUserProfileResponse : Profile Response ==> ', InComingMessage);
    if (InComingMessage['errorMessage']) {
      console.error('SocialChannelsComponent_HandleUserProfileResponse: Profile Response Error ==> ', InComingMessage);

    }
    else {
      let oResponse = InComingMessage;
      console.log("SocialChannelsComponent_HandleUserProfileResponse: Get Profile Response==>", oResponse);

      if (oResponse.connected_social_platforms) {
        this.connected_social_platforms = oResponse.connected_social_platforms;
        console.log("connected_social_platforms ==>>>", this.connected_social_platforms);
      }
    }
  }

  SocialChannelComponent_DisconnectAccount()
  {
    this.oWebSocketService.WebSocket_RegisterEvent('disconnect_social_platform_result', this.SocialChannelComponent_DisconnectAccountResponse);
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    const oGetUserProfile =
    {
      session_id: this.sSessionId,
      email: this.sUserEmail,
      platform : this.oSocialChannel,
    }
    console.log("SocialChannelComponent_DisconnectAccount: disconnect_social_plateform Object ===>", oGetUserProfile);
    this.oWebSocketService.WebSocket_SendMessage('disconnect_social_platform', JSON.stringify(oGetUserProfile));
  }

  SocialChannelComponent_DisconnectAccountResponse = (InComingMessage) => 
  {
    console.log("SocialChannelComponent_Disconnect: disconnect Response==> ", InComingMessage);
    if  (InComingMessage['errorMessage']) {
      console.error('SocialChannelComponent_DisconnectAccount: Disconnect Response Error ==> ', InComingMessage);
    }
    else {
      let oResponse = InComingMessage;
      console.log("SocialChannelComponent_DisconnectAccount: Disconnect Profile Response==>", oResponse);
      this.SocialChannelsComponent_GetProfileData();
    }
  }

  SocialChannelsComponent_SocialMediaChannelsLink() 
  {
    this.sGoogleSigninLink = this.oDataService.googleJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId+"&return_url=2";
    //console.log("Login with Google Account url : ",this.sGoogleSigninLink);
    this.sFacebookSigninLink = this.oDataService.faceBookJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId+"&return_url=2";
    // console.log("Login with Facebook Account url : ",this.sFacebookSigninLink);
    this.sLinkedInSigninLink = this.oDataService.linkedInJoinLink + "?" + "email=" + this.sUserEmail + "&session_id=" + this.sSessionId+"&return_url=2";
    // console.log("Login with Google Account url : ",this.sLinkedInSigninLink);
  }

  openModal(value)
  {
    console.log("Selected Value ===>>>>",value)
    $('#disconnect').modal('show');
    this.DisconnectSocialForm.patchValue({platform : value})
    this.oSocialChannel = value;
  }

  disconnectChannel()
  {
    this.SocialChannelComponent_DisconnectAccount();
  }
}
