import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cBlockConfigurations } from '../shared/models/blocks-config';
import { cDataService } from './data.service';
import { cSessionService } from './session.service';
import { cTemplateConfigService } from './template.service';
import { cWebSocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class cEditVideoService {
  sSessionID: string;
  sUserEmail: string;
  sVideoID : string;
  // sEditVideoID : string;
  oVideoDetails : any;
  oTemplateConfigurations : any = [];
  lBlockConfigurations : any = [];
  
  constructor(private oWebSocketService : cWebSocketService, private oSessionService : cSessionService, private oDataService : cDataService, private oTemplateConfigService : cTemplateConfigService,private oRouter : Router) 
  {
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
  }
  
  EditVideoService_GetEditVideo(sIncommingVideoId : string)
  {
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sVideoID = sIncommingVideoId;
    this.oSessionService.cSessionService_SetVideoId(this.sVideoID);
    // let oGetEditVideoRequest = 
    // {
    //   session_id: this.sSessionID,
    //   email: this.sUserEmail,
    //   video_id: sIncommingVideoId
    // }
    // this.oWebSocketService.WebSocket_RegisterEvent("edit_video_result", this.EditVideoService_HandleEditVideoResponse);
    // this.oWebSocketService.WebSocket_SendMessage("edit_video_request", JSON.stringify(oGetEditVideoRequest));
    this.EditVideoService_GetVideoDetails(this.sVideoID);
  }
  // EditVideoService_HandleEditVideoResponse = (InComingMessage) => 
  // {
  //   let oFormatedIncommingMessage = JSON.parse(InComingMessage);
  //   console.log("EditVideoService_HandleEditVideoResponse : ==> ", oFormatedIncommingMessage);
  //   if (oFormatedIncommingMessage["errorMessage"]) 
  //   {
  //     console.error("EditVideoService_HandleEditVideoResponse : Error => ", oFormatedIncommingMessage["errorMessage"]);
  //   }
  //   else 
  //   {
  //     this.sEditVideoID = oFormatedIncommingMessage.edited_video_id;
  //     this.sVideoID = oFormatedIncommingMessage.video_id;
  //     this.oSessionService.cSessionService_SetEditVideoId(this.sEditVideoID);
  //   }
  //   this.oWebSocketService.WebSocket_UnRegisterEvent("edit_video_result", this.EditVideoService_HandleEditVideoResponse);
  // }
  EditVideoService_GetVideoDetails(sIncommingVideoId : string)
  {
    this.oWebSocketService.WebSocket_RegisterEvent('video_data_result', this.EditVideoService_HandleSingleVideoResponse);
    let oVideoDetailsRequest = 
    {
      email: this.sUserEmail,
      session_id: this.sSessionID,
      video_id: sIncommingVideoId
    };
    this.oWebSocketService.WebSocket_SendMessage("video_data_request", JSON.stringify(oVideoDetailsRequest));
  }
  EditVideoService_HandleSingleVideoResponse = (IncomingMessage) => 
  {
    console.log("EditVideoService_HandleSingleVideoResponse : Video Details => ", JSON.parse(IncomingMessage));
    if (JSON.parse(IncomingMessage)['errorMessage']) 
    {
      console.error("EditVideoService_HandleSingleVideoResponse : ERROR ==>",JSON.parse(IncomingMessage)['errorMessage']);
    }
    else 
    {
      let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
      this.oVideoDetails = oFormatedIncommingMessage.video;
      let vo_text = this.oVideoDetails.vo_text?this.oVideoDetails.vo_text:'';
      let selected_voice = this.oVideoDetails.selected_voice;
  
      if(!this.oVideoDetails.vo_file)
      {
        selected_voice = selected_voice?selected_voice:'voice3';
        this.oVideoDetails.selected_voice = selected_voice;
        this.oVideoDetails.vo_text = vo_text;
      }
      this.oSessionService.cSessionService_SetVideoDetails(this.oVideoDetails);
      this.EditVideoService_SetCreateVideoForms(this.oVideoDetails);
    }
    this.oWebSocketService.WebSocket_UnRegisterEvent('video_data_result', this.EditVideoService_HandleSingleVideoResponse);
  }
  async EditVideoService_SetCreateVideoForms(oIncommingVideo : any)
  {
    console.log("cEditVideoService : Incomming video details ==> ", oIncommingVideo);
    this.EditVideoService_SetOverviewForm(oIncommingVideo);
    await this.EditVideoService_SetLooknFeelForm(oIncommingVideo);
    await this.EditVideoService_SetContentForm(oIncommingVideo);
    this.EditVideoService_SetOtherSettingsForm(oIncommingVideo);

    // this.oSessionService.cSessionService_SetVideoId(oIncommingVideo.video_id);
    this.oSessionService.cSessionService_SetResetTextOverlayCheck(false);
    this.oSessionService.cSessionService_SetVideoTitle(oIncommingVideo.title);
    this.oSessionService.cSessionService_SetVideoDuration(oIncommingVideo.duration);
    this.oSessionService.cSessionService_SetVideoCreationDate(oIncommingVideo.created);
    this.oRouter.navigate(["wizard/overview"]);
  }
  //Set overview form
  EditVideoService_SetOverviewForm(oIncommingVideo : any)
  {
    let oOverViewForm = 
    {
        session_id: this.sSessionID,
        email: this.sUserEmail,
        title: oIncommingVideo.title,
        duration: oIncommingVideo.duration,
        about: oIncommingVideo.about,
        aspect_ratio: oIncommingVideo.aspect_ratio,
    }
    this.oSessionService.cSessionService_SetOverviewForm(oOverViewForm);
  }
  //Set looknfeel form
  async EditVideoService_SetLooknFeelForm(oIncommingVideo : any)
  {
    let logo;
    let size;
    let secondLogo;
    let secondLogoSize;
    if(oIncommingVideo.logo_img)
    {
      logo = await this.EditVideoService_ReturnFileDetails(oIncommingVideo.logo_img);
      size = logo.size;
    }
    else 
    {
      logo="";
      size=0;
    }
    if(oIncommingVideo.second_logo_img)
    {
      secondLogo = await this.EditVideoService_ReturnFileDetails(oIncommingVideo.second_logo_img);
      secondLogoSize = secondLogo.size;
    }
    else 
    {
      secondLogo="";
      secondLogoSize=0;
    }

    let lRemoveDuplicates = new Set(oIncommingVideo.key_terms);
    let lKeyterms = [...lRemoveDuplicates];
    let oLooknFeelForm = 
    {
      session_id: this.sSessionID,
      email: this.sUserEmail,
      video_id : oIncommingVideo.video_id,
      template_category : oIncommingVideo.template_category,
      key_terms : lKeyterms,
      template_name : oIncommingVideo.template_name ,
      color : oIncommingVideo.color,
      font_color : oIncommingVideo.font_color,
      oUploadedLogo : logo,
      nUploadedLogoSize : size,
      bDisplayUploadLogoSection : true,
      oUploadedSecondLogo : secondLogo,
      nUploadedSecondLogoSize : secondLogoSize,
      bSecondLogo: true,
    }
    this.oSessionService.cSessionService_SetLooknFeelForm(oLooknFeelForm);
  }
  //Set content form
  async EditVideoService_SetContentForm(oIncommingVideo : any)
  {
    let oUploadedVoiceOverFile : any;
    let nVoiceOverFileSize : number = 0;
    let bTextToSpeechToggle : boolean = true;
    // console.log("VoiceOver ",oUploadedVoiceOverFile);
    if(oIncommingVideo.vo_file)
    {
      oUploadedVoiceOverFile = await this.EditVideoService_ReturnFileDetails(oIncommingVideo.vo_file);
      bTextToSpeechToggle=false;
      nVoiceOverFileSize=oUploadedVoiceOverFile.size;
    }

    let oContentForm = 
    {
      session_id: this.sSessionID,
      email: this.sUserEmail,
      vo_text : oIncommingVideo.vo_text,
      selected_voice : oIncommingVideo.selected_voice,
      pronto_clips : true,
      oUploadedVoiceOverFile : oUploadedVoiceOverFile,
      nVoiceOverFileSize : nVoiceOverFileSize,
      sGender : this.EditVideoService_ReturnVoiceGender(oIncommingVideo.selected_voice),
      lUploadedFiles : await this.EditVideoService_ReturnUploadedVisuals(oIncommingVideo.user_images.concat(oIncommingVideo.user_clips)),
      bShowTextToSpeechToggle : bTextToSpeechToggle,
      bLetProntoChooseToggle : oIncommingVideo.use_pronto_clips,
      bMyMediaLibraryToggle : false,
      bUploadSectionToggle : oIncommingVideo.user_images.concat(oIncommingVideo.user_clips).length==0?false:true,
      lBlockConfig : await this.VideoTimelineComponent_GenerateBlocks(oIncommingVideo.block_config,oIncommingVideo.text_list,oIncommingVideo.clips),
      // lBlockConfig : this.EditVideoService_ReturnBlockConfigurations(oIncommingVideo.text_list)
      music_file : oIncommingVideo.music_file,
      cta : oIncommingVideo.cta
    }
    this.oSessionService.cSessionService_SetContentForm(oContentForm);
  }
  //Set other settings form
  EditVideoService_SetOtherSettingsForm(oIncommingVideo : any)
  {
    if(Object.keys(oIncommingVideo.ci_dict.length>0))
    {
      let oAddress : any;
      let bDisplayAddress : boolean=false;
      let bDisplayPhone : boolean=false;
      let bDisplayUrl : boolean=false; 
      if(oIncommingVideo.ci_dict.address)
      {
        oAddress =
        {
          line1: oIncommingVideo.ci_dict.address.line1,
          line2: oIncommingVideo.ci_dict.address.line2,
          city: oIncommingVideo.ci_dict.address.city,
          state: oIncommingVideo.ci_dict.address.state,
          postal_code: oIncommingVideo.ci_dict.address.postal_code,
          country: oIncommingVideo.ci_dict.address.country,
        }
        //set address toggle
        bDisplayAddress=true;
      }
      else
      {
        oAddress =
        {
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
        }
      }
      //set phone toggle
      if(oIncommingVideo.ci_dict.phone || oIncommingVideo.ci_dict.phone==="")
      {
        bDisplayPhone=true;
      }
      //set url toggle
      if(oIncommingVideo.ci_dict.url || oIncommingVideo.ci_dict.url==="")
      {
        bDisplayUrl=true;
      }
      let oOtherSettingForm = 
      {
        video_id : oIncommingVideo.video_id,
        session_id: this.sSessionID,
        email: this.sUserEmail,
        url: oIncommingVideo.ci_dict.url,
        address: oAddress, 
        phone: oIncommingVideo.ci_dict.phone,
        bDisplayAddress: bDisplayAddress,
        bDisplayPhone: bDisplayPhone,
        bDisplayUrl : bDisplayUrl
      }
      this.oSessionService.cSessionService_SetOtherSettingForm(oOtherSettingForm);
    }
    else
    {
      //if address field is not present then show the create video behaviour for other settings section
      // localStorage.removeItem('lsOtherSettingForm');
      let oOtherSettingForm = 
      {
        video_id : oIncommingVideo.video_id,
        session_id: this.sSessionID,
        email: this.sUserEmail,
        url: "",
        address:
        {
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
        },
        phone: "",
        bDisplayAddress: false,
        bDisplayPhone: false,
        bDisplayUrl : false
      }
      this.oSessionService.cSessionService_SetOtherSettingForm(oOtherSettingForm);
    }
  } 
  async EditVideoService_ReturnFileDetails(sIncommingPath : string)
  {
    let url: string = await this.oDataService.DataService_GetSignedURLForDownload(sIncommingPath)
    let size : any = await this.EditVideoService_ReturnFileSize(url);
    let file = 
    {
      url : url,
      size : size,
      fpath : sIncommingPath,
      visual_path : sIncommingPath,
      filename : sIncommingPath?.substr(sIncommingPath.indexOf('.com/')+5,sIncommingPath.length),
    }
    return file;
  }
  EditVideoService_ReturnVoiceGender(sIncommingVoice : string)
  {
    let sGender :string;
    if(sIncommingVoice)
    {
      let lVoiceOverList = this.oSessionService.cSessionService_GetUserVoiceOverList();
      const voice=lVoiceOverList.find(({value})=> value===sIncommingVoice);
      sGender =  voice.gender;
    }
    else
    {
      sGender = "";
    }
    return sGender;
  }
  VideoTimelineComponent_GenerateBlocks(lIncommingBlocks : any, lIncommingTextList : any, lIncommingClips : any)
  {
    this.oTemplateConfigurations = JSON.parse(this.oSessionService.cSessionService_GetTemplatesConfiguration());
    // console.log("Template Configuration : ==>",this.oTemplateConfigurations);
    var lOriginalVideoBlockConfig = this.oSessionService.SessionService_GetOriginalVideoBlockConfig();
    const lBlocks = lIncommingBlocks;
    const lTextList = lIncommingTextList;
    const lClips = lIncommingClips;
    let nClipIndex = 0;
    let nTextIndex = 0;
    var nBlockDuraion = 0;
    this.lBlockConfigurations=[];
    for (let nBlockToPushIndex = 0; nBlockToPushIndex < lBlocks.length; nBlockToPushIndex++) 
    {
      const oCurrentBlock = lBlocks[nBlockToPushIndex];
      let block_file_path :string;
      if(oCurrentBlock.block_file_path)
      {
        block_file_path = oCurrentBlock.block_file_path;
      }
      else
      {
        block_file_path = lOriginalVideoBlockConfig[nBlockToPushIndex]?.block_file_path;
      }
      // let url = block_file_path==undefined?'': await this.oDataService.DataService_GetSignedURLForDownload(block_file_path);
      // let thumb = block_file_path==undefined?'': await this.oDataService.DataService_GetUserVisualThumbnail(block_file_path);
      // let thumb_url = block_file_path==undefined?'': await this.oDataService.DataService_GetSignedURLForDownload(thumb);
      let url = "";
      let thumb = "";
      let thumb_url = "";
      let block_args = oCurrentBlock.block_args;
      // let clip_trans = [];
      if (!block_args.hasOwnProperty("clip_trans")) 
      {
        block_args.clip_trans = [{trans_args : {direction : ""},trans_name : ""}];
      }
      if (!block_args.hasOwnProperty("clip_effects")) 
      {
        block_args.clip_effects = [{effect_args : {theme : ""},effect_name : ""}];
      }
      let num_clips = oCurrentBlock.block_args.num_clips;
      let num_text = oCurrentBlock.block_args.num_texts??0;
      let clips = [];
      let ltextTitles=[];
      for (let nClipToPushIndex = 0; nClipToPushIndex < num_clips; nClipToPushIndex++) 
      {
        let clip_path = lClips[nClipIndex];
        let sType = clip_path?.indexOf('.mp4')!==-1?'clip':'image';
        // let clip_url = await this.oDataService.DataService_GetSignedURLForDownload(clip_path);
        // let clip_thumb = await this.oDataService.DataService_GetUserVisualThumbnail(clip_path);
        // let clip_thumb_url = await this.oDataService.DataService_GetSignedURLForDownload(clip_thumb);
        // clips.push({clip_path : clip_path, clip_url : clip_url, clip_thumb : clip_thumb,clip_thumb_url : clip_thumb_url,type : sType});
        clips.push({clip_path : clip_path, clip_url : "", clip_thumb : "",clip_thumb_url : "",type : sType});
        nClipIndex++;
        // console.log("Clip Index : ==>",nClipIndex);
      }
      for (let nTextToPushIndex = 0; nTextToPushIndex < num_text; nTextToPushIndex++) 
      {
        let sText = lTextList[nTextIndex].includes("\n")?"":lTextList[nTextIndex];

        if(nTextToPushIndex==0)
        {
          ltextTitles.push({placeholder: "Add Title", value : sText});                  
        }
        else
        {
          if(nTextToPushIndex==num_text-1)
          {
            if(num_text==2)
            {
              ltextTitles.push({placeholder: "Add Subtitle " + nTextToPushIndex, value : sText});
            }
            else
            {
              ltextTitles.push({placeholder: "Add Description", value : sText});
            }
          }
          else
          {
            ltextTitles.push({placeholder: "Add Subtitle " + nTextToPushIndex, value : sText});
          }
        }
        nTextIndex++;
      }
      nBlockDuraion = nBlockToPushIndex==0?0:nBlockDuraion+oCurrentBlock.block_args.duration;
      let oBlockConfigurations = new cBlockConfigurations(
      "block_"+nBlockToPushIndex,block_file_path,url,thumb,thumb_url,
      oCurrentBlock.effects,num_clips,clips,block_args,ltextTitles,nBlockDuraion,num_text);
      this.lBlockConfigurations.push(oBlockConfigurations);
    }
    console.log("Block Configuration : ==> ", this.lBlockConfigurations);
    localStorage.removeItem('lsOriginalVideoBlockConfig');
    return this.lBlockConfigurations;
  }
  async EditVideoService_ReturnUploadedVisuals(lIncommingVisualsList : any)
  {
    let luserVisuals = [];
    // console.log("EditVideoService_ReturnUploadedVisuals : ==> ", lIncommingVisualsList);
    if(lIncommingVisualsList.length>0)
    {
      for (let nVisualToAddIndex = 0; nVisualToAddIndex < lIncommingVisualsList.length; nVisualToAddIndex++) 
      {
        let visual = lIncommingVisualsList[nVisualToAddIndex];
        let sFPathWithoutExtension = visual.split('.').slice(0, -1).join('.');
        const sThumbPath = sFPathWithoutExtension+"_thumb.jpg" ;
        const sFilename = visual.substr(visual.indexOf('.com/')+5,visual.length);
        const sThumbnail = await this.oDataService.DataService_GetSignedURLForDownload(sThumbPath);
        const sUrl = await this.oDataService.DataService_GetSignedURLForDownload(visual);
        const sFPath = visual;
        const nSize = await this.EditVideoService_ReturnFileSize(sUrl);
        luserVisuals.push({filename : sFilename, url : sUrl,thumb : sThumbnail, size : nSize, fpath : sFPath});
      }
    }
    else
    {
      luserVisuals=[];
    }
    console.log("EditVideoService_ReturnUploadedVisuals : ==> ", luserVisuals);
    return luserVisuals;
  }
   EditVideoService_ReturnFileSize(sIncommingFileURL : string)
  {
    var fileSize =  new Promise(resolve =>
    {
      // console.log("EditVideoService_ReturnFileSize : ===> ",sIncommingFileURL);
      var size = 0;
      var XMLHttpRequest = require('xhr2');
      var xhr = new XMLHttpRequest();
      xhr.open("GET",sIncommingFileURL, true);
      xhr.responseType = "arraybuffer";
      xhr.onreadystatechange = function() 
      {
        if(this.readyState == this.DONE) 
        {
          size = this.response.byteLength;
          // console.log("Image size = " + size + " bytes.");
          resolve(size);
        }
      };
      xhr.send(null);
    });
    return fileSize;  
  }
  EditVideoService_ReturnCombinedPayload()
  {
    // this.sEditVideoID = this.oSessionService.SessionService_GetEditVideoId();
    this.sVideoID = this.oSessionService.SessionService_GetVideoId();
    let fOverviewForm = this.oSessionService.cSessionService_GetOverviewForm();
    let fLooknFeelForm = this.oSessionService.cSessionService_GetLooknFeelForm();
    let fContentForm = this.oSessionService.cSessionService_GetContentForm();
    let fOtherSettingForm = this.oSessionService.cSessionService_GetOtherSettingForm();
    let oPayload = 
    {
      session_id: fOverviewForm.session_id,
      email: fOverviewForm.email,
      video_id : this.sVideoID,
      title: fOverviewForm.title,
      duration: fOverviewForm.duration,
      about: fOverviewForm.about,
      aspect_ratio: '16x9',
      template_category : fLooknFeelForm.template_category,
      key_terms : fLooknFeelForm.key_terms,
      template_name : fLooknFeelForm.template_name,
      color : fLooknFeelForm.color,
      font_color : fLooknFeelForm.font_color,
      logo_img : this.EditVideoService_ReturnLogo(fLooknFeelForm),
      second_logo_img : this.EditVideoService_ReturnSecondLogo(fLooknFeelForm),
      user_images : [],
      user_clips : [],
      pronto_clips : fContentForm.pronto_clips,
      text_list : this.EditVideoService_ReturnTextList(fContentForm.lBlockConfig),
      clips : this.EditVideoService_ReturnClipList(fContentForm.lBlockConfig),
      block_config : this.EditVideoService_ReturnBlockConfig(fContentForm.lBlockConfig),
      music_file : fContentForm.music_file,
      cta : fContentForm.cta,
      vo_text :'',
      selected_voice : '',
      voice_file : '',
      url: '',
      address:
      ({
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
      }),
      phone: '', 
    }
    //if user used address,url,phone
    if(fOtherSettingForm)
    {
      oPayload.url = fOtherSettingForm.url==null?'':fOtherSettingForm.url;
      oPayload.address =
      {
        line1: fOtherSettingForm.line1,
        line2: fOtherSettingForm.line2,
        city: fOtherSettingForm.city,
        state: fOtherSettingForm.state,
        postal_code: fOtherSettingForm.postal_code,
        country: fOtherSettingForm.country,
      };
      oPayload.phone = fOtherSettingForm.phone==null?'':fOtherSettingForm.phone;
    }
    else
    {
      delete oPayload.url;
      delete oPayload.address;
      delete oPayload.phone;
    }
    var lImagesList=[];
    var lClipsList=[];
    //Extract user images and clips from uploaded images
    if(fContentForm.bUploadSectionToggle)
    {
      for (let nVisualIndex = 0; nVisualIndex < fContentForm.lUploadedFiles.length; nVisualIndex++) 
      {
        const element = fContentForm.lUploadedFiles[nVisualIndex];
        const extension =  element.filename.split('.').pop();
        if(extension === "png" || extension === "jpg" || extension === "jpeg")
        {
          lImagesList.push(element.fpath);
        }
        else
        {
          lClipsList.push(element.fpath);
        }
      }
    }
    //Extract images and clips from user media
    if(fContentForm.bMyMediaLibraryToggle && fContentForm.lUserSelectedVisuals)
    {
      for (let nVisualIndex = 0; nVisualIndex < fContentForm.lUserSelectedVisuals.length; nVisualIndex++) 
      {
        const element = fContentForm.lUserSelectedVisuals[nVisualIndex];
        const extension =  element.name.split('.').pop();
        if(extension === "png" || extension === "jpg" || extension === "jpeg")
        {
          lImagesList.push(element.visual_path);
        }
        else
        {
          lClipsList.push(element.visual_path);
        }
      }
    }
    oPayload.user_clips = lClipsList;
    oPayload.user_images = lImagesList;
    //if user enters voice over text
    if(fContentForm.bShowTextToSpeechToggle)
    {
      let vo_text = fContentForm.vo_text==null?'':fContentForm.vo_text;
      oPayload.vo_text = vo_text;
      oPayload.selected_voice = fContentForm.selected_voice;
      delete oPayload.voice_file;
    }
    //if user uploads voice over file
    else
    {
      const oVoiceOverFile = fContentForm.oUploadedVoiceOverFile;
      oPayload.voice_file = oVoiceOverFile.fpath;
      delete oPayload.vo_text;
      delete oPayload.selected_voice;
    }

    if (!fOtherSettingForm?.bDisplayUrl)
    {
      delete oPayload.url;
    }
    if(!fOtherSettingForm?.bDisplayAddress)
    {
      delete oPayload.address;
    }
    if(!fOtherSettingForm?.bDisplayPhone)
    {
     delete oPayload.phone;
    }
    return oPayload;
  }
  EditVideoService_ReturnTextList(lIncommingConfigurations : any)
  {
    let lTextList=[];
    lIncommingConfigurations.forEach(element => 
    {
      element.lTextAreaDetails.forEach(element => 
      {
        lTextList.push(element.value);  
      });
    });
    // console.log("EditVideoService_ReturnTextList : Text List==> ",lTextList);
    return lTextList;
  }
  EditVideoService_ReturnClipList(lIncommingConfigurations : any)
  {
    let lClipList=[];
    const sResetTextArea = this.oSessionService.SessionService_GetResetTextOverlayCheck();
    if(sResetTextArea=='true')
    {
      return lClipList;
    }
    else
    {
      lIncommingConfigurations.forEach(element => 
      {
        element.clips.forEach(element => 
        {
          lClipList.push(element.clip_path);  
        });
      });
      //console.log("EditVideoService_ReturnClipList : Clip List ==> ",lClipList);
      return lClipList;
    }
  }
  EditVideoService_ReturnBlockConfig(lIncommingConfigurations : any)
  {
    let lBlockConfig = [];
    const sResetTextArea = this.oSessionService.SessionService_GetResetTextOverlayCheck();
    if(sResetTextArea=='true')
    {
      return lBlockConfig;
    }
    else
    {
      lIncommingConfigurations.forEach(element => 
      {
        let oBlock = {effects : element.effects, block_args: element.block_args};
        // console.log("Current block : ==> ", oBlock);
        for (let nClipEffectIndex = 0; nClipEffectIndex < oBlock.block_args?.clip_effects.length; nClipEffectIndex++) 
        {
          if(oBlock.block_args.clip_effects[nClipEffectIndex]== null || (oBlock.block_args.clip_effects[nClipEffectIndex].effect_name==="" && oBlock.block_args.clip_effects[nClipEffectIndex].effect_args.theme===""))
          {
            oBlock.block_args.clip_effects.splice(nClipEffectIndex,1);
          }
        }
        for (let nClipTransIndex = 0; nClipTransIndex < oBlock.block_args?.clip_trans.length; nClipTransIndex++) 
        {
          if(oBlock.block_args.clip_trans[nClipTransIndex] == null || (oBlock.block_args.clip_trans[nClipTransIndex].trans_name==="" && oBlock.block_args.clip_trans[nClipTransIndex].trans_args.direction===""))
          {
            oBlock.block_args.clip_trans.splice(nClipTransIndex,1);
          }
        }
        //if user didn't updated clip_effects and clip_trans
        if(oBlock.block_args.clip_effects.length === 0)
        {
          delete oBlock.block_args.clip_effects;
        }
        //if user didn't updated clip_trans
        if(oBlock.block_args.clip_trans.length === 0)
        {
          delete oBlock.block_args.clip_trans;
        }
        //if effect is undefined then remove it 
        if(oBlock.effects === undefined)
        {
          delete oBlock.effects;
        }
        lBlockConfig.push(oBlock);
      });
      console.log("EditVideoService_ReturnBlockConfig : Block List ==> ",lBlockConfig);
      return lBlockConfig;
    }
  }
  EditVideoService_ReturnLogo(oIncommingLooknFeelForm : any)
  {
    let logo_path;
    if(oIncommingLooknFeelForm.bDisplayUploadLogoSection==true)
    {
      logo_path = oIncommingLooknFeelForm.oUploadedLogo?oIncommingLooknFeelForm.oUploadedLogo.fpath:'';
    }
    else
    {
      let profile_logo = this.oSessionService.cSessionService_GetUserLogo();
      logo_path = profile_logo?profile_logo.fpath:'';
    }
    return logo_path;
  }
  EditVideoService_ReturnSecondLogo(oIncommingLooknFeelForm : any)
  {
    let logo_path;
    if(oIncommingLooknFeelForm.bSecondLogo==true)
    {
      logo_path = oIncommingLooknFeelForm.oUploadedSecondLogo?oIncommingLooknFeelForm.oUploadedSecondLogo.fpath:'';
    }
    // else
    // {
    //   let profile_logo = this.oSessionService.cSessionService_GetUserLogo();
    //   logo_path = profile_logo?profile_logo.fpath:'';
    // }
    return logo_path;
  }
}
