import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franchisees',
  templateUrl: './franchisees.component.html',
  styleUrls: ['./franchisees.component.scss']
})
export class FranchiseesComponent implements OnInit {
  lFranchesees : any = ['item','item','item','item','item','item','item','item','item','item']
  constructor() { }

  ngOnInit(): void {
  }
 
}