import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { video } from '../../models/video';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonCallService {

  constructor(private http: HttpClient) { }
  getSignedURL(email,session_id,video_id)
  {

 let formData = new FormData();
  formData.append('session_id', session_id);
  formData.append('email', email);
  formData.append('fpath', video_id);
  return this.http.post(environment.API_HOST + 'signed_url_for_download', formData).toPromise();
  }

getVoicelist(email,session_id):Promise<unknown>
{
   return this.http.get(environment.API_HOST + 'voices?email='+email+'&session_id='+session_id).toPromise();
}
}
