export class CanvasMod {
    valX = 0;
    valY = 0;
    valWidth = 0;
    valHeight = 0;
    id = "";

    constructor(){
        
    }
}
