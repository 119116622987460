import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franchesees-media-library',
  templateUrl: './franchesees-media-library.component.html',
  styleUrls: ['./franchesees-media-library.component.scss']
})
export class FrancheseesMediaLibraryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.InitializeJqueryCode()
  }
  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `
    $(function ($) {
      $.autofilter();
  });
  jQuery(function () {
    jQuery('.showSingle').click(function () {
        jQuery('.targetDiv').hide('.cnt');
        jQuery('#div' + $(this).attr('target')).slideToggle();

    });
});

$(document).ready(function () {
    var submitIcon = $('.searchbox-icon');
    var inputBox = $('.searchbox-input');
    var searchBox = $('.searchbox');
    var isOpen = false;
    submitIcon.click(function () {
        if (isOpen == false) {
            searchBox.addClass('searchbox-open');
            inputBox.focus();
            isOpen = true;
        } else {
            searchBox.removeClass('searchbox-open');
            inputBox.focusout();
            isOpen = false;
        }
    });
    submitIcon.mouseup(function () {
        return false;
    });
    searchBox.mouseup(function () {
        return false;
    });
    $(document).mouseup(function () {
        if (isOpen == true) {
            $('.searchbox-icon').css('display', 'block');
            submitIcon.click();
        }
    });
});
    `;
    document.body.appendChild(chatScript);
  }

}
