import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup,FormBuilder,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
declare var $ : any;

@Component({
  selector: 'app-ftue-other-setting',
  templateUrl: './ftue-other-setting.component.html',
  styleUrls: ['./ftue-other-setting.component.scss']
})
export class FtueOtherSettingComponent implements OnInit, OnDestroy {

  sSessionId : string = "";
  sUserEmail : string = "";
  fOtherSettingForm : FormGroup;
  oSavedFormData : any;
  bDisplayErrorMsg: boolean = false;
  sErrorMessage: string;
  bURLToggle : boolean = true ;
  bAddressToggle : boolean = true;
  bPhoneToggle : boolean = false;
  sVideoId: string;
  bCreateVideoMode : boolean = true;
  oOverviewForm: any;
  oLooknFeelForm: any;
  showMenu : string;
  isError : boolean = false; 
  constructor(private oFormBuilder : FormBuilder, private oRouter : Router, private oWebSocketService: cWebSocketService, private oSessionService : cSessionService, private oEditVideoService : cEditVideoService) 
  {
    this.showMenu = this.oRouter.url;
    let bGenerateVideo = this.oSessionService.cSessionService_GetGeneratedVideoCheck();
    if(bGenerateVideo)
    {
      this.bCreateVideoMode=false;
    }
    else
    {
      this.bCreateVideoMode=true;
    }
  }
  
  ngOnInit(): void 
  {
    this.OtherSettingComponent_InitializeJqueryCode()
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.oWebSocketService.WebSocket_RegisterEvent("user_video_result", this.OtherSettingComponent_HandleCreateVideoResponse);
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oOverviewForm = this.oSessionService.cSessionService_GetOverviewForm();
    this.oLooknFeelForm = this.oSessionService.cSessionService_GetLooknFeelForm();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.oSavedFormData = this.oSessionService.cSessionService_GetOtherSettingForm();
    console.log("OtherSettingComponent : SavedForm ==> ",this.oSavedFormData);
    if(this.oSavedFormData)
    {
      this.OtherSettingComponent_SetOtherSettingForm();

    }
    else
    {
      this.OtherSettingComponent_InitializeOverviewForm();
      // this.OtherSettingComponent_GetProfileData();
    }
    this.OtherSettingComponent_URLToggle(this.bURLToggle);
    this.OtherSettingComponent_AddressToggle(this.bAddressToggle);
    this.OtherSettingComponent_PhoneToggle(this.bPhoneToggle);


  }
  ngOnDestroy()
  {
    this.oSessionService.cSessionService_SetOtherSettingForm(this.fOtherSettingForm.value);
    this.oWebSocketService.WebSocket_Destroy();
  }
  OtherSettingComponent_InitializeOverviewForm()
  {
    this.fOtherSettingForm = this.oFormBuilder.group(
    {
      video_id: this.sVideoId ,
      session_id: this.sSessionId,
      email: this.sUserEmail,
        url: ['',[
        Validators.required,
        Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
      ]],
      line1: ['',[Validators.required]],
      line2:  ['',[Validators.required]],
      city:  ['',[Validators.required]],
      state: ['',[Validators.required]],
      postal_code:  ['',[Validators.required]],
      country:  ['',[Validators.required]],

      phone: [''],
      bDisplayUrl:  this.bURLToggle,
      bDisplayAddress: this.bAddressToggle,
      bDisplayPhone:this.bPhoneToggle,
    });
  }
  OtherSettingComponent_SetOtherSettingForm()
  {
    this.bURLToggle = this.oSavedFormData.bDisplayUrl;
    this.bAddressToggle = this.oSavedFormData.bDisplayAddress;
    this.bPhoneToggle = this.oSavedFormData.bDisplayPhone;
    
    this.fOtherSettingForm = this.oFormBuilder.group(
    {
      video_id : this.sVideoId ,
      session_id: this.oSavedFormData.session_id,
      email: this.oSavedFormData.email,
      url: ['',[
        Validators.required,
        Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
      ]],
      line1: [this.oSavedFormData.line1,[Validators.required]],
      line2: [this.oSavedFormData.line2,[Validators.required]],
      city: [this.oSavedFormData.city,[Validators.required]],
      state: [this.oSavedFormData.state,[Validators.required]],
      postal_code: [this.oSavedFormData.postal_code,[Validators.required]],
      country: [this.oSavedFormData.country,[Validators.required]],
     
      phone: [this.oSavedFormData.phone],
      bDisplayUrl: this.oSavedFormData.bDisplayUrl ,
      bDisplayAddress: this.oSavedFormData.bDisplayAddress,
      bDisplayPhone: this.oSavedFormData.bDisplayPhone,
    });
 
  }
  OtherSettingComponent_SendCreateVideoRequest()
  {

    console.log(this.fOtherSettingForm);
    console.log(this.fOtherSettingForm.invalid);
    console.log("clicked")
    //clicking without validation dont send it forward.
   if(this.fOtherSettingForm.valid){
  
    let oCreateVideoRequestForm = 
    {
      video_id: this.fOtherSettingForm.value.video_id ,
      session_id: this.fOtherSettingForm.value.session_id,
      email: this.fOtherSettingForm.value.email,
      url: this.fOtherSettingForm.value.url,
      address:
      ({
        line1: this.fOtherSettingForm.value.line1,
        line2: this.fOtherSettingForm.value.line2,
        city: this.fOtherSettingForm.value.city,
        state: this.fOtherSettingForm.value.state,
        postal_code: this.fOtherSettingForm.value.postal_code,
        country: this.fOtherSettingForm.value.country,
      }),
      phone: this.fOtherSettingForm.value.phone,
      about: this.oOverviewForm.about,
      key_terms: this.oLooknFeelForm.key_terms, 
    }
    if (!this.bURLToggle)
    {
      delete oCreateVideoRequestForm.url
    }
    if(!this.bAddressToggle)
    {
      delete oCreateVideoRequestForm.address
    }
    if(!this.bPhoneToggle)
    {
     delete oCreateVideoRequestForm.phone
    }

    console.log(oCreateVideoRequestForm);
    this.oSessionService.cSessionService_SetGenerateVideoPayload(oCreateVideoRequestForm);
    if(this.bCreateVideoMode)
    {
      this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oRouter.navigateByUrl("/ftue-preview");
    }
    else
      {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oSessionService.cSessionService_SetResetTextOverlayCheck(false);
        this.oRouter.navigateByUrl('/dashboard/overview');
      }
    // console.log("OtherSettingComponent_SendCreateVideoRequest : create video Request To WebSocket => ", oCreateVideoRequestForm);
    // this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oCreateVideoRequestForm));
  }
  }
  
  OtherSettingComponent_HandleCreateVideoResponse = (InComingMessage) => 
  {
    if (JSON.parse(InComingMessage)["errorMessage"]) 
    {
     
      console.error("OtherSettingComponent_HandleCreateVideoResponse : Error ==> ", InComingMessage);
      // this.sErrorMessage = JSON.parse(InComingMessage)["errorMessage"];
      this.sErrorMessage = 'An error occurred while creating video. Please try again.';
      $('#error-popup').modal("show");
      this.bDisplayErrorMsg = true;
      setTimeout(()=>{this.bDisplayErrorMsg = false},3000);
    }
    else 
    {
      localStorage.removeItem('lsTextOverlaysCheck');
      localStorage.removeItem('lsVoiceOverCheck');
      localStorage.removeItem('lsVideoContentCheck');
      const oResponse = JSON.parse(InComingMessage);
      console.log("OtherSettingComponent_HandleCreateVideoResponse : Response ==> ", oResponse);
      this.oSessionService.cSessionService_SetOtherSettingForm(this.fOtherSettingForm.value);
      if(this.bCreateVideoMode)
      {
        localStorage.removeItem('lsGeneratedVideo');
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oRouter.navigateByUrl("/ftue-preview");
      }
      else
      {
        this.oSessionService.cSessionService_SetGeneratedVideoCheck(true);
        this.oRouter.navigateByUrl('/ftu-overview');
      }
    }
  }
  OtherSettingComponent_UpdateVideo(bUpdateVideo : boolean)
  {
    console.log("Update video ==> ", bUpdateVideo);
    if(bUpdateVideo)
    {
      this.oSessionService.cSessionService_SetOtherSettingForm(this.fOtherSettingForm.value);
      const oEditVideoForm = this.oEditVideoService.EditVideoService_ReturnCombinedPayload();
      console.log("Updated Video Request : ==> ", oEditVideoForm);
      this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oEditVideoForm));
    }
  }
  //Handling Toggle Checks on the Page Dynamically 
  OtherSettingComponent_PhoneToggle(event: any)
  {
    this.fOtherSettingForm.controls['phone'].setValue('');
    this.bPhoneToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({bDisplayPhone : this.bPhoneToggle});
    if (this.bPhoneToggle) {
   

      this.fOtherSettingForm.controls['phone'].setValidators([
        Validators.required,
  
      ]);
    } else {
      this.fOtherSettingForm.controls['phone'].clearValidators();
    }

     
    this.fOtherSettingForm.controls['phone'].updateValueAndValidity();




  }
  OtherSettingComponent_AddressToggle(event: any)
  {
    this.bAddressToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({bDisplayAddress : this.bAddressToggle});
    
     if (this.bAddressToggle) {
 

      this.fOtherSettingForm.controls['line1'].setValidators([
        Validators.required 
      ]);
      this.fOtherSettingForm.controls['line2'].setValidators([
        Validators.required 
      ]);
      this.fOtherSettingForm.controls['city'].setValidators([
        Validators.required 
      ]);
      this.fOtherSettingForm.controls['state'].setValidators([
        Validators.required 
      ]);
      this.fOtherSettingForm.controls['postal_code'].setValidators([
        Validators.required 
      ]);
      this.fOtherSettingForm.controls['country'].setValidators([
        Validators.required 
      ]); 


    } else {
      this.fOtherSettingForm.controls['line1'].clearValidators();
      this.fOtherSettingForm.controls['line2'].clearValidators();
      this.fOtherSettingForm.controls['city'].clearValidators();
      this.fOtherSettingForm.controls['state'].clearValidators();
      this.fOtherSettingForm.controls['postal_code'].clearValidators();
      this.fOtherSettingForm.controls['country'].clearValidators();
       

    }

   
    this.fOtherSettingForm.controls['line1'].updateValueAndValidity();
 
    this.fOtherSettingForm.controls['line2'].updateValueAndValidity();
    this.fOtherSettingForm.controls['city'].updateValueAndValidity();
    this.fOtherSettingForm.controls['state'].updateValueAndValidity();
    this.fOtherSettingForm.controls['postal_code'].updateValueAndValidity();
    this.fOtherSettingForm.controls['country'].updateValueAndValidity();



  


  }
  OtherSettingComponent_URLToggle(event: any)
  {
    this.bURLToggle = event.target.checked;
    this.fOtherSettingForm.patchValue({bDisplayUrl : this.bURLToggle});
  console.log(this.bURLToggle)
    if (this.bURLToggle) {
      console.log(this.fOtherSettingForm.controls['url']);

      this.fOtherSettingForm.controls['url'].setValidators([
        Validators.required,
        Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
      ]);
    } else {
      this.fOtherSettingForm.controls['url'].clearValidators();
    }

    console.log(this.fOtherSettingForm.controls['url']);
    this.fOtherSettingForm.controls['url'].updateValueAndValidity();
  }

  //Getting the profile data from backend

  OtherSettingComponent_GetProfileData()
  {
      this.oWebSocketService.WebSocket_RegisterEvent('profile_request_result', this.OtherSettingComponent_HandleUserProfileResponse);
      const oGetUserProfile = 
      {
        session_id: this.sSessionId,
        email: this.sUserEmail,
        
      }
      console.log("OtherSettingComponent_GetProfileData : Profile Request Object ==> ",oGetUserProfile);
      this.oWebSocketService.WebSocket_SendMessage('profile_request', JSON.stringify(oGetUserProfile));
  }
  OtherSettingComponent_HandleUserProfileResponse = (InComingMessage) => 
  {
    console.log('OtherSettingComponent_HandleUserProfileResponse : Profile Response ==> ', InComingMessage);
    if (InComingMessage['errorMessage']) 
    {
      // this.sErrorMessage = JSON.parse(InComingMessage)["errorMessage"];
      this.sErrorMessage = 'An internal error occurred. Please try again later.';
      $('#error-popup').modal("show"); 
      this.bDisplayErrorMsg = true;
      setTimeout(()=>{this.bDisplayErrorMsg = false},5000) 
    }
    else 
    {
      let oResponse = InComingMessage;
      if(oResponse.url)
      {
        this.fOtherSettingForm.patchValue({
          url: oResponse.url
        })
      }
      if(oResponse.address)
      {
        this.fOtherSettingForm.patchValue({
          line1: oResponse.line1,
          line2: oResponse.line2,
          city: oResponse.city,
          state: oResponse.state,
          postal_code: oResponse.postal_code,
          country: oResponse.country
        })
      }
      if(oResponse.phone)
      {
        this.fOtherSettingForm.patchValue
        ({
          phone: oResponse.phone
        })
      }
    }
  }
  OtherSettingComponent_ToggleView(event)
  {
    if(event.target.checked)
    {
      this.oRouter.navigateByUrl('/wizard/overview');
    }
    else 
    {
      this.oRouter.navigateByUrl('edit-video/timeline-view');
    }
  }
  FTUEOtherSettingComponent_NavigateToLogin()
  {
    localStorage.clear();
    this.oRouter.navigateByUrl('/ftue-login');
  } 
  OtherSettingComponent_InitializeJqueryCode() 
  {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = 
    `{
        $(document).ready(function () {
          $('.address').change(function () {
              if (this.checked)
                  $('.address-div').fadeIn('slow');
              else
                  $('.address-div').fadeOut('slow');
          });

          $('.url').change(function () {
              if (this.checked)
                  $('.url-div').fadeIn('slow');
              else
                  $('.url-div').fadeOut('slow');

          });

          $('.phone').change(function () {
              if (this.checked)
                  $('.phone-div').fadeIn('slow');
              else
                  $('.phone-div').fadeOut('slow');

          });

      });
    }`;
    document.body.appendChild(chatScript);
  }
_keyPress(event: any) {

    let newVal = this.fOtherSettingForm.controls['phone'].value.replace(/\D/g, '');

 
  
    this.fOtherSettingForm.controls['phone'].setValue(newVal);
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

 
      if (!pattern.test(inputChar)) {

 
        
          event.preventDefault();
  
      }
    }
}
