import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-edit-video-header',
  templateUrl: './edit-video-header.component.html',
  styleUrls: ['./edit-video-header.component.scss']
})
export class EditVideoHeaderComponent implements OnInit {
  @Output() bEmittedSendUpdateVideoRequest = new EventEmitter<boolean>();
  bFullHeader: boolean = false;
  bDisplayTimeLineOptions: boolean = false;
  bDisplayPublishOptions: boolean = false;
  sVideoTitle : string;
  sVideoDuration : string;
  sVideoStatus : string;
  sVideoCreationDate : string;
  constructor(private location : Location, private oRouter : Router,private oSessionService : cSessionService, private oTemplateConfigService : cTemplateConfigService) 
  { 
    if(this.oRouter.url === '/edit-video/timeline-view')
    {
      this.bFullHeader = true
      this.bDisplayTimeLineOptions = true
    }
    if(this.oRouter.url === '/wizard/preview')
    {
      this.bDisplayPublishOptions = true
    }
  }

  ngOnInit(): void 
  {
    this.sVideoTitle = this.oSessionService.SessionService_GetVideoTitle();
    this.sVideoDuration = this.oSessionService.SessionService_GetVideoDuration();
    this.sVideoStatus = this.oSessionService.cSessionService_GetVideoStatus();
    this.sVideoCreationDate = this.oSessionService.cSessionService_GetVideoCreationDate();
  }
  NavigateToDashboard()
  {
    this.oSessionService.cSessionService_ResetHighlightedTabs();
    this.oRouter.navigateByUrl('/my-videos/draftsVideo');
  }
  EditVideoHeaderComponent_SendUpdateVideoRequest()
  {
    this.oSessionService.cSessionService_ResetHighlightedTabs();
    this.bEmittedSendUpdateVideoRequest.emit(true);
  }
  EditVideoHeaderComponent_CancelVideo()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigateByUrl('/dashboard/myvideos');
  }
  EditVideoHeaderComponent_SaveVideo()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigateByUrl('/my-videos/draftsVideo');
  }
  
  NavigateToNextTab()
  {
    if(this.oRouter.url === '/edit-video/overview')
    {
      this.oRouter.navigateByUrl('/edit-video/look-feel')
    }
    else if (this.oRouter.url === '/edit-video/look-feel')
    {
     this.oRouter.navigateByUrl('/edit-video/content')
    }
    else if (this.oRouter.url === '/edit-video/content')
    {
      this.oRouter.navigateByUrl('/edit-video/other-setting')
    }
 }

}
